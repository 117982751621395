import React,{Fragment, useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert, Modal } from "react-bootstrap";
import {reducer} from '../bootstrap/alertReducer';
import DatePicker  from "react-datepicker";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { SketchPicker } from 'react-color';
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const IPQSCampaignEdit = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [basicModal, setBasicModal] = useState(false);
    const [state, dispatch] = useReducer(reducer, initial);
    const [activeStatus, setActiveStatus] = useState('yes');
    const [IQPSCampaignId, setIQPSCampaignId] = useState('');
    const [campaignId, setCampaignId] = useState('');
    const [campaignIdText, setCampaignIdText] = useState('');
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [campaignList, setCampaignList] = useState([]);
    const [campaignListStatus, setCampaignListStatus] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [color, setColor] = useState('#ffffff');
    const [channelName] = useState('');
    const [dailySampleSize, setDailySampleSize] = useState('');

    function handleChangeColor(color) {
        setColor(color.hex);
    };

    useEffect(() => {
        getIQPSCampaign();
        // eslint-disable-next-line
    }, []);

    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function changeDailySampleSize(event) {
        if(parseInt(event.target.value) >= 0) {
            setDailySampleSize(parseInt(event.target.value));
        } else {
            setDailySampleSize('');
        }
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    function choosecampaignId(id, name) {
        setCampaignId(id);
        const text = id+' '+name;
        setCampaignIdText(text);

        setCampaignList([]);
        setCampaignListStatus('');
    }

    async function changeCampaignId(event) {
        setCampaignIdText(event.target.value);
        setCampaignId(event.target.value);

        if(event.target.value !== "") {
            const data = { search: event.target.value }
            const response = await axios.post(baseURL+'/api/campaign_search', data)
            setCampaignList(response.data.data);
            setCampaignListStatus(response.data.status);
        } else {
            setCampaignList([]);
            setCampaignListStatus('');
        }
    }

    async function saveIQPSManagement() {
        if(parseInt(campaignId) > 0) {
            const data = {
                iqps_campaign_id: searchParams.get("id"),
                active: activeStatus,
                campaign_id: campaignId,
                daily_sample_size: dailySampleSize,
                entry_date: entryDate,
                color: color,
                channel_name: channelName
            }
            const response = await axios.post(baseURL+'/api/ipqs_campaign_edit', data)

            setAlertStatus(response.data.status);
            if(response.data.status === 'error') {
                setAlertText(response.data.text);
            }
            if(response.data.status === 'insert') {
                navigate('/ipqs-management');
            }
        }
    }

    async function getIQPSCampaign() {
        const data = { iqps_campaign_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/ipqs_campaign_get', data)
        if(response.data.status === 'ok') {
            if(parseInt(response.data.data[0].active) === 0) {
                setActiveStatus('no');
            } else {
                setActiveStatus('yes');
            }

            setIQPSCampaignId(response.data.data[0].id);
            setCampaignId(response.data.data[0].campaign_id);
            setCampaignIdText(response.data.data[0].campaign_id + ' ' + response.data.data[0].campaign_name);
            setDailySampleSize(response.data.data[0].daily_sample_size);
            setEntryDate(response.data.data[0].entry_date);
            setColor(response.data.data[0].color);
        }
    }

    async function deleteCampaignRequest() {
        const data = {
            campaign_id: IQPSCampaignId
        }
        const response = await axios.post(baseURL+'/api/ipqs_campaign_delete', data)
        if(response.data.status === 'ok') {
            setBasicModal(false);
            navigate('/ipqs-management');
        }
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Edit IPQS Campaign</h2>
                <Link to={"/ipqs-management"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Select Campaign</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={campaignIdText} onChange={(event) => changeCampaignId(event)} placeholder="Select Campaign"/>
                                                {campaignList &&
                                                    campaignList.map((item, key) => (
                                                        <li className='pt-1 pb-1' style={{cursor:'pointer'}} onClick={() => choosecampaignId(item.id, item.name)}>Campaign ID: {item.id}, Name: {item.name}</li>
                                                    )
                                                )}

                                                {campaignListStatus === 'no' && (
                                                    <li className='pt-1 pb-1'>There is no campaign with this ID or name.</li>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Daily Sample Size</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={dailySampleSize} onChange={(event) => changeDailySampleSize(event)} placeholder="Daily Sample Size"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveIQPSManagement()}>
                                Save
                            </Button>

                            <Link to="/ipqs-management" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            <Button variant="danger" className="" onClick={() => setBasicModal(true)}>
                                Delete
                            </Button>

                            <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                <Modal.Header>
                                    <h4 className="text-black mb-2">&nbsp;</h4>
                                    <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4 className="text-black">Are you sure to delete the campaign?</h4>
                                    <Button variant="danger" className="" onClick={() => deleteCampaignRequest()}>
                                        Delete
                                    </Button>
                                </Modal.Body>
                            </Modal>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'update' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The data has been updated successfully.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default IPQSCampaignEdit;