import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import  DatePicker  from "react-datepicker";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PermissionGate from '../PermissionGate';

const IPQSLog = () => {
    const { background } = useContext(ThemeContext);
    useEffect(() => {
        getCampaignOptionList();
        getPubOptionList();
        getAdvFeedOptionList();
    }, []);

    const [tableData, setTableData] = useState([]);
    const [chartTableData, setChartTableData] = useState([]);
    const [chartTableDataType, setChartTableDataType] = useState('');

    const [active, setActive] = useState(1);
    const [maxPage, setMaxPage] = useState(2);
    const [pageItems, setPageItems] = useState([{p:1}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);

    const [sortBy, setSortBy] = useState('DESC');
    const [orderBy, setOrderBy] = useState('id');

    const [dateRangeStart, setDateRangeStart] = useState(new Date().toISOString().split('T')[0]);
    const [dateRangeEnd, setDateRangeEnd] = useState(new Date().toISOString().split('T')[0]);

    const [campaignOptionList, setCampaignOptionList] = useState([]);
    const [campaignOptionListAll, setCampaignOptionListAll] = useState([]);
    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([]);

    const [pubOptionList, setPubOptionList] = useState([]);
    const [pubOptionListAll, setPubOptionListAll] = useState([]);
    const [selectedPubOptions, setSelectedPubOptions] = useState([]);

    const [advFeedOptionList, setAdvFeedOptionList] = useState([]);
    const [selectedAdvFeedOptions, setSelectedAdvFeedOptions] = useState([]);

    const [chartLabels, setChartLabels] = useState([]);
    const [chartLabels2, setChartLabels2] = useState([]);

    const [chartData, setChartData] = useState([]);
    const [chartColors, setChartColors] = useState([]);

    const [chartDataset, setChartDataset] = useState([]);
    const [chartDataset2, setChartDataset2] = useState([]);

    const [allResponseData, setAllResponseData] = useState({});

    const [selectedReportType, setSelectedReportType] = useState('day');
    const [selectedDateRangeOptions, setSelectedDateRangeOptions] = useState('last_7days');

    const [chartHeight] = useState(120);
    const [selectedParameter, setselectedParameter] = useState('ip_repeat_ratio');
    
    const [exportToCsvButtonText, setExportToCsvButtonText] = useState('Export to CSV');

    const [showTable, setShowTable] = useState(false);
    const [expandCollapseTable, setExpandCollapseTable] = useState('Expand Table');

    // Multi-Select Conf - START //
    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
        return (<div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props)}><div style={{ padding: "6px 5px" }}>{children}</div></div>);
    };
    const ClearIndicatorStyles = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "black" : "black", minHeight: "50px" });
    const ClearIndicatorStylesDark = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "white" : "white", minHeight: "50px" });
    // Multi-Select Conf - END //

    const chartDataDay = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: chartDataset,
        grouped: true,
    };

    const chartDataDay2 = {
        defaultFontFamily: "Poppins",
        labels: chartLabels2,
        datasets: chartDataset2,
        grouped: true,
    };

    const chartOptionsDayVpnTorBot = {
        plugins:{
            legend: { display: false },
            title: { display: true },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            return items.dataset.label + ': ' + items.raw;
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter === 'rpm' || selectedParameter === 'cpc' || selectedParameter === 'ctr') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return a.datasetIndex - b.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    const chartOptionsDay = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            return items.dataset.label + ': ' + items.raw;
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter === 'rpm' || selectedParameter === 'cpc' || selectedParameter === 'ctr') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return b.datasetIndex - a.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    const chartDataMain = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                data: chartData
            },
        ],
    };

    const chartOptionsMain = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltips: { mode: "index", intersect: false },
            responsive: true,
            datalabels: {
                display: true,
                color: `${checkDatalabelColor()}`,
                anchor: "end",
                offset: -20,
                align: "start"
            }
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    function checkDatalabelColor() {
        if(background.value === 'dark') { return "white"; }
        else { return "black"; }
    }

    async function getCampaignOptionList() {
        const response = await axios.post(baseURL+'/api/ipqs_report/get_campaign_list')
        setCampaignOptionListAll(response.data.data);
        setCampaignOptionList(response.data.data);
    }

    function handleSelectAdvFeed(data) {
        setSelectedAdvFeedOptions(data);

        if(data.length === 0) {
            setPubOptionList(pubOptionListAll);
        } else {
            var new_publishers = [];
            for(const publisher of pubOptionListAll) {
                if(publisher.id === 0) {
                    new_publishers.push(publisher);
                } else {
                    for(const d of data) {
                        for(const feed_id of publisher.feed_ids) {
                            if(d.id === feed_id) {
                                new_publishers.push(publisher);
                            }
                        }
                    }
                }
            }
            new_publishers = [...new Set(new_publishers)]
            setPubOptionList(new_publishers);
        }
    }

    function handleSelectPub(data) {
        setSelectedPubOptions(data);

        if(data.length === 0) {
            setCampaignOptionList(campaignOptionListAll);
        } else {
            var new_campaigns = [];
            for(const campaign of campaignOptionListAll) {
                if(campaign.id === 0) {
                    new_campaigns.push(campaign);
                } else {
                    for(const d of data) {
                        if(d.id === campaign.publisher_id) {
                            new_campaigns.push(campaign);
                        }
                    }
                }
            }
            setCampaignOptionList(new_campaigns);
        }
    }

    async function getPubOptionList() {
        const response = await axios.get(baseURL+'/api/get_publisher_list')
        setPubOptionListAll(response.data);
        setPubOptionList(response.data);
    }

    async function getAdvFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_adv_feed_list')
        setAdvFeedOptionList(response.data);
    }

    function setNavigationLinks(max_page) {
        var page_items_tmp = [];
        for (let number = active; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        var max_page = maxPage;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
        getLogsTable(page_number);
    }

    function changePageNoReload(page, max_page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getLogsTable(page_number, sort_by, order_by) {
        setTableData([]);

        var sort_by_value = sortBy;
        if(sort_by !== undefined) {
            sort_by_value = sort_by;
        }

        var order_by_value = orderBy;
        if(order_by !== undefined) {
            order_by_value = order_by;
        }

        const data = {
            adv_feeds: selectedAdvFeedOptions,
            publishers: selectedPubOptions,
            campaigns: selectedCampaignOptions,
            date: selectedDateRangeOptions,
            date_start: dateRangeStart,
            date_end: dateRangeEnd,
            page: page_number,
            sort_by: sort_by_value,
            order_by: order_by_value,
        }


        var response = await axios.post(baseURL+'/api/ipqs_log/get', data)
        console.log(response.data);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        

        setTableData(response.data.data);
        setNavigationLinks(response.data.max_page);

        changePageNoReload(page_number, response.data.max_page);

        setLoadingBadge(false);

        getChart();
        return "";
    }

    async function getChart() {
        setChartTableData([]);
        setChartLabels([]);
        setChartDataset([]);
        setChartLabels2([]);
        setChartDataset2([]);

        const data = {
            adv_feeds: selectedAdvFeedOptions,
            publishers: selectedPubOptions,
            campaigns: selectedCampaignOptions,
            date: selectedDateRangeOptions,
            date_start: dateRangeStart,
            date_end: dateRangeEnd,
        }

        var api_url = '';
        if(selectedReportType === 'day') {
            api_url = '/api/ipqs_log/chart/by_day';
        } else if(selectedReportType === 'publisher') {
            api_url = '/api/ipqs_log/chart/by_publisher';
        }

        var response = await axios.post(baseURL+api_url, data)
        console.log(response.data);
        setAllResponseData(response.data);

        if(selectedReportType === 'day') {
            setChartTableData(response.data.result_table);

            setChartDay(response.data, selectedParameter);

            setChartLabels2(response.data.labels);
            setChartDataset2(response.data.datasets.vpn_tor_bot);
        }

        if(selectedReportType === 'publisher') {
            setChartTableData(response.data.result_table);
            setChartTableDataType(response.data.result_table_type);

            setChartColors(response.data.chart_color);
            setChart(response.data, selectedParameter);

            setChartDataset(response.data.datasets.vpn_tor_bot);
        }

        setLoadingBadge(false);
    }

    async function setChart(data, sp) {
        setChartLabels(data.chart_data.labels);
        if(sp === 'ip_repeat_ratio') {
            setChartData(data.chart_data.ip_repeat_ratio);
        }
        if(sp === 'fraud_score') {
            setChartData(data.chart_data.fraud_score);
        }
        if(sp === 'vpn') {
            setChartData(data.chart_data.vpn);
        }
        if(sp === 'tor') {
            setChartData(data.chart_data.tor);
        }
        if(sp === 'bot') {
            setChartData(data.chart_data.bot);
        }

    }

    async function setChartDay(data, sp) {
        setChartLabels(data.labels);
        if(sp === 'ip_repeat_ratio') {
            setChartDataset(data.datasets.ip_repeat_ratio);
        }
        if(sp === 'fraud_score') {
            setChartDataset(data.datasets.fraud_score);
        }
        if(sp === 'vpn') {
            setChartDataset(data.datasets.vpn);
        }
        if(sp === 'tor') {
            setChartDataset(data.datasets.tor);
        }
        if(sp === 'bot') {
            setChartDataset(data.datasets.bot);
        }
    }

    function handleSelectParameter(event) {
        setselectedParameter(event);

        if(selectedReportType === 'day') {
            setChartDay(allResponseData, event);
        }

        if(selectedReportType === 'publisher') {
            setChart(allResponseData, event);
        }

    }

    async function sortTable(value) {
        if(sortBy === 'ASC') {
            //Sort By Desc
            setSortBy('DESC')
            setOrderBy(value)
            setTableData([]);
            getLogsTable(1, 'DESC', value);
        } else {
            //Sort By Asc
            setSortBy('ASC')
            setOrderBy(value)
            setTableData([]);
            getLogsTable(1, 'ASC', value);
        }
    }

    function changeDateRange(date, type) {
        const d = new Date(date).toISOString().split('T')[0];
        if(type === 'start') {
            setDateRangeStart(d);
        } else {
            setDateRangeEnd(d);
        }
    }

    function handleSelectReportType(event) {
        setAllResponseData([]);
        setTableData([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);

        setChartTableData([]);
        setChartLabels([]);
        setChartDataset([]);
        setChartLabels2([]);
        setChartDataset2([]);

        setSelectedReportType(event);
    }

    function openFeed(date_format) {
        let tr_feeds = document.getElementsByName(date_format);

        chartTableData.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.date_format === date_format) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                    el.feed_display = 'revert';
                    document.getElementsById(date_format).className = "fa-solid fa-circle-minus";
                }
            } else {
                if(el.date_format === date_format) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                    el.feed_display = 'none';
                    document.getElementsById(date_format).className = "fa-solid fa-circle-plus";
                }
            }
        });
    }

    async function exportToCsv() {
        setExportToCsvButtonText('Exporting...');

        var usersCsv = [];
        var row = "";
        var date_range = "";
        var headers = [];
        if(selectedReportType === 'day') {
            // Headers for each column
            headers = ['date, ip_repeat, fraud_score, vpn, tor, bot']
            // Convert users data to a csv
            usersCsv = chartTableData.reduce((acc, user) => {
                const { date_format, ip_repeat_ratio, fraud_score, vpn, tor, bot } = user
                acc.push(['"' + date_format + '"', Math.round(ip_repeat_ratio*100)/100, Math.round(fraud_score*100)/100, Math.round(vpn*100)/100, Math.round(tor*100)/100, Math.round(bot*100)/100].join(','))
                return acc
            }, [])
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'report.csv', fileType: 'text/csv' })
            await delay(3000);

            // Headers for each column
            headers = ['date, publisher_name, publisher_id, ip_repeat, fraud_score, vpn, tor, bot']
            // Convert users data to a csv
            usersCsv = [];
            for(const table of chartTableData) {
                row = "";
                var date = '"' + table.date_format + '"';
                for(const f of table.publishers) {
                    row = date + ',' + f.publisher_name + ',' + f.publisher_id + ',' + Math.round(f.ip_repeat_ratio*100)/100 + ',' + Math.round(f.fraud_score*100)/100 + ',' + Math.round(f.vpn*100)/100 + ',' + Math.round(f.tor*100)/100 + ',' + Math.round(f.bot*100)/100;
                    usersCsv.push(row);
                }
            }
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'report_details.csv', fileType: 'text/csv'})
        }

        if(selectedReportType === 'publisher' && chartTableDataType === 'publisher') {
            // Headers for each column
            headers = ['publisher_name, publisher_id, date_range, ip_repeat, fraud_score, vpn, tor, bot']
            // Convert users data to a csv
            usersCsv = [];
            for(const table of chartTableData) {
                date_range = '"' + table.date_range_start + ' - ' + table.date_range_end + '"';
                
                row = table.publisher_name + ',' + table.publisher_id + ',' + date_range + ',' + Math.round(table.ip_repeat_ratio*100)/100 + ',' + Math.round(table.fraud_score*100)/100 + ',' + Math.round(table.vpn*100)/100 + ',' + Math.round(table.tor*100)/100 + ',' + Math.round(table.bot*100)/100;
                usersCsv.push(row);
            }
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'report.csv', fileType: 'text/csv'})
        }

        if(selectedReportType === 'publisher' && chartTableDataType === 'feed') {
            // Headers for each column
            headers = ['feed_name, feed_id, date_range, ip_repeat, fraud_score, vpn, tor, bot']
            // Convert users data to a csv
            usersCsv = [];
            for(const table of chartTableData) {
                date_range = '"' + table.date_range_start + ' - ' + table.date_range_end + '"';
                
                row = table.feed_name + ',' + table.feed_id + ',' + date_range + ',' + Math.round(table.ip_repeat_ratio*100)/100 + ',' + Math.round(table.fraud_score*100)/100 + ',' + Math.round(table.vpn*100)/100 + ',' + Math.round(table.tor*100)/100 + ',' + Math.round(table.bot*100)/100;
                usersCsv.push(row);
            }
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'report.csv', fileType: 'text/csv'})
        }


        setExportToCsvButtonText('Export to CSV');
    }

    async function downloadFile({ data, fileName, fileType }) {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
        a.dispatchEvent(clickEvt)
        a.remove()
        return "";
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    function expandTable() {
        if(showTable === false) {
            setShowTable(true);
            setExpandCollapseTable('Collapse Table');
        } else {
            setShowTable(false);
            setExpandCollapseTable('Expand Table');
        }
    }

    
    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">IPQS Log</h2>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Adv Feeds</label>
                                    <Select
                                        options={advFeedOptionList}
                                        placeholder="All Adv Feeds"
                                        value={selectedAdvFeedOptions}
                                        onChange={(event) => handleSelectAdvFeed(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Publishers</label>
                                    <Select
                                        options={pubOptionList}
                                        placeholder="All Publishers"
                                        value={selectedPubOptions}
                                        onChange={(event) => handleSelectPub(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Campaign</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="Select Campaign"
                                        defaultValue={''}
                                        onChange={(event) => {setSelectedCampaignOptions(event)}}
                                        isMulti={true}
                                        options={campaignOptionList}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Date Range</label>
                                    <select value={selectedDateRangeOptions} onChange={(event) => setSelectedDateRangeOptions(event.target.value)} className="form-control">
                                        {/* <option value="yesterday">Yesterday</option> */}
                                        <option value="last_7days">Last 7 days</option>
                                        <option value="last_14days_from_today">Last 14 days</option>
                                        <option value="last_30days_from_today">Last 30 days</option>
                                        <option value="month">MTD</option>
                                        <option value="previous_month">Previous Month</option>
                                        <option value="last_last_month">Last last Month</option>
                                        <option value="date_range">Custom Range</option>
                                    </select>
                                </div>
                            </div>

                            {selectedDateRangeOptions === "date_range" && (
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date Start</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'start')} value={dateRangeStart} className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date End</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'end')} value={dateRangeEnd} className="form-control"/>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Report Type</label>
                                    <select value={selectedReportType}  onChange={(event) => handleSelectReportType(event.target.value)} className="form-control">
                                        <option value="day">By Day</option>
                                        <option value="publisher">By Publisher</option>
                                    </select>
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => {setSortBy('DESC'); setOrderBy('id'); getLogsTable(1, 'ASC', 'id');} }>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {loadingBadge === true && (
                <Badge bg="info">Loading</Badge>
            )}

            {(chartData.length > 0 || chartDataset.length > 0) && (
                <div className="">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body pb-0">
                                <div className="form-group mb-3 row">
                                    <label className="col-6 col-sm-3 col-md-3 col-lg-2 col-form-label pt-2" htmlFor="val-api">Display By</label>
                                    <div className="col-6 col-sm-9 col-md-9 col-lg-10">
                                        <select value={selectedParameter}  onChange={(event) => handleSelectParameter(event.target.value)} className="form-control">
                                            <option value="ip_repeat_ratio">IP Repeat Ratio</option>
                                            <option value="fraud_score">Fraud Score</option>
                                            <option value="vpn">VPN</option>
                                            <option value="tor">TOR</option>
                                            <option value="bot">BOT</option>
                                        </select>
                                    </div>
                                </div>

                                {selectedReportType === 'day' && (
                                    <Bar data={chartDataDay} height={chartHeight} options={chartOptionsDay} />
                                )}
                                
                                {selectedReportType === 'publisher' && (
                                    <Bar data={chartDataMain} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                                )}

                            </div>

                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card">
                            <div className="card-body pb-0">
                                <div className="form-group mb-3 row">
                                    <label className="col-6 col-sm-3 col-md-3 col-lg-2 col-form-label pt-2" htmlFor="val-api">Display By</label>
                                    <div className="col-6 col-sm-9 col-md-9 col-lg-10">
                                        <select value={selectedParameter}  onChange={(event) => handleSelectParameter(event.target.value)} className="form-control">
                                            <option value="vpn_tor_bot">VPN TOR BOT</option>
                                        </select>
                                    </div>
                                </div>

                                {selectedReportType === 'day' && (
                                    <Bar data={chartDataDay2} height={chartHeight} options={chartOptionsDayVpnTorBot} />
                                )}
                                
                                {selectedReportType === 'publisher' && (
                                    <Bar data={chartDataDay} height={chartHeight} options={chartOptionsDay} />
                                )}

                            </div>

                        </div>
                    </div>

                </div>
            )}

            {chartTableData.length > 0 && (
                <div style={{display: 'table', margin: '0px auto'}}>
                    <Button className="me-2 mb-4" variant="success" onClick={() => expandTable()}>
                        {expandCollapseTable}
                    </Button>
                </div>
            )}

            {chartTableData.length > 0 && selectedReportType === 'day' && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Day</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >IP Repeat</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >Fraud Score</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('vpn_rate')} >VPN Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('tor_rate')} >TOR Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('bot_rate')} >Bot Rate</strong></th>
                                        </tr>
                                    </thead>
                                    
                                        {chartTableData.length > 0 &&
                                            chartTableData.map((item, key) => (
                                                <tbody key={key+1100}>
                                                    <tr>
                                                        <td>
                                                            <i className="fa-solid fa-circle-plus" id={item.date_format} onClick={() => openFeed(item.date_format)}></i> {item.date_format}
                                                        </td>
                                                        <td>{item.ip_repeat_ratio}</td>
                                                        <td>{item.fraud_score}</td>
                                                        <td>{item.vpn}</td>
                                                        <td>{item.tor}</td>
                                                        <td>{item.bot}</td>
                                                    </tr>

                                                    {item.publishers.map((pub, key) => {
                                                        return (
                                                        <tr name={item.date_format} key={key+1200} style={{display:'none'}}>
                                                            <td style={{paddingLeft: '30px'}}>
                                                                {pub.publisher_name} ({pub.publisher_id})
                                                            </td>
                                                            <td>{pub.ip_repeat_ratio}</td>
                                                            <td>{pub.fraud_score}</td>
                                                            <td>{pub.vpn}</td>
                                                            <td>{pub.tor}</td>
                                                            <td>{pub.bot}</td>
                                                        </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            )
                                        )}
                                    
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {chartTableData.length > 0 && selectedReportType === 'publisher' && chartTableDataType === 'publisher' && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Publisher Name</strong></th>
                                            <th><strong>Date Range</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >IP Repeat</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >Fraud Score</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('vpn_rate')} >VPN Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('tor_rate')} >TOR Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('bot_rate')} >Bot Rate</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chartTableData.length > 0 &&
                                            chartTableData.map((item, key) => (
                                                <tr key={key+1300}>
                                                    <td>{item.publisher_name} ({item.publisher_id})</td>
                                                    <td>{item.date_range_start} - {item.date_range_end}</td>
                                                    <td>{item.ip_repeat_ratio}</td>
                                                    <td>{item.fraud_score}</td>
                                                    <td>{item.vpn}</td>
                                                    <td>{item.tor}</td>
                                                    <td>{item.bot}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {chartTableData.length > 0 && selectedReportType === 'publisher' && chartTableDataType === 'feed' && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Feed Name</strong></th>
                                            <th><strong>Date Range</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >IP Repeat</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >Fraud Score</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('vpn_rate')} >VPN Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('tor_rate')} >TOR Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('bot_rate')} >Bot Rate</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chartTableData.length > 0 &&
                                            chartTableData.map((item, key) => (
                                                <tr key={key+1400}>
                                                    <td>{item.feed_name} ({item.feed_id})</td>
                                                    <td>{item.date_range_start} - {item.date_range_end}</td>
                                                    <td>{item.ip_repeat_ratio}</td>
                                                    <td>{item.fraud_score}</td>
                                                    <td>{item.vpn}</td>
                                                    <td>{item.tor}</td>
                                                    <td>{item.bot}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            

            {tableData.length > 0 && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('report_timestamp')} >Report Date</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('campaign_name')} >Campaign</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('publisher_name')} >Publisher</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('feed_name')} >Adv Feed</strong></th>
                                            <th><strong>Date Range</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('daily_sample_size')} >Daily Sample Size</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >IP Repeat</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ip_repeat')} >Fraud Score</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('vpn_rate')} >VPN Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('tor_rate')} >TOR Rate</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('bot_rate')} >Bot Rate</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                <tr key={key+1500}>
                                                    <td>{item.report_date}</td>
                                                    <td>{item.campaign_name}</td>
                                                    <td>{item.publisher_name}</td>
                                                    <td>{item.feed_name}</td>
                                                    <td>{item.date_range_start}-{item.date_range_end}</td>
                                                    <td>{item.daily_sample_size}</td>
                                                    <td>{item.ip_repeat}</td>
                                                    <td>{item.fraud_score}</td>
                                                    <td>{item.vpn_rate}</td>
                                                    <td>{item.tor_rate}</td>
                                                    <td>{item.bot_rate}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}

                                <Pagination
                                    size={"sm"}
                                    className={`mt-4  ${true ? "pagination-gutter" : ""} ${
                                        "info" && `pagination-${"info"}`
                                    } ${!false && "no-bg"} ${false && "pagination-circle"}`}
                                >
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    {pageItems.length > 0 &&
                                        pageItems.map((item, key) => (
                                            <li key={key+1600} className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}

                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default IPQSLog;