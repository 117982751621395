import React,{Fragment, useState, useContext, useEffect} from 'react';
import { Button, Table, Badge} from "react-bootstrap";
import DatePicker  from "react-datepicker";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { Bar } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';
import { ThemeContext } from "../../../context/ThemeContext";
import PermissionGate from '../PermissionGate';
import Select from "react-select";

const Income = () => {
    const { background } = useContext(ThemeContext);

    const [selectedDateRangeOptions, setSelectedDateRangeOptions] = useState('last_7days_minus_2');
    const [dateRangeStart, setDateRangeStart] = useState(new Date().toISOString().split('T')[0]);
    const [dateRangeEnd, setDateRangeEnd] = useState(new Date().toISOString().split('T')[0]);

    const [chartHeight, setChartHeight] = useState(80);
    const [chartColors, setChartColors] = useState([]);
    const [chartData, setChartData] = useState([10, 20]);
    const [chartLabels, setChartLabels] = useState(['One','Two']);

    const [chartColorsPublisher, setChartColorsPublisher] = useState([]);
    const [chartDataPublisher, setChartDataPublisher] = useState([10, 20]);
    const [chartLabelsPublisher, setChartLabelsPublisher] = useState(['One','Two']);

    const [tableDataFeed, setTableDataFeed] = useState([]);
    const [tableDataPublisher, setTableDataPublisher] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableDataTotal, setTableDataTotal] = useState([]);

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalExpense, setTotalExpense] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalMarginRate, setTotalMarginRate] = useState(0);

    const [period, setPeriod] = useState('');
    const [desktopData, setDesktopData] = useState({});
    const [mobileData, setMobileData] = useState({});

    const [showTable, setShowTable] = useState(false);
    const [expandCollapseTable, setExpandCollapseTable] = useState('Expand Table');

    const [loadingBadge, setLoadingBadge] = useState(false);

    const [exportToCsvButtonText, setExportToCsvButtonText] = useState('Export to CSV');

    const [selectedFeedOptions, setSelectedFeedOptions] = useState([]);
    const [feedOptionList, setFeedOptionList] = useState([]);
    const [feedOptionListAll, setFeedOptionListAll] = useState([]);

    const [selectedPubOptions, setSelectedPubOptions] = useState([]);
    const [pubOptionList, setPubOptionList] = useState([]);
    const [pubOptionListAll, setPubOptionListAll] = useState([]);

    useEffect(() => {
        getFeedOptionList();
        getPubOptionList();
    }, []);

    // Multi-Select Conf - START //
    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
    const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
    return (<div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props)}><div style={{ padding: "6px 5px" }}>{children}</div></div>);
    };
    const ClearIndicatorStyles = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "black" : "black", minHeight: "50px" });
    const ClearIndicatorStylesDark = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "white" : "white", minHeight: "50px" });
    // Multi-Select Conf - END //

    async function getFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_feed_list?include_all=no')
        setFeedOptionListAll(response.data);
        setFeedOptionList(response.data);
    }

    async function getPubOptionList() {
        const response = await axios.get(baseURL+'/api/get_publisher_list')
        setPubOptionListAll(response.data);
        setPubOptionList(response.data);
    }

    function handleSelectAdvFeed(data) {
        setSelectedFeedOptions(data);

        if(data.length === 0) {
            setPubOptionList(pubOptionListAll);
        } else {
            var new_publishers = [];
            for(const publisher of pubOptionListAll) {
                if(publisher.id === 0) {
                    new_publishers.push(publisher);
                } else {
                    for(const d of data) {
                        for(const feed_id of publisher.feed_ids) {
                            if(d.id === feed_id) {
                                new_publishers.push(publisher);
                            }
                        }
                    }
                }
            }
            new_publishers = [...new Set(new_publishers)]
            setPubOptionList(new_publishers);
        }
    }

    function handleSelectPub(data) {
        console.log(data);
        setSelectedPubOptions(data);

        if(data.length === 0) {
            setFeedOptionList(feedOptionListAll);
        } else {
            var all_available_feed_ids = [];
            for(const p of data) {
                for(const feed_id of p.feed_ids) {
                    console.log("feed_id: ", feed_id);
                    let val = feedOptionListAll.find(element => element.id === feed_id)
                    if(val !== undefined) {
                        all_available_feed_ids.push(val);
                    }
                    
                }
            }
            setFeedOptionList(all_available_feed_ids);
        }
    }

    const chartDataMain = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                data: chartData
            },
        ],
    };

    const chartDataPub = {
        defaultFontFamily: "Poppins",
        labels: chartLabelsPublisher,
        datasets: [
            {
                label: "",
                backgroundColor: chartColorsPublisher,
                hoverBackgroundColor: chartColorsPublisher,
                data: chartDataPublisher
            },
        ],
    };

    const chartOptionsMain = {
        indexAxis: 'y',
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltips: { mode: "index", intersect: false },
            responsive: true,
            datalabels: {
                display: true,
                color: `${checkDatalabelColor()}`,
                anchor: "end",
                offset: -20,
                align: "start"
            }
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    function checkDatalabelColor() {
        if(background.value === 'dark') { return "white"; }
        else { return "black"; }
    }

    function changeDateRange(date, type) {
        const d = new Date(date).toISOString().split('T')[0];
        if(type === 'start') {
            setDateRangeStart(d);
        } else {
            setDateRangeEnd(d);
        }
    }

    async function getStatement() {
        setLoadingBadge(true);
        setTableDataFeed([]);
        setTableDataPublisher([]);
        setTableData([]);
        setTableDataTotal([]);
        setPeriod('');

        const data = {
            feeds: selectedFeedOptions,
            publishers: selectedPubOptions,
            date: selectedDateRangeOptions,
            date_range_start: dateRangeStart,
            date_range_end: dateRangeEnd
        }
        const response = await axios.post(baseURL+'/api/get_income_statement', data)
        console.log(response.data);

        setTableDataFeed(response.data.result_table_feed);
        setTableDataPublisher(response.data.result_table_publisher);
        setTableData(response.data.result_table);
        setTableDataTotal(response.data.result_total);

        setPeriod(response.data.period);
        setDesktopData(response.data.desktop_data);
        setMobileData(response.data.mobile_data);

        for(const r of response.data.result_total) {
            setTotalRevenue(r.revenue);
            setTotalExpense(r.expense);
            setTotalIncome(r.income);
        }
        setTotalMarginRate(response.data.total_margin_rate);

        var chart_height = (12 * parseInt(response.data.chart_data_feed.revenue.length));
        if(chart_height >= 200) {
            setChartHeight(chart_height);
        } else {
            setChartHeight(200);
        }

        setChartColors(response.data.chart_color_feed);
        setChartLabels(response.data.chart_data_feed.labels);
        setChartData(response.data.chart_data_feed.revenue);

        setChartColorsPublisher(response.data.chart_color_publisher);
        setChartLabelsPublisher(response.data.chart_data_publisher.labels);
        setChartDataPublisher(response.data.chart_data_publisher.pub_rev);

        setLoadingBadge(false);
    }

    async function openFeed(advertiser_id, feed_name) {
        let tr_feeds = document.getElementsByName(feed_name);

        tableDataFeed.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                    el.feed_display = 'revert';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-minus";
                }
            } else {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                    el.feed_display = 'none';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-plus";
                }
            }
        });
    }

    async function openPublisher(advertiser_id, feed_name) {
        let tr_feeds = document.getElementsByName(feed_name);

        tableDataPublisher.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                    el.feed_display = 'revert';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-minus";
                }
            } else {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                    el.feed_display = 'none';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-plus";
                }
            }
        });
    }

    function expandTable() {
        if(showTable === false) {
            setShowTable(true);
            setExpandCollapseTable('Collapse Table');
        } else {
            setShowTable(false);
            setExpandCollapseTable('Expand Table');
        }
    }

    async function exportToCsv() {
        setExportToCsvButtonText('Exporting...');

        var usersCsv = [];
        var row = "";
        var headers = [];
        
        // Headers for each column
        headers = ['date, revenue, expense, income']
        usersCsv = [];
        for(const table of tableData) {
            row = "";            
            row = '"' + table.date + '",' + Math.round(table.revenue*100)/100 + ',' + Math.round(table.expense*100)/100 + ',' + Math.round(table.income*100)/100;
            usersCsv.push(row);
            
        }
        await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'income_statement.csv', fileType: 'text/csv'})

        setExportToCsvButtonText('Export to CSV');
    }

    async function downloadFile({ data, fileName, fileType }) {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
        a.dispatchEvent(clickEvt)
        a.remove()
        return "";
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Income Statement</h2>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Adv Feeds</label>

                                    <Select
                                        closeMenuOnSelect={false}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="All Adv Feeds"
                                        defaultValue={''}
                                        onChange={(event) => handleSelectAdvFeed(event)}
                                        isMulti
                                        options={feedOptionList}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                                
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Publishers</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="All Publishers"
                                        defaultValue={''}
                                        onChange={(event) => handleSelectPub(event)}
                                        isMulti
                                        options={pubOptionList}
                                        value={selectedPubOptions}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Date Range</label>
                                    <select value={selectedDateRangeOptions} onChange={(event) => setSelectedDateRangeOptions(event.target.value)} className="form-control">
                                        <option value="yesterday">Yesterday</option>
                                        <option value="last_7days_minus_2">Last 7 days</option>
                                        <option value="last_14days">Last 14 days</option>
                                        <option value="last_30days">Last 30 days</option>
                                        <option value="month">MTD</option>
                                        <option value="previous_month">Previous Month</option>
                                        <option value="last_last_month">Last last Month</option>
                                        <option value="date_range">Custom Range</option>
                                    </select>
                                </div>
                            </div>

                            {selectedDateRangeOptions === "date_range" && (
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date Start</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'start')} value={dateRangeStart} className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date End</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'end')} value={dateRangeEnd} className="form-control"/>
                                    </div>
                                </div>
                            )}

                            <Button className="me-2" variant="info" onClick={() => getStatement()}>
                                Get Statement
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {loadingBadge === true && (
                <Badge bg="info">Loading</Badge>
            )}

            {tableDataTotal.length > 0 &&
                <div className="card pt-3 pb-3">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="mb-2" style={{display: 'table', margin: '0px auto'}}>
                                <b>Total data</b>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Revenue
                                <h3>${Intl.NumberFormat("en").format(Math.floor(totalRevenue))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Expense
                                <h3>${Intl.NumberFormat("en").format(Math.floor(totalExpense))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Income
                                <h3>${Intl.NumberFormat("en").format(Math.floor(totalIncome))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Margin
                                <h3>{totalMarginRate.toFixed(2)}%</h3>
                            </div>
                        </div>
                        
                    </div>
                </div>
            }

            {tableDataTotal.length > 0 &&
                <div className="card pt-3 pb-3">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="mb-2" style={{display: 'table', margin: '0px auto'}}>
                                <b>Desktop data</b>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Desktop Revenue
                                <h3>${Intl.NumberFormat("en").format(Math.floor(desktopData.revenue))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Expense
                                <h3>${Intl.NumberFormat("en").format(Math.floor(desktopData.expense))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Income
                                <h3>${Intl.NumberFormat("en").format(Math.floor(desktopData.income))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Margin
                                <h3>{desktopData.margin_rate.toFixed(2)}%</h3>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {tableDataTotal.length > 0 &&
                <div className="card pt-3 pb-3">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="mb-2" style={{display: 'table', margin: '0px auto'}}>
                                <b>Mobile data</b>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Mobile Revenue
                                <h3>${Intl.NumberFormat("en").format(Math.floor(mobileData.revenue))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Expense
                                <h3>${Intl.NumberFormat("en").format(Math.floor(mobileData.expense))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Income
                                <h3>${Intl.NumberFormat("en").format(Math.floor(mobileData.income))}</h3>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Margin
                                <h3>{mobileData.margin_rate.toFixed(2)}%</h3>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {tableDataTotal.length > 0 &&
                <div className="card pt-3 pb-3">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div style={{display: 'table', margin: '0px auto'}}>
                                Period: 
                                <h3>{period}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {tableDataTotal.length > 0 &&
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <h3>Revenue</h3>
                                <Bar data={chartDataMain} height={chartHeight} options={chartOptionsMain} />
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <h3>Expense</h3>
                                <Bar data={chartDataPub} height={chartHeight} options={chartOptionsMain} />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {tableDataTotal.length > 0 &&
                <div style={{display: 'table', margin: '0px auto'}}>
                    <Button className="me-2 mb-4" variant="success" onClick={() => expandTable()}>
                        {expandCollapseTable}
                    </Button>
                </div>
            }

            { (tableDataTotal.length > 0 && showTable === true) &&
                <div className="row">
                    <div className="col-12">
                        <div className="card">

                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Date</strong></th>
                                            <th><strong>Revenue</strong></th>
                                            <th><strong>Expense</strong></th>
                                            <th><strong>Income</strong></th>
                                        </tr>
                                    </thead>
                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody>
                                                <tr>
                                                    <td><strong>{item.name}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.revenue)}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.expense)}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.income)}</strong></td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}

                                    {(tableData && showTable === true) &&
                                        tableData.map((item, key) => (
                                            <tbody>
                                                <tr>
                                                    <td>{item.date}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.expense)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.income)}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}

                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            }

            { (tableDataTotal.length > 0 && showTable === true) &&
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Date</strong></th>
                                            <th><strong>Revenue</strong></th>
                                        </tr>
                                    </thead>
                                    {tableDataFeed &&
                                        tableDataFeed.map((item, key) => (
                                            <tbody>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        <i className="fa-solid fa-circle-plus" name={item.advertiser_name} onClick={() => openFeed(item.advertiser_id, item.feed_name)}></i> {item.date}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.feed_name} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.feed_name}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.revenue)}</td>
                                                    </tr>
                                                    );
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </Table>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Publisher</strong></th>
                                            <th><strong>Expense</strong></th>
                                        </tr>
                                    </thead>
                                    {tableDataPublisher &&
                                        tableDataPublisher.map((item, key) => (
                                            <tbody>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        <i className="fa-solid fa-circle-plus" name={item.advertiser_name} onClick={() => openPublisher(item.advertiser_id, item.feed_name)}></i> {item.date}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.feed_name} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.feed_name}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.pub_rev)}</td>
                                                    </tr>
                                                    );
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </Fragment>
    )

}

export default Income;