import React,{Fragment,useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert, Modal } from "react-bootstrap";
import { SketchPicker } from 'react-color';
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import {reducer} from '../bootstrap/alertReducer';
import DatePicker  from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg
            viewBox='0 0 24 24'
            width='24'
            height='24'
            stroke='currentColor'
            strokeWidth='2'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='me-2'
        >
        <polyline points='9 11 12 14 22 4'></polyline>
        <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg
          viewBox='0 0 24 24'
          width='24'
          height='24'
          stroke='currentColor'
          strokeWidth='2'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='me-2'
        >
          <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
          <line x1='15' y1='9' x2='9' y2='15'></line>
          <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const AdvertiserEdit = () => {
    const [basicModal, setBasicModal] = useState(false);
    useEffect(() => {
        getAdvertiser();
        // eslint-disable-next-line
    }, []);

    const [searchParams] = useSearchParams();
    const [state, dispatch] = useReducer(reducer, initial);
    const [activeStatus, setActiveStatus] = useState('yes');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [color, setColor] = useState('#ffffff');

    function handleChangeColor(color) {
        setColor(color.hex);
    };

    async function saveAdvertiser() {
        const data = {
            advertiserId: searchParams.get("id"),
            active: activeStatus,
            name: name,
            address: address,
            contact: contact,
            email: email,
            phone: phone,
            entry_date: entryDate,
            color: color
        }
        const response = await axios.post(baseURL+'/api/advertiser_edit', data)
        setAlertStatus(response.data.status);

        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
        
    }

    async function getAdvertiser() {
        const data = {
            advertiserId: searchParams.get("id")
        }
        const response = await axios.post(baseURL+'/api/advertiser_get', data)

        if(response.data.status === 'ok') {
            setName(response.data.data[0].name);
            setAddress(response.data.data[0].address);
            setContact(response.data.data[0].billing_contact);
            setEmail(response.data.data[0].billing_email);
            setPhone(response.data.data[0].billing_phone);
            setEntryDate(response.data.data[0].entry_date);
            setColor(response.data.data[0].color);
        }
    }

    async function deleteAdvertiser() {
        const data = {
            advertiserId: searchParams.get("id")
        }
        const response = await axios.post(baseURL+'/api/advertiser_detele', data)

        if(response.data.status === 'ok') {
            document.getElementById("cancel_link").click();
        }
    }

    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function changeName(event) {
        setName(event.target.value);
    }

    function changeAddress(event) {
        setAddress(event.target.value);
    }

    function changeContact(event) {
        setContact(event.target.value);
    }

    function changeEmail(event) {
        setEmail(event.target.value);
    }

    function changePhone(event) {
        setPhone(event.target.value);
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Edit Advertiser</h2>
                <Link to={"/advertiser-management?set_saved_options=yes"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Advertiser Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={name} onChange={(event) => changeName(event)} placeholder="Advertiser Name"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-address">Company Address</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-address" value={address} onChange={(event) => changeAddress(event)} placeholder="Company Address"/>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-contact">Billing Contact</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-contact" value={contact} onChange={(event) => changeContact(event)} placeholder="Billing Contact"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-email">Billing Email</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-email" value={email} onChange={(event) => changeEmail(event)} placeholder="Billing Email"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-phone">Billing Phone number</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-phone" value={phone} onChange={(event) => changePhone(event)} placeholder="Billing Phone number"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveAdvertiser()}>
                                Save
                            </Button>

                            <Link to="/advertiser-management?set_saved_options=yes" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            <Button variant="danger" className="" onClick={() => setBasicModal(true)}>
                                Delete
                            </Button>

                            <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                <Modal.Header>
                                    <h4 className="text-black mb-2">&nbsp;</h4>
                                    <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4 className="text-black">Are you sure to delete the advertiser?</h4>
                                    <Button variant="danger" className="" onClick={() => deleteAdvertiser()}>
                                        Delete
                                    </Button>
                                </Modal.Body>
                            </Modal>


                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'update' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The data has been updated successfully.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
    
}

export default AdvertiserEdit;