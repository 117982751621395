import React,{Fragment} from 'react';

const NoPermissions = () => {
    return(
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header border-0 flex-wrap pb-0 select-btn">
                            <div className="">
                                <h4 className="fs-20 text-black">Permissions</h4>
                                <p>Your account does not have any permissions. Please contact the administrator.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NoPermissions;