import React, { Fragment, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination } from "react-bootstrap";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import PermissionGate from '../PermissionGate';

const Users = () => {
    const [tableData, setTableData] = useState([]);

    const [active, setActive] = useState(1);
    const [maxPage, setMaxPage] = useState(5);
    const [pageItems, setPageItems] = useState([{p:1},{p:2}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);

    const [sortBy, setSortBy] = useState('ASC');
    const [orderBy, setOrderBy] = useState('id');
    const [email, setEmail] = useState('');

    useEffect(() => {
        getUsers(1);
        // eslint-disable-next-line
    }, []);

    function changeUserSerach(event) {
        setEmail(event.target.value);
    }

    function setNavigationLinks(max_page) {
        var page_items_tmp = [];
        for (let number = active; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        var max_page = maxPage;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
        getUsers(page_number);
    }

    function changePageNoReload(page, max_page) {
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getUsers(page_number, sort_by, order_by) {
        var sort_by_value = sortBy;
        if(sort_by !== undefined) {
            sort_by_value = sort_by;
        }

        var order_by_value = orderBy;
        if(order_by !== undefined) {
            order_by_value = order_by;
        }

        const data = {
            email: email,
            page: page_number,
            sort_by: sort_by_value,
            order_by: order_by_value,
        }
        const response = await axios.post(baseURL+'/api/user_management/get_users', data)
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        setNavigationLinks(response.data.max_page)

        setTableData(response.data.data);
        setLoadingBadge(false);
        changePageNoReload(page_number, response.data.max_page);
        return "";
    }

    async function sortTable(value) {
        if(sortBy === 'ASC') {
            //Sort By Desc
            setSortBy('DESC')
            setOrderBy(value)
            setTableData([]);
            getUsers(1, 'DESC', value);
        } else {
            //Sort By Asc
            setSortBy('ASC')
            setOrderBy(value)
            setTableData([]);
            getUsers(1, 'ASC', value);
        }
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">User Management</h2>
                <Link to={"/user-new"} className="btn btn-secondary text-white mb-2"><i className="la la-list scale5 me-3"></i>New User</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <input
                                        type="text"
                                        className="form-control input-default "
                                        placeholder="Search user by email"
                                        onChange={(event) => changeUserSerach(event)}
                                    />
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => {getUsers(1, 'ASC', 'id')} }>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('id')} >User ID</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('email')} >Email</strong></th>
                                            <th><strong>Action</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                <tr key={key+1100}>
                                                    <td>{item.id}</td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        <Link to={`/user-edit?id=${item.id}`}>
                                                            <Button className="me-2" variant="info" size="sm">
                                                                Edit
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}

                                <Pagination
                                    size={"sm"}
                                    className={`mt-4  ${true ? "pagination-gutter" : ""} ${
                                        "info" && `pagination-${"info"}`
                                    } ${!false && "no-bg"} ${false && "pagination-circle"}`}
                                >
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    {pageItems.length > 0 &&
                                        pageItems.map((item, key) => (
                                            <li key={key+1200} className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}

                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default Users;