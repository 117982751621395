import React,{Fragment, useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert } from "react-bootstrap";
import {reducer} from '../bootstrap/alertReducer';
import DatePicker  from "react-datepicker";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { SketchPicker } from 'react-color';
import Select from "react-select";
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const IPQSCampaignNew = () => {
    let navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initial);
    const [activeStatus, setActiveStatus] = useState('yes');
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [color, setColor] = useState('#ffffff');
    const [campaignOptionList, setCampaignOptionList] = useState([]);
    const [campaignOptionListAll, setCampaignOptionListAll] = useState([]);
    const [dailySampleSize, setDailySampleSize] = useState('');
    const [selectedAdvFeedOptions, setSelectedAdvFeedOptions] = useState([]);
    const [advFeedOptionList, setAdvFeedOptionList] = useState([]);
    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState(false);
    const [selectedPubOptions, setSelectedPubOptions] = useState([]);
    const [pubOptionList, setPubOptionList] = useState([]);
    const [pubOptionListAll, setPubOptionListAll] = useState([]);

    function handleChangeColor(color) {
        setColor(color.hex);
    };

    useEffect(() => {
        getColor();
        getAdvFeedOptionList();
        getPubOptionList();
        getCampaignOptionList();
    }, []);

    async function getAdvFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_adv_feed_list')
        setAdvFeedOptionList(response.data);
    }

    async function getPubOptionList() {
        const response = await axios.get(baseURL+'/api/get_publisher_list')
        setPubOptionListAll(response.data);
        setPubOptionList(response.data);
    }

    async function getCampaignOptionList() {
        const response = await axios.post(baseURL+'/api/ipqs_report/get_campaign_list')
        setCampaignOptionListAll(response.data.data);
        setCampaignOptionList(response.data.data);
    }

    function handleSelectAdvFeed(data) {
        setSelectedAdvFeedOptions(data);

        if(data.length === 0) {
            setPubOptionList(pubOptionListAll);
        } else {
            var new_publishers = [];
            for(const publisher of pubOptionListAll) {
                if(publisher.id === 0) {
                    new_publishers.push(publisher);
                } else {
                    for(const feed_id of publisher.feed_ids) {
                        if(data.id === feed_id) {
                            new_publishers.push(publisher);
                        }
                    }
                }
            }
            new_publishers = [...new Set(new_publishers)]
            setPubOptionList(new_publishers);
        }
    }

    function handleSelectPub(data) {
        setSelectedPubOptions(data);

        if(data.length === 0) {
            setCampaignOptionList(campaignOptionListAll);
        } else {
            var new_campaigns = [];
            for(const campaign of campaignOptionListAll) {
                if(campaign.id === 0) {
                    new_campaigns.push(campaign);
                } else {
                    if(data.id === campaign.publisher_id) {
                        new_campaigns.push(campaign);
                    }
                }
            }
            setCampaignOptionList(new_campaigns);
        }
    }

    function selectCampaign(data) {
        setSelectedCampaignOptions(data);
    }

    async function getColor() {
        const data = {
            type: 'ipqs_campaign'
        }
        const response = await axios.post(baseURL+'/api/get_color', data)
        setColor(response.data.data.color);
    }

    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function changeDailySampleSize(event) {
        if(parseInt(event.target.value) >= 0) {
            setDailySampleSize(parseInt(event.target.value));
        } else {
            setDailySampleSize('');
        }
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    async function saveIQPSManagement() {
        const data = {
            active: activeStatus,
            campaign: selectedCampaignOptions,
            daily_sample_size: dailySampleSize,
            entry_date: entryDate,
            color: color,
            channel_name: ''
        }
        const response = await axios.post(baseURL+'/api/ipqs_campaign_save', data)

        setAlertStatus(response.data.status);
        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
        if(response.data.status === 'insert') {
            navigate('/ipqs-management');
        }
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">New IPQS Campaign</h2>
                <Link to={"/ipqs-management"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Select Adv Feed</label>
                                        <div className="col-lg-6">
                                            <Select
                                                options={advFeedOptionList}
                                                placeholder="Select Adv Feed"
                                                value={selectedAdvFeedOptions}
                                                onChange={(event) => handleSelectAdvFeed(event)}
                                                isSearchable={true}
                                                isMulti={false}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Select Publisher</label>
                                        <div className="col-lg-6">
                                            <Select
                                                options={pubOptionList}
                                                placeholder="Select Publisher"
                                                value={selectedPubOptions}
                                                onChange={(event) => {handleSelectPub(event)}}
                                                isSearchable={true}
                                                isMulti={false}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Select Campaign</label>
                                        <div className="col-lg-6">
                                            <Select
                                                options={campaignOptionList}
                                                placeholder="Select Campaign"
                                                value={selectedCampaignOptions}
                                                onChange={(event) => {selectCampaign(event)}}
                                                isSearchable={true}
                                                isMulti={false}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Daily Sample Size</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={dailySampleSize} onChange={(event) => changeDailySampleSize(event)} placeholder="Daily Sample Size"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveIQPSManagement()}>
                                Save
                            </Button>

                            <Link to="/ipqs-management" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'insert' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The New Feed has been added.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default IPQSCampaignNew;