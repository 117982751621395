import React,{Fragment,useState,useContext, useEffect} from 'react';
import { Button, Table, Badge } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import  DatePicker  from "react-datepicker";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PermissionGate from '../PermissionGate';

const Advertiser = () => {
    const { background } = useContext(ThemeContext);
    const [selectedReportType, setSelectedReportType] = useState('day');
    const [selectedAdvOptions, setSelectedAdvOptions] = useState([]);
    const [advOptionList, setAdvOptionList] = useState([]);
    const [selectedDateRangeOptions, setSelectedDateRangeOptions] = useState('last_7days');
    const [selectedParameter, setselectedParameter] = useState('revenue');
    const [selectedParameter2, setselectedParameter2] = useState('rpm');
    const [selectedBreakDown, setSelectedBreakDown] = useState('advertiser');
    const [tableData, setTableData] = useState([]);
    const [tableDataTotal, setTableDataTotal] = useState([]);
    const [dateRangeStart, setDateRangeStart] = useState(new Date().toISOString().split('T')[0]);
    const [dateRangeEnd, setDateRangeEnd] = useState(new Date().toISOString().split('T')[0]);
    const [allResponseData, setAllResponseData] = useState({});

    const [chartLabels, setChartLabels] = useState([]);
    const [chartLabels2, setChartLabels2] = useState([]);

    const [chartData, setChartData] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [chartColors, setChartColors] = useState([]);

    const [chartDataset, setChartDataset] = useState([]);
    const [chartDataset2, setChartDataset2] = useState([]);

    const [chartHeight, setChartHeight] = useState(20);
    const [exportToCsvButtonText, setExportToCsvButtonText] = useState('Export to CSV');
    const [sortBy, setSortBy] = useState('asc');
    const [expandCollapseText, setExpandCollapseText] = useState('Expand All');
    const [loadingBadge, setLoadingBadge] = useState(false);

    const [expandCollapseTable, setExpandCollapseTable] = useState('Expand Table');
    const [showTable, setShowTable] = useState(false);
    const [chartDataAllFeeds, setChartDataAllFeeds] = useState([]);

    const [revenueStat, setRevenueStat] = useState([]);

    useEffect(() => {
        getAdvOptionList();
        getRevenueStat();
    }, []);

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    // Multi-Select Conf - START //
    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
        return (<div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props)}><div style={{ padding: "6px 5px" }}>{children}</div></div>);
    };
    const ClearIndicatorStyles = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "black" : "black", minHeight: "50px" });
    const ClearIndicatorStylesDark = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "white" : "white", minHeight: "50px" });
    // Multi-Select Conf - END //

    // Charts - START //
    const chartDataMain = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                data: chartData
            },
        ],
    };

    const chartDataMain2 = {
        defaultFontFamily: "Poppins",
        labels: chartLabels2,
        datasets: [
            {
                label: "",
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                data: chartData2
            },
        ],
    };

    const chartDataCountry = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: [
            {
                label: "",
                backgroundColor: (context) => { return getGradient(chartData, context.raw); },
                hoverBackgroundColor: (context) => { return getGradient(chartData, context.raw); },
                data: chartData
            },
        ],
    };

    const chartDataCountry2 = {
        defaultFontFamily: "Poppins",
        labels: chartLabels2,
        datasets: [
            {
                label: "",
                backgroundColor: (context) => { return getGradient(chartData, context.raw); },
                hoverBackgroundColor: (context) => { return getGradient(chartData, context.raw); },
                data: chartData2
            },
        ],
    };

    const chartOptionsMain = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltips: { mode: "index", intersect: false },
            responsive: true,
            datalabels: {
                display: true,
                color: `${checkDatalabelColor()}`,
                anchor: "end",
                offset: -20,
                align: "start"
            }
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    const chartDataDay = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: chartDataset,
        grouped: true,
    };

    const chartDataDay2 = {
        defaultFontFamily: "Poppins",
        labels: chartLabels2,
        datasets: chartDataset2,
        grouped: true,
    };

    const chartOptionsDay = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            return items.dataset.label + ': ' + items.raw;
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter === 'rpm' || selectedParameter === 'cpc' || selectedParameter === 'ctr') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return b.datasetIndex - a.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    const chartOptionsDay2 = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            return items.dataset.label + ': ' + items.raw;
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter2 === 'rpm' || selectedParameter2 === 'cpc' || selectedParameter2 === 'ctr') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return b.datasetIndex - a.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    const chartOptionsMainLegendTitle = {
        plugins:{
            legend: { display: false },
            title: { display: true },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            return items.dataset.label + ': ' + items.raw;
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter === 'rpm' || selectedParameter === 'cpc' || selectedParameter === 'ctr') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return b.datasetIndex - a.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    function getGradient(chartData, raw) {
        const colors = ['#0095EF', '#3C50B1', '#6A38B3', '#A224AD', '#F31D64', '#FE433C'];
        const max_value = Math.max(...chartData);
        const percent = raw * 100 / max_value;
        if(percent < 17) { return colors[0];
        } else if(percent >= 17 && percent < 34) { return colors[1];
        } else if(percent >= 34 && percent < 51) { return colors[2];
        } else if(percent >= 51 && percent < 68) { return colors[3];
        } else if(percent >= 68 && percent < 85) { return colors[4];
        } else if(percent >= 85) { return colors[5]; }
    }

    function checkDatalabelColor() {
        if(background.value === 'dark') { return "white"; }
        else { return "black"; }
    }
    // Charts - END //

    async function sortTable(sort_by) {
        var tableDataTmp = [];
        if(sortBy === 'asc') {
            //Sort By Desc
            tableDataTmp = tableData.sort((p1, p2) => (p1[sort_by] < p2[sort_by]) ? 1 : (p1[sort_by] > p2[sort_by]) ? -1 : 0);
            setSortBy('desc')
        } else {
            //Sort By Asc
            tableDataTmp = tableData.sort((p1, p2) => (p1[sort_by] > p2[sort_by]) ? 1 : (p1[sort_by] < p2[sort_by]) ? -1 : 0);
            setSortBy('asc')
        }
        setExportToCsvButtonText('Export to CSV ');
        setTableData(tableDataTmp);
        await delay(1000);
        setExportToCsvButtonText('Export to CSV');
    }

    function handleSelectParameter(event) {
        setselectedParameter(event);
        if(selectedReportType === 'day') {
            setChartDay(allResponseData, event);
        } else if(selectedReportType === 'advertiser') {
            if(selectedBreakDown === 'advertiser') {
                setChart(allResponseData, event);
            } else {
                setChartDay(allResponseData, event);
            }
        } else if(selectedReportType === 'all_advertisers') {
            setChartAllFeeds(allResponseData, event);
        } else {
            setChart(allResponseData, event);
        }
    }

    function handleSelectParameter2(event) {
        setselectedParameter2(event);
        if(selectedReportType === 'day') {
            setChartDay2(allResponseData, event);
        } else if(selectedReportType === 'advertiser') {
            if(selectedBreakDown === 'advertiser') {
                setChart2(allResponseData, event);
            } else {
                setChartDay2(allResponseData, event);
            }
        } else {
            setChart2(allResponseData, event);
        }
    }

    async function getAdvOptionList() {
        const response = await axios.get(baseURL+'/api/get_advertiser_list')
        setAdvOptionList(response.data);
    }

    async function getRevenueStat() {
        var api_url = '/api/revenue_stat';
        const response = await axios.post(baseURL+api_url)
        setRevenueStat(response.data.result);
    }

    async function getAdvertiserReport() {
        setAllResponseData([]);
        setTableData([]);
        setTableDataTotal([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);
        setChartColors([]);
        setLoadingBadge(true);
        setChartDataAllFeeds([]);

        setShowTable(false);
        setExpandCollapseTable('Expand Table');

        const windowsSize = getWindowSize();

        var data = {
            advertisers: selectedAdvOptions,
            date: selectedDateRangeOptions,
            date_range_start: dateRangeStart,
            date_range_end: dateRangeEnd
        }

        var api_url = '';
        if(selectedReportType === 'day') {
            data = {
                advertisers: selectedAdvOptions,
                date: selectedDateRangeOptions,
                date_range_start: dateRangeStart,
                date_range_end: dateRangeEnd,
                break_down: selectedBreakDown,
            }
            api_url = '/api/advertiser_report/by_day';
        } else if(selectedReportType === 'advertiser') {
            data = {
                advertisers: selectedAdvOptions,
                date: selectedDateRangeOptions,
                date_range_start: dateRangeStart,
                date_range_end: dateRangeEnd,
                break_down: selectedBreakDown,
            }
            api_url = '/api/advertiser_report/by_advertiser';
        } else if(selectedReportType === 'country') {
            api_url = '/api/advertiser_report/by_country';
        } else if(selectedReportType === 'all_advertisers') {
            api_url = '/api/advertiser_report/all_advertisers';
        }
        const response = await axios.post(baseURL+api_url, data)
        setAllResponseData(response.data);

        setTableData(response.data.table);
        setTableDataTotal(response.data.total);
        setChartHeight(60);

        if(selectedReportType === 'day') {
            if(windowsSize.innerWidth < 576) {
                setChartHeight(150);
            } else {
                var chart_height = (5 * parseInt(response.data.datasets.revenue.length));
                if(chart_height >= 100) {
                    setChartHeight(chart_height);
                } else {
                    setChartHeight(100);
                }
            }

            setChartDay(response.data, selectedParameter);
            setChartDay2(response.data, selectedParameter2);
        } else if(selectedReportType === 'advertiser') {

            if(windowsSize.innerWidth < 576) {
                setChartHeight(150);
            } else {
                setChartHeight(60);
            }

            if(selectedBreakDown === 'advertiser') {
                setChartColors(response.data.chart_color);
                setChart(response.data, selectedParameter);
                setChart2(response.data, selectedParameter2);
            } else {
                setChartDay(response.data, selectedParameter);
                setChartDay2(response.data, selectedParameter2);
            }
        } else if(selectedReportType === 'all_advertisers') {
            chart_height = (4 * parseInt(response.data.datasets.revenue.length));
            if(chart_height >= 120) {
                setChartHeight(chart_height);
            } else {
                setChartHeight(120);
            }
            
            setChartAllFeeds(response.data, selectedParameter);
        } else {

            if(windowsSize.innerWidth < 576) {
                setChartHeight(150);
            } else {
                setChartHeight(60);
            }
            
            setChartColors(response.data.chart_color);
            setChart(response.data, selectedParameter);
            setChart2(response.data, selectedParameter2);

        }

        setLoadingBadge(false);
    }

    async function setChartAllFeeds(data, sp) {
        setChartLabels(data.labels);

        var charts_data = [];
        for(const d of data.dataset_list) {
            var chart_dataset = [];
            if(sp === 'revenue') {
                chart_dataset = d.revenue;
            }
            if(sp === 'pub_revenue') {
                chart_dataset = d.pub_rev;
            }
            if(sp === 'impressions') {
                chart_dataset = d.impressions;
            }
            if(sp === 'searches') {
                chart_dataset = d.searches;
            }
            if(sp === 'clicks') {
                chart_dataset = d.clicks;
            }
            if(sp === 'rpm') {
                chart_dataset = d.rpm;
            }
            if(sp === 'cpc') {
                chart_dataset = d.cpc;
            }
            if(sp === 'ctr') {
                chart_dataset = d.ctr;
            }

            charts_data.push({
                feed_name: d.feed_name,
                chartDataMain: {
                    defaultFontFamily: "Poppins",
                    labels: data.labels,
                    datasets: chart_dataset
                },
                chartOptionsMain: chartOptionsMainLegendTitle
            });
        }
        setChartDataAllFeeds(charts_data);
    }

    async function setChartDay(data, sp) {
        setChartLabels(data.labels);
        if(sp === 'revenue') {
            setChartDataset(data.datasets.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartDataset(data.datasets.pub_rev);
        }
        if(sp === 'impressions') {
            setChartDataset(data.datasets.impressions);
        }
        if(sp === 'searches') {
            setChartDataset(data.datasets.searches);
        }
        if(sp === 'clicks') {
            setChartDataset(data.datasets.clicks);
        }
        if(sp === 'rpm') {
            setChartDataset(data.datasets.rpm);
        }
        if(sp === 'cpc') {
            setChartDataset(data.datasets.cpc);
        }
        if(sp === 'ctr') {
            setChartDataset(data.datasets.ctr);
        }
    }

    async function setChartDay2(data, sp) {
        setChartLabels2(data.labels);
        if(sp === 'revenue') {
            setChartDataset2(data.datasets.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartDataset2(data.datasets.pub_rev);
        }
        if(sp === 'impressions') {
            setChartDataset2(data.datasets.impressions);
        }
        if(sp === 'searches') {
            setChartDataset2(data.datasets.searches);
        }
        if(sp === 'clicks') {
            setChartDataset2(data.datasets.clicks);
        }
        if(sp === 'rpm') {
            setChartDataset2(data.datasets.rpm);
        }
        if(sp === 'cpc') {
            setChartDataset2(data.datasets.cpc);
        }
        if(sp === 'ctr') {
            setChartDataset2(data.datasets.ctr);
        }
    }

    async function setChart(data, sp) {
        setChartLabels(data.chart_data.labels);
        if(sp === 'revenue') {
            setChartData(data.chart_data.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartData(data.chart_data.pub_rev);
        }
        if(sp === 'impressions') {
            setChartData(data.chart_data.impressions);
        }
        if(sp === 'searches') {
            setChartData(data.chart_data.searches);
        }
        if(sp === 'clicks') {
            setChartData(data.chart_data.clicks);
        }
        if(sp === 'rpm') {
            setChartData(data.chart_data.rpm);
        }
        if(sp === 'cpc') {
            setChartData(data.chart_data.cpc);
        }
        if(sp === 'ctr') {
            setChartData(data.chart_data.ctr);
        }
    }

    async function setChart2(data, sp) {
        setChartLabels2(data.chart_data.labels);
        if(sp === 'revenue') {
            setChartData2(data.chart_data.revenue);
        }
        if(sp === 'pub_revenue') {
            setChartData2(data.chart_data.pub_rev);
        }
        if(sp === 'impressions') {
            setChartData2(data.chart_data.impressions);
        }
        if(sp === 'searches') {
            setChartData2(data.chart_data.searches);
        }
        if(sp === 'clicks') {
            setChartData2(data.chart_data.clicks);
        }
        if(sp === 'rpm') {
            setChartData2(data.chart_data.rpm);
        }
        if(sp === 'cpc') {
            setChartData2(data.chart_data.cpc);
        }
        if(sp === 'ctr') {
            setChartData2(data.chart_data.ctr);
        }
    }

    async function openAllFeed() {
        tableData.forEach(el => {
            var tr_feeds = document.getElementsByName(el.feed_name);

            if(el.feed_display === 'none') {
                if(expandCollapseText === 'Expand All') {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                
                    el.feed_display = 'revert';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-minus";
                    setExpandCollapseText('Collapse All');
                }
            } else {
                if(expandCollapseText === 'Collapse All') {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                
                    el.feed_display = 'none';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-plus";
                    setExpandCollapseText('Expand All');
                }
            }
        });
    }

    async function openFeed(advertiser_id, feed_name) {
        let tr_feeds = document.getElementsByName(feed_name);

        tableData.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                    el.feed_display = 'revert';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-minus";
                }
            } else {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                    el.feed_display = 'none';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-plus";
                }
            }
        });
    }

    async function openFeedCountry(advertiser_id, country) {
        let tr_feeds = document.getElementsByName(country);

        tableData.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.country === country) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                    el.feed_display = 'revert';
                    document.getElementById(el.country).className = "fa-solid fa-circle-minus";
                }
            } else {
                if(el.country === country) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                    el.feed_display = 'none';
                    document.getElementById(el.country).className = "fa-solid fa-circle-plus";
                }
            }
        });
    }

    function changeDateRange(date, type) {
        const d = new Date(date).toISOString().split('T')[0];
        if(type === 'start') {
            setDateRangeStart(d);
        } else {
            setDateRangeEnd(d);
        }
    }

    async function downloadFile({ data, fileName, fileType }) {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
        a.dispatchEvent(clickEvt)
        a.remove()
        return "";
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    async function exportToCsv() {
        setExportToCsvButtonText('Exporting...');

        var usersCsv = [];
        var row = "";
        var headers = [];
        if(selectedReportType === 'advertiser') {
            // Headers for each column
            headers = ['advertiser_id, advertiser, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr'];
            usersCsv = tableData.reduce((acc, user) => {
                const { advertiser_id, advertiser, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr } = user
                acc.push([advertiser_id, advertiser, Math.round(revenue*100)/100, Math.round(pub_rev*100)/100, impressions, searches, clicks, Math.round(rpm*100)/100, Math.round(cpc*100)/100, Math.round(ctr*100)/100].join(','))
                return acc
            }, [])
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report.csv', fileType: 'text/csv'})
            await delay(3000);

            // Headers for each column
            headers = ['feed_id, feed_name, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr']
            usersCsv = [];
            for(const table of tableData) {
                row = "";
                for(const f of table.feeds) {
                    row = f.feed_id + ',' + f.feed_name + ',' + Math.round(f.revenue*100)/100 + ',' + Math.round(f.pub_rev*100)/100 + ',' + f.impressions + ',' + f.searches + ',' + f.clicks + ',' + Math.round(f.rpm*100)/100 + ',' + Math.round(f.cpc*100)/100 + ',' + Math.round(f.ctr*100)/100;
                    usersCsv.push(row);
                }
            }
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report_details.csv', fileType: 'text/csv'})
        }

        if(selectedReportType === 'day') {
            // Headers for each column
            headers = ['date, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr']
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { date, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr } = user
                acc.push(['"' + date + '"', Math.round(revenue*100)/100, Math.round(pub_rev*100)/100, Math.round(impressions*100)/100, Math.round(searches*100)/100, Math.round(clicks*100)/100, Math.round(rpm*100)/100, Math.round(cpc*100)/100, Math.round(ctr*100)/100].join(','))
                return acc
            }, [])
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report_day.csv', fileType: 'text/csv' })
            await delay(3000);

            // Headers for each column
            headers = ['date, advertiser_name, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr']
            // Convert users data to a csv
            usersCsv = [];
            for(const table of tableData) {
                row = "";
                var date = '"' + table.date + '"';
                for(const f of table.feeds) {
                    row = date + ',' + f.feed_name + ',' + Math.round(f.revenue*100)/100 + ',' + Math.round(f.pub_rev*100)/100 + ',' + Math.round(f.impressions*100)/100 + ',' + Math.round(f.searches*100)/100 + ',' + Math.round(f.clicks*100)/100 + ',' + Math.round(f.rpm*100)/100 + ',' + Math.round(f.cpc*100)/100 + ',' + Math.round(f.ctr*100)/100;
                    usersCsv.push(row);
                }
            }
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report_day_details.csv', fileType: 'text/csv'})
        }

        if(selectedReportType === 'country') {
            // Headers for each column
            headers = ['country, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr']
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { country, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr } = user
                acc.push([country, Math.round(revenue*100)/100, Math.round(pub_rev*100)/100, impressions, searches, clicks, Math.round(rpm*100)/100, Math.round(cpc*100)/100, Math.round(ctr*100)/100].join(','))
                return acc
            }, [])
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report_country.csv', fileType: 'text/csv'})
            await delay(3000);

            // Headers for each column
            headers = ['country, advertiser_name, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr']
            // Convert users data to a csv
            usersCsv = [];
            for(const table of tableData) {
                row = "";
                var country = table.country;
                for(const f of table.feeds) {
                    row = country + ',' + f.feed_name + ',' + Math.round(f.revenue*100)/100 + ',' + Math.round(f.pub_rev*100)/100 + ',' + f.impressions + ',' + f.searches + ',' + f.clicks + ',' + Math.round(f.rpm*100)/100 + ',' + Math.round(f.cpc*100)/100 + ',' + Math.round(f.ctr*100)/100;
                    usersCsv.push(row);
                }
            }
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report_country_details.csv', fileType: 'text/csv'})
        }

        setExportToCsvButtonText('Export to CSV');
    }

    function handleSelectReportType(event) {
        setAllResponseData([]);
        setTableData([]);
        setTableDataTotal([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);
        setSelectedReportType(event);
    }

    function handleSelectBreakDown(event) {
        setAllResponseData([]);
        setTableData([]);
        setTableDataTotal([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);
        setSelectedBreakDown(event);
    }

    function expandTable() {
        if(showTable === false) {
            setShowTable(true);
            setExpandCollapseTable('Collapse Table');
        } else {
            setShowTable(false);
            setExpandCollapseTable('Expand Table');
        }
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Advertiser Report</h2>
            </div>

            {revenueStat &&
                revenueStat.map((item, key) => (
                    <div className="card pt-3 pb-3" key={key+1000}>
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Last 7 days
                                    <h3>${Intl.NumberFormat("en").format(Math.round(parseFloat(item.last_7days)))}</h3>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Last 30 days
                                    <h3>${Intl.NumberFormat("en").format(Math.round(parseFloat(item.last_30days)))}</h3>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Overall (MTD)
                                    <h3>${Intl.NumberFormat("en").format(Math.round(parseFloat(item.mtd)))}</h3>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Overall (YTD)
                                    <h3>${Intl.NumberFormat("en").format(Math.round(parseFloat(item.ytd)))}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Advertisers</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="All Advertisers"
                                        defaultValue={''}
                                        onChange={(event) => setSelectedAdvOptions(event)}
                                        isMulti
                                        options={advOptionList}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Date Range</label>
                                    <select value={selectedDateRangeOptions} onChange={(event) => setSelectedDateRangeOptions(event.target.value)} className="form-control">
                                        {/* <option value="yesterday">Yesterday</option> */}
                                        <option value="last_7days">Last 7 days</option>
                                        <option value="last_14days">Last 14 days</option>
                                        <option value="last_30days">Last 30 days</option>
                                        <option value="month">MTD</option>
                                        <option value="previous_month">Previous Month</option>
                                        <option value="last_last_month">Last last Month</option>
                                        <option value="date_range">Custom Range</option>
                                    </select>
                                </div>
                            </div>

                            {selectedDateRangeOptions === "date_range" && (
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date Start</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'start')} value={dateRangeStart} className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date End</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'end')} value={dateRangeEnd} className="form-control"/>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Report Type</label>
                                    <select value={selectedReportType}  onChange={(event) => handleSelectReportType(event.target.value)} className="form-control">
                                        <option value="all_advertisers">All Advertisers</option>
                                        <option value="day">By Day</option>
                                        <option value="advertiser">By Advertiser</option>
                                        <option value="country">By Country</option>    
                                    </select>
                                </div>
                            </div>

                            {(selectedReportType === "day" || selectedReportType === "advertiser") && (
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Break down</label>
                                        <select value={selectedBreakDown}  onChange={(event) => handleSelectBreakDown(event.target.value)} className="form-control">
                                            <option value="advertiser">By Advertiser</option>
                                            <option value="feed">By Adv Feed</option>
                                        </select>
                                    </div>
                                </div>
                            )}

                            <Button className="me-2" variant="info" onClick={() => getAdvertiserReport()}>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {loadingBadge === true && (
                <Badge bg="info">Loading</Badge>
            )}

            {tableDataTotal &&
                tableDataTotal.map((item, key) => (
                    <div className="card pt-3 pb-3" key={key+1100}>
                        <div className="row">
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Revenue
                                    <h3>${Intl.NumberFormat("en").format(item.revenue)}</h3>
                                    <span style={item.revenue_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.revenue_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.revenue_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                    Searches
                                    <h3>{Intl.NumberFormat("en").format(item.searches)}</h3>
                                    <span style={item.searches_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.searches_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.searches_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    Clicks
                                    <h3>{Intl.NumberFormat("en").format(item.clicks)}</h3>
                                    <span style={item.clicks_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.clicks_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.clicks_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    RPM
                                    <h3>${Intl.NumberFormat("en").format(item.rpm)}</h3>
                                    <span style={item.rpm_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.rpm_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.rpm_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    CPC
                                    <h3>${Intl.NumberFormat("en").format(item.cpc)}</h3>
                                    <span style={item.cpc_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.cpc_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.cpc_percent)}%</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-2">
                                <div className="xs-text-center sm-text-center xs-pt-20 sm-pt-20" style={{display: 'table', margin: '0px auto'}}>
                                    CTR
                                    <h3>{Intl.NumberFormat("en").format(item.ctr)}%</h3>
                                    <span style={item.ctr_percent >= 0 ? {color: 'green'} : {color: 'red'}}><i className={item.ctr_percent >= 0 ? "fa-solid fa-arrow-up": "fa-solid fa-arrow-down"}></i> {Intl.NumberFormat("en").format(item.ctr_percent)}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}

            {(chartData.length > 0 || chartDataset.length > 0) && (
                <div className="">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body pb-0">
                                <div className="form-group mb-3 row">
                                    <label className="col-6 col-sm-3 col-md-3 col-lg-2 col-form-label pt-2" htmlFor="val-api">Display By</label>
                                    <div className="col-6 col-sm-9 col-md-9 col-lg-10">
                                        <select value={selectedParameter}  onChange={(event) => handleSelectParameter(event.target.value)} className="form-control">
                                            <option value="revenue">Revenue</option>
                                            <option value="pub_revenue">Pub Revenue</option>
                                            <option value="impressions">Impressions</option>
                                            <option value="searches">Searches</option>
                                            <option value="clicks">Clicks</option>
                                            <option value="rpm">RPM</option>
                                            <option value="cpc">CPC</option>
                                            <option value="ctr">CTR</option>
                                        </select>
                                    </div>
                                </div>

                                {selectedReportType === 'day' && (
                                    <Bar data={chartDataDay} height={chartHeight} options={chartOptionsDay} />
                                )}
                                {(selectedReportType === 'advertiser' && selectedBreakDown === 'advertiser') && (
                                    <Bar data={chartDataMain} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                                )}
                                {(selectedReportType === 'advertiser' && selectedBreakDown === 'feed') && (
                                    <Bar data={chartDataDay}  height={chartHeight} options={chartOptionsDay} />
                                )}
                                {selectedReportType === 'country' && (
                                    <Bar data={chartDataCountry} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card">
                            <div className="card-body pb-0">
                                <div className="form-group mb-3 row">
                                    <label className="col-6 col-sm-3 col-md-3 col-lg-2 col-form-label pt-2" htmlFor="val-api">Display By</label>
                                    <div className="col-6 col-sm-9 col-md-9 col-lg-10">
                                        <select value={selectedParameter2}  onChange={(event) => handleSelectParameter2(event.target.value)} className="form-control">
                                            <option value="revenue">Revenue</option>
                                            <option value="pub_revenue">Pub Revenue</option>
                                            <option value="impressions">Impressions</option>
                                            <option value="searches">Searches</option>
                                            <option value="clicks">Clicks</option>
                                            <option value="rpm">RPM</option>
                                            <option value="cpc">CPC</option>
                                            <option value="ctr">CTR</option>
                                        </select>
                                    </div>
                                </div>

                                {selectedReportType === 'day' && (
                                    <Bar data={chartDataDay2} height={chartHeight} options={chartOptionsDay2} />
                                )}
                                {(selectedReportType === 'advertiser' && selectedBreakDown === 'advertiser') && (
                                    <Bar data={chartDataMain2} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                                )}
                                {(selectedReportType === 'advertiser' && selectedBreakDown === 'feed') && (
                                    <Bar data={chartDataDay2} height={chartHeight} options={chartOptionsDay2} />
                                )}
                                {selectedReportType === 'country' && (
                                    <Bar data={chartDataCountry2} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            )}
            
            <div className="row">
                {(selectedReportType === 'all_advertisers' && chartDataAllFeeds.length > 0) &&
                    chartDataAllFeeds.map((item, key) => (
                        <div className="col-12 col-md-6" key={key+1200}>
                            <div className="card">
                                <div className="card-body pb-0">

                                    <div className="row">
                                        <div className="form-group mb-3 col-6">
                                            <label>Display By</label>
                                            <select value={selectedParameter}  onChange={(event) => handleSelectParameter(event.target.value)} className="form-control">
                                                <option value="revenue">Revenue</option>
                                                <option value="pub_revenue">Pub Revenue</option>
                                                <option value="impressions">Impressions</option>
                                                <option value="searches">Searches</option>
                                                <option value="clicks">Clicks</option>
                                                <option value="rpm">RPM</option>
                                                <option value="cpc">CPC</option>
                                                <option value="ctr">CTR</option>
                                            </select>
                                        </div>
                                        <div className="col-6 text-right">
                                            <div style={{display:'table', float:'right'}}>
                                                <Badge bg="badge-xl" className='badge-outline-primary'>{item.feed_name}</Badge>
                                            </div>
                                        </div>
                                    </div>

                                    <Bar data={item.chartDataMain} height={chartHeight} options={item.chartOptionsMain} />
                                </div>

                            </div>
                        </div>
                    )
                )}
            </div>

            {selectedReportType === 'advertiser' && tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Name</strong> <span style={{cursor: 'pointer'}} onClick={() => openAllFeed()}>({expandCollapseText})</span></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('revenue')}>Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('pub_rev')}>Pub Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('impressions')}>Impressions</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('searches')}>Searches</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('clicks')}>Clicks</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('rpm')}>RPM</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('cpc')}>CPC</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ctr')}>CTR</strong></th>
                                        </tr>
                                    </thead>
                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody key={key+1200}>
                                                <tr id={item.advertiser_id}>
                                                    <td><strong>{item.name}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.revenue)}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.pub_rev)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.impressions)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.searches)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.clicks)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.rpm)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.cpc)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.ctr)}</strong></td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}

                                    { (tableData && showTable === true) &&
                                        tableData.map((item, key) => (
                                            <tbody key={key+1300}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        <i className="fa-solid fa-circle-plus" name={item.advertiser_name} onClick={() => openFeed(item.advertiser_id, item.feed_name)}></i> {item.advertiser}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.feed_name} key={key+1400} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.feed_name}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.revenue)}</td>
                                                        <td>${Intl.NumberFormat("en").format(feed.pub_rev)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.impressions)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.searches)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.clicks)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.rpm)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.cpc)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.ctr)} %</td>
                                                    </tr>
                                                    );
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </Table>

                                <div style={{display: 'table', margin: '0px auto'}}>
                                    <Button className="me-2" variant="success" onClick={() => expandTable()}>
                                        {expandCollapseTable}
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selectedReportType === 'day' && tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Date</strong> <span style={{cursor: 'pointer'}} onClick={() => openAllFeed()}>({expandCollapseText})</span></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('revenue')}>Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('pub_rev')}>Pub Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('impressions')}>Impressions</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('searches')}>Searches</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('clicks')}>Clicks</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('rpm')}>RPM</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('cpc')}>CPC</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ctr')}>CTR</strong></th>
                                        </tr>
                                    </thead>
                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody key={key+1500}>
                                                <tr id={item.advertiser_id}>
                                                    <td><strong>{item.name}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.revenue)}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.pub_rev)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.impressions)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.searches)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.clicks)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.rpm)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.cpc)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.ctr)}</strong></td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}

                                    { (tableData && showTable === true) &&
                                        tableData.map((item, key) => (
                                            <tbody key={key+1600}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        <i className="fa-solid fa-circle-plus" name={item.advertiser_name} onClick={() => openFeed(item.advertiser_id, item.feed_name)}></i> {item.date}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.feed_name} key={key+1700} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.feed_name}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.revenue)}</td>
                                                        <td>${Intl.NumberFormat("en").format(feed.pub_rev)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.impressions)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.searches)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.clicks)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.rpm)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.cpc)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.ctr)} %</td>
                                                    </tr>
                                                    );
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </Table>

                                <div style={{display: 'table', margin: '0px auto'}}>
                                    <Button className="me-2" variant="success" onClick={() => expandTable()}>
                                        {expandCollapseTable}
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selectedReportType === 'country' && tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Country</strong> <span style={{cursor: 'pointer'}} onClick={() => openAllFeed()}>({expandCollapseText})</span></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('revenue')}>Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('pub_rev')}>Pub Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('impressions')}>Impressions</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('searches')}>Searches</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('clicks')}>Clicks</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('rpm')}>RPM</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('cpc')}>CPC</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ctr')}>CTR</strong></th>
                                        </tr>
                                    </thead>
                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody key={key+1800}>
                                                <tr id={item.advertiser_id}>
                                                    <td><strong>{item.name}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.revenue)}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.pub_rev)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.impressions)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.searches)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.clicks)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.rpm)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.cpc)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.ctr)}</strong></td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}

                                    { (tableData && showTable === true) &&
                                        tableData.map((item, key) => (
                                            <tbody key={key+1900}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        <i className="fa-solid fa-circle-plus" id={item.country} onClick={() => openFeedCountry(item.advertiser_id, item.country)}></i> {item.country}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.country} key={key+2000} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.advertiser}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.revenue)}</td>
                                                        <td>${Intl.NumberFormat("en").format(feed.pub_rev)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.impressions)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.searches)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.clicks)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.rpm)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.cpc)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.ctr)} %</td>
                                                    </tr>
                                                    );
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </Table>

                                <div style={{display: 'table', margin: '0px auto'}}>
                                    <Button className="me-2" variant="success" onClick={() => expandTable()}>
                                        {expandCollapseTable}
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default Advertiser;