import React, { Fragment, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination } from "react-bootstrap";
import Select from "react-select";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import Cookies from 'js-cookie';
import { useSearchParams } from "react-router-dom";
import PermissionGate from '../PermissionGate';

const Advertiser = () => {
    const [searchParams] = useSearchParams();

    const [advOptionList, setAdvOptionList] = useState([]);
    const [selectedAdvOptions, setSelectedAdvOptions] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [advertiserActiveStatus, setAdvertiserActiveStatus] = useState(1);

    const [active, setActive] = useState(1);
    const [maxPage, setMaxPage] = useState(2);
    const [pageItems, setPageItems] = useState([{p:1}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);

    const [sortBy, setSortBy] = useState('ASC');
    const [orderBy, setOrderBy] = useState('id');

    useEffect(() => {
        getAdvOptionList();
        getAdvertisers(1);
        // eslint-disable-next-line
    }, []);

    async function selectSavedAdvertisers(adv_option_list) {
        var selected_adv_string = Cookies.get('selected_adv_string');
        var selected_adv_arr = selected_adv_string.split(',');
        var selected_arr = [];
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.id) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        var selected_default_adv_string = Cookies.get('selected_default_adv_string');
        selected_adv_arr = selected_default_adv_string.split(',');
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.value) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        if(searchParams.get("set_saved_options") === "yes") {
            setSelectedAdvOptions(selected_arr);
            await delay(1000);
            setSelectedAdvOptions(selected_arr);
            getAdvertisers(1, undefined, undefined, selected_arr);
        } else {
            Cookies.set('selected_adv_string', '', { expires: 1, path: '/'});
            Cookies.set('selected_default_adv_string', '', { expires: 1, path: '/'});
        }
    }
    
    function handleSelectAdv(data) {
        setSelectedAdvOptions(data);

        var selected_adv_string = "";
        var selected_default_adv_string = "";
        for(const d of data) {
            if(d.id > 0) {
                selected_adv_string = selected_adv_string + d.id + ',';
            } else {
                selected_default_adv_string = selected_default_adv_string + d.value + ',';
            }
        }
        selected_adv_string = selected_adv_string.slice(0, -1);
        selected_default_adv_string = selected_default_adv_string.slice(0, -1);
        
        Cookies.set('selected_adv_string', selected_adv_string, { expires: 1, path: '/'});
        Cookies.set('selected_default_adv_string', selected_default_adv_string, { expires: 1, path: '/'});
    }

    async function getAdvOptionList() {
        const response = await axios.get(baseURL+'/api/get_advertiser_list?include_all=yes')
        setAdvOptionList(response.data);
        selectSavedAdvertisers(response.data);
        return "";
    }

    function setNavigationLinks(max_page) {
        var page_items_tmp = [];
        for (let number = active; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        var max_page = maxPage;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
        getAdvertisers(page_number);
    }

    function changePageNoReload(page, max_page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getAdvertisers(page_number, sort_by, order_by, selected_adv_options) {
        var sort_by_value = sortBy;
        if(sort_by !== undefined) {
            sort_by_value = sort_by;
        }

        var order_by_value = orderBy;
        if(order_by !== undefined) {
            order_by_value = order_by;
        }

        var selected_adv_options_value = selectedAdvOptions;
        if(selected_adv_options !== undefined) {
            selected_adv_options_value = selected_adv_options;
        }

        const data = {
            advertisers: selected_adv_options_value,
            advertiserActiveStatus: advertiserActiveStatus,
            page: page_number,
            sort_by: sort_by_value,
            order_by: order_by_value,
        }
        const response = await axios.post(baseURL+'/api/advertisers_get', data)
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        
        setTableData(response.data.data);
        setAdvertiserActiveStatus(0);

        setNavigationLinks(response.data.max_page)

        changePageNoReload(page_number, response.data.max_page);
        setLoadingBadge(false);
        return "";
    }

    async function sortTable(value) {
        if(sortBy === 'ASC') {
            setSortBy('DESC')
            setOrderBy(value)
            setTableData([]);
            getAdvertisers(1, 'DESC', value);
        } else {
            setSortBy('ASC')
            setOrderBy(value)
            setTableData([]);
            getAdvertisers(1, 'ASC', value);
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Advertiser Management</h2>
                <Link to={"/advertiser-new"} className="btn btn-secondary text-white mb-2"><i className="la la-list scale5 me-3"></i>New Advertiser</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Advertisers</label>
                                    <Select
                                        options={advOptionList}
                                        placeholder="All Advertisers"
                                        value={selectedAdvOptions}
                                        onChange={(event) => handleSelectAdv(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => {setSortBy('ASC'); setOrderBy('id'); getAdvertisers(1, 'ASC', 'id')} }>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('active')} >Active</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('id')} >Advertiser ID</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('name')} >Company Name</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('address')} >Company Address</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('billing_contact')} >Billing Contact</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('billing_email')} >Billing Email</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('billing_phone')} >Billing Phone number</strong></th>
                                            <th><strong>Color</strong></th>
                                            <th><strong>Action</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                <tr key={key}>
                                                    <td>
                                                        {item.active === 1 && (
                                                            <span>Active</span>
                                                        )}
                                                        {item.active === 0 && (
                                                            <span><i className="text-danger fa-solid fa-circle-stop"></i> Inactive</span>
                                                        )}
                                                    </td>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.billing_contact}</td>
                                                    <td>{item.billing_email}</td>
                                                    <td>{item.billing_phone}</td>
                                                    <td>
                                                        <div style={{width: '40px', height: '20px', background: item.color}}></div>
                                                    </td>
                                                    <td>
                                                        <Link to={`/advertiser-edit?id=${item.id}`}>
                                                            <Button className="me-2" variant="info" size="sm">
                                                                Edit
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}

                                <Pagination size={"sm"} className={`mt-4  ${true ? "pagination-gutter" : ""} ${ "info" && `pagination-${"info"}` } ${!false && "no-bg"} ${false && "pagination-circle"}`}>
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    {pageItems.length > 0 &&
                                        pageItems.map((item) => (
                                            <li className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}

                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default Advertiser;