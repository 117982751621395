import React,{Fragment,useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert, Modal } from "react-bootstrap";
import PermissionGate from '../PermissionGate';
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import {reducer} from '../bootstrap/alertReducer';
import DatePicker  from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import { SketchPicker } from 'react-color';

const emojis = {
    success: (
        <svg
            viewBox='0 0 24 24'
            width='24'
            height='24'
            stroke='currentColor'
            strokeWidth='2'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='me-2'
        >
        <polyline points='9 11 12 14 22 4'></polyline>
        <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg
          viewBox='0 0 24 24'
          width='24'
          height='24'
          stroke='currentColor'
          strokeWidth='2'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='me-2'
        >
          <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
          <line x1='15' y1='9' x2='9' y2='15'></line>
          <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const PublisherEdit = () => {
    const [basicModal, setBasicModal] = useState(false);
    useEffect(() => {
        getAdvertiser();
        // eslint-disable-next-line
    }, []);

    const [searchParams] = useSearchParams();
    const [state, dispatch] = useReducer(reducer, initial);
    const [activeStatus, setActiveStatus] = useState('yes');
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [legacyID, setLegacyID] = useState(0);
    const [address, setAddress] = useState('');
    const [billingContact, setBillingContact] = useState('');
    const [billingEmail, setBillingEmail] = useState('');
    const [billingPhoneNumber, setBillingPhoneNumber] = useState('');
    const [publisherReportAPI, setPublisherReportAPI] = useState('');
    const [note, setNote] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [color, setColor] = useState('#ffffff');

    function handleChangeColor(color) {
        setColor(color.hex);
    };

    async function saveAdvertiser() {
        const data = {
            publisherId: searchParams.get("id"),
            active: activeStatus,
            name: name,
            entry_date: entryDate,
            username: username,
            password: password,
            admin_password: adminPassword,
            address: address,
            billing_contact: billingContact,
            billing_email: billingEmail,
            billing_phone_number: billingPhoneNumber,
            legacy_id: legacyID,
            note: note,
            color: color,
        }
        const response = await axios.post(baseURL+'/api/publisher_edit', data)
        setAlertStatus(response.data.status);

        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
        
    }

    async function getAdvertiser() {
        const data = {
            publisherId: searchParams.get("id")
        }
        const response = await axios.post(baseURL+'/api/publisher_get', data)

        if(response.data.status === 'ok') {
            setName(response.data.data[0].name);
            setEntryDate(response.data.data[0].entry_date);
            if(response.data.data[0].active === 0) {
                setActiveStatus('no');
            }
            setUsername(response.data.data[0].username);

            setLegacyID(response.data.data[0].legacy_id);
            setAddress(response.data.data[0].address);
            setBillingContact(response.data.data[0].billing_contact);
            setBillingEmail(response.data.data[0].billing_email);
            setBillingPhoneNumber(response.data.data[0].billing_phone_number);

            var report_api = baseURL + '/api/pub/get?token=' + response.data.data[0].api_token + '&dateFrom=2023-01-31&dateTo=2023-04-01';
            setPublisherReportAPI(report_api);
            setNote(response.data.data[0].note);
            setColor(response.data.data[0].color);
        }
    }

    async function deleteAdvertiser() {
        const data = {
            publisherId: searchParams.get("id")
        }
        const response = await axios.post(baseURL+'/api/publisher_delete', data)

        if(response.data.status === 'ok') {
            document.getElementById("cancel_link").click();
        }
    }

    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function changeName(event) {
        setName(event.target.value);
    }

    function changeLegacyID(event) {
        var value = 0;
        if(parseInt(event.target.value) > 0) {
            value = parseInt(event.target.value);
        } else {
            value = "";
        }
        setLegacyID(value);
    }

    function changeUsername(event) {
        setUsername(event.target.value);
    }

    function changePassword(event) {
        setPassword(event.target.value);
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    function changeAddress(event) {
        setAddress(event.target.value);
    }

    function changeBillingContact(event) {
        setBillingContact(event.target.value);
    }

    function changeBillingEmail(event) {
        setBillingEmail(event.target.value);
    }

    function changePublisherReportAPI(event) {
        setPublisherReportAPI(event.target.value);
    }

    function changeNote(event) {
        setNote(event.target.value);
    }

    function changeBillingPhoneNumber(event) {
        setBillingPhoneNumber(event.target.value);
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Edit Publisher</h2>
                <Link to={"/publisher-management?set_saved_options=yes"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Publisher Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={name} onChange={(event) => changeName(event)} placeholder="Publisher Name"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Legacy ID</label>
                                        <div className="col-lg-6">
                                            <input type="number" className="form-control" id="val-name" value={legacyID} onWheel={(e) => e.target.blur()} onChange={(event) => changeLegacyID(event)} placeholder="Legacy ID"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Address</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={address} onChange={(event) => changeAddress(event)} placeholder="Address"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Billing Contact</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={billingContact} onChange={(event) => changeBillingContact(event)} placeholder="Billing Contact"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Billing Email</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={billingEmail} onChange={(event) => changeBillingEmail(event)} placeholder="Billing Email"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Billing Phone Number</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={billingPhoneNumber} onChange={(event) => changeBillingPhoneNumber(event)} placeholder="Billing Phone Number"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Username</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={username} onChange={(event) => changeUsername(event)} placeholder="Username"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-password">Password</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-password" value={password} onChange={(event) => changePassword(event)} placeholder="Password"/>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-password">Admin Password</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-password" value={adminPassword} onChange={(event) => setAdminPassword(event.target.value)} placeholder="Admin Password"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Publisher Report API</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={publisherReportAPI} onChange={(event) => changePublisherReportAPI(event)} placeholder="Publisher Report API" />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Note</label>
                                        <div className="col-lg-6">
                                            <textarea rows={4} className="form-control" id="val-name" value={note} onChange={(event) => changeNote(event)} placeholder="Note"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveAdvertiser()}>
                                Save
                            </Button>

                            <Link to="/publisher-management?set_saved_options=yes" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            <Button variant="danger" className="" onClick={() => setBasicModal(true)}>
                                Delete
                            </Button>

                            <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                <Modal.Header>
                                    <h4 className="text-black mb-2">&nbsp;</h4>
                                    <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4 className="text-black">Are you sure to delete the publisher?</h4>
                                    <Button variant="danger" className="" onClick={() => deleteAdvertiser()}>
                                        Delete
                                    </Button>
                                </Modal.Body>
                            </Modal>


                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'update' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The data has been updated successfully.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
    
}

export default PublisherEdit;