import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination, Modal } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import Cookies from 'js-cookie';
import { useSearchParams } from "react-router-dom";
import PermissionGate from '../PermissionGate';

const PublisherFeed = () => {
    const [searchParams] = useSearchParams();

    const { mobileTrColor } = useContext(ThemeContext);
    const [basicModal, setBasicModal] = useState(false);
    useEffect(() => {
        getCampaignOptionList();
        // eslint-disable-next-line
    }, []);

    const [advFeedOptionList, setAdvFeedOptionList] = useState([]);

    const [pubOptionList, setPubOptionList] = useState([]);
    const [pubOptionListAll, setPubOptionListAll] = useState([]);

    const [campaignOptionList, setCampaignOptionList] = useState([]);
    const [campaignOptionListAll, setCampaignOptionListAll] = useState([]);

    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([{ id: 0, value: '1', label: 'Active Campaigns', publisher_id: '', feed_id: ''}]);
    const [selectedPubOptions, setSelectedPubOptions] = useState([]);
    const [selectedAdvFeedOptions, setSelectedAdvFeedOptions] = useState([]);

    const [tableData, setTableData] = useState([]);
    const [feedActiveStatus] = useState(1);

    const [active, setActive] = useState(1);
    const [maxPage, setMaxPage] = useState(5);
    const [pageItems, setPageItems] = useState([{p:1}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [noResultBadge, setNoResultBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);

    const [sortBy, setSortBy] = useState('ASC');
    const [orderBy, setOrderBy] = useState('id');

    const [resetCampaignFeedID, setResetCampaignFeedID] = useState(0);
    const [exportToCsvButtonText, setExportToCsvButtonText] = useState('Export to CSV');
    
    function selectCampaign(data) {
        setSelectedCampaignOptions(data);

        var selected_campaign_string = "";
        var selected_default_campaign_string = "";
        for(const d of data) {
            if(d.id > 0) {
                selected_campaign_string = selected_campaign_string + d.id + ',';
            } else {
                selected_default_campaign_string = selected_default_campaign_string + d.value + ',';
            }
        }
        selected_campaign_string = selected_campaign_string.slice(0, -1);
        selected_default_campaign_string = selected_default_campaign_string.slice(0, -1);
        
        Cookies.set('selected_campaign_string', selected_campaign_string, { expires: 1, path: '/'});
        Cookies.set('selected_default_campaign_string', selected_default_campaign_string, { expires: 1, path: '/'});
    }

    async function selectSavedCampaigns(adv_option_list) {
        var selected_adv_string = Cookies.get('selected_campaign_string');
        var selected_adv_arr = selected_adv_string.split(',');
        var selected_arr = [];
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.id) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        var selected_default_adv_string = Cookies.get('selected_default_campaign_string');
        selected_adv_arr = selected_default_adv_string.split(',');
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.value) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        if(searchParams.get("set_saved_options") === "yes") {
            setSelectedCampaignOptions(selected_arr);
            await delay(1000);
            setSelectedCampaignOptions(selected_arr);
        } else {
            Cookies.set('selected_campaign_string', '', { expires: 1, path: '/'});
            Cookies.set('selected_default_campaign_string', '1', { expires: 1, path: '/'});
        }
    }

    async function selectSavedFeeds(adv_option_list) {
        var selected_adv_string = Cookies.get('selected_feed_string');
        var selected_adv_arr = selected_adv_string.split(',');
        var selected_arr = [];
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.id) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        var selected_default_adv_string = Cookies.get('selected_default_feed_string');
        selected_adv_arr = selected_default_adv_string.split(',');
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.value) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        if(searchParams.get("set_saved_options") === "yes") {
            setSelectedAdvFeedOptions(selected_arr);
            await delay(1000);
            getFeeds(1);
            await delay(1000);
            document.getElementById("search_button").click()
        } else {
            Cookies.set('selected_feed_string', '', { expires: 1, path: '/'});
            Cookies.set('selected_default_feed_string', '', { expires: 1, path: '/'});
            getFeeds(1);
        }
    }

    async function selectSavedPublishers(adv_option_list) {
        var selected_adv_string = Cookies.get('selected_pub_string');
        var selected_adv_arr = selected_adv_string.split(',');
        var selected_arr = [];
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.id) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        var selected_default_adv_string = Cookies.get('selected_default_pub_string');
        selected_adv_arr = selected_default_adv_string.split(',');
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.value) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }


        if(searchParams.get("set_saved_options") === "yes") {
            setSelectedPubOptions(selected_arr);
        } else {
            Cookies.set('selected_pub_string', '', { expires: 1, path: '/'});
            Cookies.set('selected_default_pub_string', '1', { expires: 1, path: '/'});
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    function handleSelectAdvFeed(data) {
        setSelectedAdvFeedOptions(data);

        if(data.length === 0) {
            setPubOptionList(pubOptionListAll);
        } else {
            var new_publishers = [];
            for(const publisher of pubOptionListAll) {
                if(publisher.id === 0) {
                    new_publishers.push(publisher);
                } else {
                    for(const d of data) {
                        for(const feed_id of publisher.feed_ids) {
                            if(d.id === feed_id) {
                                new_publishers.push(publisher);
                            }
                        }
                    }
                }
            }
            new_publishers = [...new Set(new_publishers)]
            setPubOptionList(new_publishers);
        }

        var selected_feed_string = "";
        var selected_default_feed_string = "";
        for(const d of data) {
            if(d.id > 0) {
                selected_feed_string = selected_feed_string + d.id + ',';
            } else {
                selected_default_feed_string = selected_default_feed_string + d.value + ',';
            }
        }
        selected_feed_string = selected_feed_string.slice(0, -1);
        selected_default_feed_string = selected_default_feed_string.slice(0, -1);
        
        Cookies.set('selected_feed_string', selected_feed_string, { expires: 1, path: '/'});
        Cookies.set('selected_default_feed_string', selected_default_feed_string, { expires: 1, path: '/'});
    }

    function handleSelectPub(data) {
        setSelectedPubOptions(data);

        if(data.length === 0) {
            setCampaignOptionList(campaignOptionListAll);
        } else {
            var new_campaigns = [];
            for(const campaign of campaignOptionListAll) {
                if(campaign.id === 0) {
                    new_campaigns.push(campaign);
                } else {
                    for(const d of data) {
                        if(d.id === campaign.publisher_id) {
                            new_campaigns.push(campaign);
                        }
                    }
                }
            }
            setCampaignOptionList(new_campaigns);
        }

        var selected_pub_string = "";
        var selected_default_pub_string = "";
        for(const d of data) {
            if(d.id > 0) {
                selected_pub_string = selected_pub_string + d.id + ',';
            } else {
                selected_default_pub_string = selected_default_pub_string + d.value + ',';
            }
        }
        selected_pub_string = selected_pub_string.slice(0, -1);
        selected_default_pub_string = selected_default_pub_string.slice(0, -1);
        
        Cookies.set('selected_pub_string', selected_pub_string, { expires: 1, path: '/'});
        Cookies.set('selected_default_pub_string', selected_default_pub_string, { expires: 1, path: '/'});
    }

    async function getCampaignOptionList() {
        const response = await axios.get(baseURL+'/api/get_campaign_list?include_all=yes')
        setCampaignOptionListAll(response.data);
        setCampaignOptionList(response.data);
        selectSavedCampaigns(response.data);

        getPubOptionList();
        return "";
    }

    async function getPubOptionList() {
        const response = await axios.get(baseURL+'/api/get_publisher_list')
        setPubOptionListAll(response.data);
        setPubOptionList(response.data);
        selectSavedPublishers(response.data);

        getAdvFeedOptionList();
    }

    async function getAdvFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_adv_feed_list')
        setAdvFeedOptionList(response.data);
        selectSavedFeeds(response.data);
    }

    function setNavigationLinks(max_page) {
        var page_items_tmp = [];
        for (let number = active; number < max_page; number++) {
            if(number <= max_page) {
                page_items_tmp.push({p:number});
            }
        }
        setPageItems(page_items_tmp);
    }

    function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        var max_page = maxPage;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            if(number <= maxPage) {
                page_items_tmp.push({p:number});
            }
            
        }
        setPageItems(page_items_tmp);
        getFeeds(page_number);
    }

    function changePageNoReload(page, max_page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            if(number <= maxPage) {
                page_items_tmp.push({p:number});
            }
        }
        setPageItems(page_items_tmp);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getFeeds(page_number, sort_by, order_by) {
        

        var sort_by_value = sortBy;
        if(sort_by !== undefined) {
            sort_by_value = sort_by;
        }

        var order_by_value = orderBy;
        if(order_by !== undefined) {
            order_by_value = order_by;
        }

        const data = {
            adv_feeds: selectedAdvFeedOptions,
            feeds: selectedCampaignOptions,
            publishers: selectedPubOptions,
            feedActiveStatus: feedActiveStatus,
            page: page_number,
            sort_by: sort_by_value,
            order_by: order_by_value,
        }
        const response = await axios.post(baseURL+'/api/campaigns_get', data)
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        
        var result = [];
        var api_status_date = "";
        for(const d of response.data.data) {
            if(d.api_status_timestamp != null) {
                api_status_date = await convertTimestampToDate(d.api_status_timestamp);
            } else {
                api_status_date = "";
            }
            
            result.push({
                id: d.id,
                name: d.name,
                active: d.active,
                publisher_id: d.publisher_id,
                publisher_name: d.publisher_name,
                api: d.api,
                api_status: d.api_status,
                api_status_timestamp: d.api_status_timestamp,
                api_status_date: api_status_date,
                category: d.category,
                type: d.type,
                entry_date: d.entry_date,
                feed_id: d.feed_id,
                feed_name: d.feed_name,
                color: d.color,
                refid: d.refid,
                channel_name: d.channel_name,
            });
        }
        setTableData(result);
        if(response.data.data.length === 0) {
            setNoResultBadge(true);
        } else {
            setNoResultBadge(false);
        }
        setNavigationLinks(response.data.max_page);

        changePageNoReload(page_number, response.data.max_page);
        setLoadingBadge(false);
    }

    async function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        var utcDate = new Date(date.toUTCString());
        utcDate.setHours(utcDate.getHours());
        var usDate = new Date(utcDate);
        const date_return = usDate.getHours() + ':' + usDate.getMinutes() + ' ' + (usDate.getMonth()+1) + '/' + usDate.getDate() + '/' + usDate.getFullYear();
        return date_return;
    }

    async function updateFeedDataAPI(campaign_id) {
        var id = `update_button_${campaign_id}`;
        document.getElementById(id).innerHTML = "Updating...";
        document.getElementById(id).disabled = true;

        updateDataCampaignAPI(campaign_id);

        setTimeout(() => {
            document.getElementById(id).innerHTML = "Update";
            document.getElementById(id).disabled = false;
        }, 60000);
    }

    async function updateDataCampaignAPI(id) {
        const data = { campaign_id: id }
        await axios.post(baseURL+'/api/campaign_update_data_api', data)
    }

    async function sortTable(value) {
        if(sortBy === 'ASC') {
            //Sort By Desc
            setSortBy('DESC')
            setOrderBy(value)
            setTableData([]);
            getFeeds(1, 'DESC', value);
        } else {
            //Sort By Asc
            setSortBy('ASC')
            setOrderBy(value)
            setTableData([]);
            getFeeds(1, 'ASC', value);
        }
    }

    async function resetCampaignDataAPI() {
        const id = `reset_data_button_${resetCampaignFeedID}`;
        document.getElementById(id).innerHTML = "Reseting...";
        document.getElementById(id).disabled = true;

        resetCampaignDataFeedAPI();
        setBasicModal(false);

        setTimeout(() => {
            document.getElementById(id).innerHTML = "Reset Data";
            document.getElementById(id).disabled = false;
        }, 420000);
    }

    async function resetCampaignDataFeedAPI() {
        const data = { campaign_id: resetCampaignFeedID }
        await axios.post(baseURL+'/api/campaign_reset_data_api', data)
    }

    async function exportToCsv() {
        // Set the button text to indicate that the export is in progress
        setExportToCsvButtonText('Exporting...');

        // Prepare the data
        const data = {
            adv_feeds: selectedAdvFeedOptions,
            feeds: selectedCampaignOptions,
            publishers: selectedPubOptions,
            feedActiveStatus: feedActiveStatus,
            sort_by: sortBy,
            order_by: orderBy,
        }

        // Send a request to the server to retrieve campaign data
        const response = await axios.post(baseURL+'/api/campaigns_get_all', data)
        
        // Process the retrieved data and format it for export
        var result = [];
        for(const d of response.data.data) {
            // Convert API status timestamp to a human-readable date
            const api_status_date = d.api_status_timestamp != null ? await convertTimestampToDate(d.api_status_timestamp) : "";
            result.push({...d, ...{api_status_date: api_status_date}});
        }

        // Define headers for each column in the CSV
        const headers = ['Active, Campaign ID, Campaign, Publisher ID, Publisher, Adv Feed ID, Adv Feed Name, Channel, Feed Type, Entry Date, API Status, Color']

        // Prepare rows of data for the CSV
        var usersCsv = [];
        for(const item of result) {
            // Format values and construct a CSV row
            const active_status = item.active === 1 ? "Active" : "Inactive";
            const api_status = item.api_status === 'ok' ? "[OK] " + item.api_status_date : "Error";
            const channel_name = item.channel_name === undefined ? "" : item.channel_name
            const row = '"' + active_status + '","' + item.id + '","' + item.name + '","' + item.publisher_id + '","' + item.publisher_name + '","' + item.feed_id + '","' + item.feed_name + '","' + channel_name + '","' + item.type + '","' + item.entry_date + '","' + api_status + '","' + item.color + '"';
            usersCsv.push(row);
        }

        // Download the CSV file with the constructed data
        await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'campaigns.csv', fileType: 'text/csv'})

        // Set the button text back to the original state
        setExportToCsvButtonText('Export to CSV');
    }

    async function downloadFile({ data, fileName, fileType }) {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
        a.dispatchEvent(clickEvt)
        a.remove()
        return "";
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Campaign Management</h2>
                <Link to={"/campaign-new"} className="btn btn-secondary text-white mb-2"><i className="la la-list scale5 me-3"></i>New Campaign</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Adv Feeds</label>
                                    <Select
                                        options={advFeedOptionList}
                                        placeholder="All Adv Feeds"
                                        value={selectedAdvFeedOptions}
                                        onChange={(event) => handleSelectAdvFeed(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Publishers</label>
                                    <Select
                                        options={pubOptionList}
                                        placeholder="All Publishers"
                                        value={selectedPubOptions}
                                        onChange={(event) => handleSelectPub(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Campaigns</label>
                                    <Select
                                        options={campaignOptionList}
                                        placeholder="All Campaigns"
                                        value={selectedCampaignOptions}
                                        onChange={(event) => selectCampaign(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <Button id="search_button" className="me-2" variant="info" onClick={() => {setSortBy('ASC'); setOrderBy('id'); getFeeds(1, 'ASC', 'id')} }>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {noResultBadge === true && (
                <Badge bg="info">No results</Badge>
            )}

            {tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('active')} >Active</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('id')} >Campaign ID</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('name')} >Campaign</strong></th>
                                            <th><strong>Publisher ID</strong></th>
                                            <th><strong>Publisher</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('feed_id')} >Adv Feed ID</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('feed_name')} >ADV Feed</strong></th>
                                            <th><strong>Channel</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('type')} >Feed Type</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('entry_date')} >Entry Date</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('api_status')} >API Status</strong></th>
                                            <th><strong>Update</strong></th>
                                            <th><strong>Data Reset</strong></th>
                                            <th><strong>Color</strong></th>
                                            <th><strong>Manage</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                
                                                <tr key={item.name} style={ item.type === 'Mobile' ? {background: mobileTrColor} : {} } >
                                                    <td>
                                                        {item.active === 1 && (
                                                            <span>Active</span>
                                                        )}
                                                        {item.active === 0 && (
                                                            <span><i className="text-danger fa-solid fa-circle-stop"></i> Inactive</span>
                                                        )}
                                                    </td>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.publisher_id}</td>
                                                    <td>{item.publisher_name}</td>
                                                    <td>{item.feed_id}</td>
                                                    <td>{item.feed_name}</td>
                                                    <td>{item.channel_name}</td>
                                                    <td>
                                                        {item.type === 'Mobile' && (
                                                            <span><i className="fa fa-mobile-screen"></i> Mobile</span>
                                                        )}
                                                        {item.type === 'Desktop' && (
                                                            <span><i className="fa fa-computer"></i> Desktop</span>
                                                        )}
                                                    </td>
                                                    <td>{item.entry_date}</td>
                                                    <td>
                                                        {item.api_status === 'ok' && (
                                                            <Button className="me-2" variant="success" size="sm">
                                                                [OK] {item.api_status_date}
                                                            </Button>
                                                        )}
                                                        {item.api_status === 'error' && (
                                                            <Button className="me-2" variant="danger" size="sm">
                                                                Error
                                                            </Button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button className="me-2" variant="warning" size="sm" id={`update_button_${item.id}`} onClick={() => updateFeedDataAPI(item.id)}>
                                                            Update
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Button className="me-2" variant="danger" size="sm" id={`reset_data_button_${item.id}`} onClick={() => {setResetCampaignFeedID(item.id); setBasicModal(true);}}>
                                                            Data Reset
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <div style={{width: '40px', height: '20px', background: item.color}}></div>
                                                    </td>
                                                    <td>
                                                        <Link to={`/campaign-edit?id=${item.id}`}>
                                                            <Button className="me-2" variant="info" size="sm">
                                                                Edit
                                                            </Button>
                                                        </Link>
                                                    </td>

                                                    
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                    <Modal.Header>
                                        <h4 className="text-black mb-2">&nbsp;</h4>
                                        <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h4 className="text-black">Are you sure to remove all data?</h4>
                                        <div className="row">
                                            <div className="float-left" style={{width: 'fit-content'}}>
                                                <Button variant="danger" className="" onClick={() => resetCampaignDataAPI()}>
                                                    Yes
                                                </Button>
                                            </div>
                                            <div className="float-left p-0" style={{width: 'fit-content'}}>
                                                <Button variant="info" className="" onClick={() => setBasicModal(false)}>
                                                    No
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>


                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}                                

                                <Pagination size={"sm"} className={`mt-4  ${true ? "pagination-gutter" : ""} ${ "info" && `pagination-${"info"}` } ${!false && "no-bg"} ${false && "pagination-circle"}`}>
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    {pageItems.length > 0 &&
                                        pageItems.map((item, key) => (
                                            <li key={key} className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}
                                    
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default PublisherFeed;