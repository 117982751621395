import React,{Fragment, useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Alert, Modal } from "react-bootstrap";
import {reducer} from '../bootstrap/alertReducer';
import { useSearchParams, useNavigate } from "react-router-dom";
import DatePicker  from "react-datepicker";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { SketchPicker } from 'react-color';
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const FeedNew = () => {
    let navigate = useNavigate();
    const [basicModal, setBasicModal] = useState(false);
    useEffect(() => {
        getFeed();
        getDataHistory();
        // eslint-disable-next-line
    }, []);

    const [state, dispatch] = useReducer(reducer, initial);
    const [searchParams] = useSearchParams();
    const [activeStatus, setActiveStatus] = useState('yes');
    const [name, setName] = useState('');
    const [api, setAPI] = useState('');
    const [category, setCategory] = useState('BHS');
    const [feedType, setFeedType] = useState('Desktop');
    const [advertiserId, setAdvertiserId] = useState('');
    const [advertiserIdText, setAdvertiserIdText] = useState('');
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [advertiserList, setAdvertiserList] = useState([]);
    const [advertiserListStatus, setAdvertiserListStatus] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [tableDataHistory, setTableDataHistory] = useState([]);
    const [color, setColor] = useState('#ffffff');
    const [channelName, setChannelName] = useState('');
    const [channelList, setChannelList] = useState([]);
    const [channelManual, setChannelManual] = useState(true);

    function chooseChannel(name) {
        var selected_list = channelList;
        selected_list.push({name:name});
        setChannelList(selected_list);
        setChannelName('');
    }

    function removeChannel(name) {
        var selected_list = [];
        for(const channel_name of channelList) {
            if(channel_name.name !== name) {
                selected_list.push(channel_name);
            }
        }
        setChannelList(selected_list);
    }

    function handleChangeColor(color) {
        setColor(color.hex);
    };

    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function handleSelectCategory(value) {
        setCategory(value);
    }
    
    function handleSelectFeedType(value) {
        setFeedType(value);
    }

    function changeName(event) {
        setName(event.target.value);
    }

    function changeAPI(event) {
        setAPI(event.target.value);
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    function chooseAdvertiserId(id, name) {
        setAdvertiserId(id);
        const text = id+' '+name;
        setAdvertiserIdText(text);

        setAdvertiserList([]);
        setAdvertiserListStatus('');
    }

    async function changeAdvertiserID(event) {
        setAdvertiserIdText(event.target.value);
        setAdvertiserId(event.target.value);
        
        const data = { search: event.target.value }
        const response = await axios.post(baseURL+'/api/advertiser_search', data)
        setAdvertiserList(response.data.data);
        setAdvertiserListStatus(response.data.status);
    }
    
    async function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        var utcDate = new Date(date.toUTCString());
        utcDate.setHours(utcDate.getHours());
        var usDate = new Date(utcDate);
        const date_return = usDate.getHours() + ':' + usDate.getMinutes() + ' ' + (usDate.getMonth()+1) + '/' + usDate.getDate() + '/' + usDate.getFullYear();
        return date_return;
    }

    async function getDataHistory() {
        const data = { feed_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/feeds_get_history', data)

        var result = [];
        for(const d of response.data.data) {
            var date = "";
            if(d.date_timestamp !== null) {
                date = await convertTimestampToDate(d.date_timestamp);
            }
            
            result.push({
                id: d.id,
                date: date,
                name: d.name,
                active: d.active,
                advertiser_id: d.advertiser_id,
                api: d.api,
                category: d.category,
                type: d.type,
                entry_date: d.entry_date,
            });
        }
        setTableDataHistory(result);
    }

    async function saveFeed() {
        const data = {
            feed_id: searchParams.get("id"),
            active: activeStatus,
            name: name,
            api: api,
            category: category,
            feed_type: feedType,
            advertiser_id: advertiserId,
            entry_date: entryDate,
            color: color,
            channel_list: channelList
        }
        const response = await axios.post(baseURL+'/api/feed_edit', data)

        setAlertStatus(response.data.status);
        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
        if(response.data.status === 'update') {
            getDataHistory();
        }
    }

    async function getFeed() {
        const data = { feed_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/feed_get', data)

        if(response.data.status === 'ok') {
            setName(response.data.data[0].name);
            setAPI(response.data.data[0].api);
            setCategory(response.data.data[0].category);
            setFeedType(response.data.data[0].type);
            setAdvertiserId(response.data.data[0].advertiser_id);
            setEntryDate(response.data.data[0].entry_date);
            setAdvertiserIdText(response.data.data[0].advertiser_id+' '+response.data.advertiser_name);
            setColor(response.data.data[0].color);

            setChannelList(response.data.channel_list);
            setChannelManual(response.data.channel_manual);
        }
    }

    async function deleteFeed() {
        const data = { feedId: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/feed_delete', data)
        if(response.data.status === 'ok') {
            navigate("/feed-management?set_saved_options=yes");
        }
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Edit Feed</h2>
                <Link to={"/feed-management?set_saved_options=yes"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Advertiser ID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={advertiserIdText} onChange={(event) => changeAdvertiserID(event)} placeholder="Advertiser ID"/>
                                                {advertiserList &&
                                                    advertiserList.map((item, key) => (
                                                        <li style={{cursor: 'pointer'}} className='pt-1 pb-1' onClick={() => chooseAdvertiserId(item.id, item.name)}>Advertiser ID: {item.id}, Name: {item.name}</li>
                                                    )
                                                )}

                                                {advertiserListStatus === 'no' && (
                                                    <li className='pt-1 pb-1'>There is no advertiser with this ID or name.</li>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Feed Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={name} onChange={(event) => changeName(event)} placeholder="Feed Name"/>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-api">Feed API</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-api" value={api} onChange={(event) => changeAPI(event)} placeholder="Feed API"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Channel IDs</label>
                                        
                                        <div className="col-lg-6">
                                            {channelList &&
                                                channelList.map((item, key) => (
                                                    <div className="float-left mt-1 mb-1" style={{display: 'contents'}}>
                                                        <Button className="me-2 btn-xs mt-1 mb-1" variant="info">
                                                            {item.name}{" "}
                                                            {channelManual === true &&
                                                                <span className="btn-icon-right" onClick={() => removeChannel(item.name)}><i className="fa fa-close" /></span>
                                                            }
                                                        </Button>
                                                    </div>
                                                )
                                            )}
                                            
                                            {channelManual === true &&
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" value={channelName} onChange={(e) => setChannelName(e.target.value)} placeholder="Channel ID" />
                                                    <button className="btn btn-primary" type="info" onClick={() => chooseChannel(channelName)} >Add</button>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-category">Feed Category</label>
                                        <div className="col-lg-6">
                                            <select value={category} onChange={(event) => handleSelectCategory(event.target.value)} className="form-control" id="feed-category">
                                                <option value="BHS">BHS</option>
                                                <option value="YHS">YHS</option>
                                                <option value="D2S">D2S</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-type">Feed Type</label>
                                        <div className="col-lg-6">
                                            <select value={feedType} onChange={(event) => handleSelectFeedType(event.target.value)} className="form-control" id="feed-type">
                                                <option value="Desktop">Desktop</option>
                                                <option value="Mobile">Mobile</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveFeed()}>
                                Save
                            </Button>

                            <Link to="/feed-management?set_saved_options=yes" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            <Button variant="danger" className="" onClick={() => setBasicModal(true)}>
                                Delete
                            </Button>

                            <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                <Modal.Header>
                                    <h4 className="text-black mb-2">&nbsp;</h4>
                                    <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4 className="text-black">Are you sure to delete the feed?</h4>
                                    <Button variant="danger" className="" onClick={() => deleteFeed()}>
                                        Delete
                                    </Button>
                                </Modal.Body>
                            </Modal>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'update' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The data has been updated successfully.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header border-0 flex-wrap pb-0 select-btn">
                            <div className="mb-1">
                                <h4 className="fs-20 text-black">Update History</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <Table responsive className="header-border respon-table-data" hover>
                                <thead>
                                    <tr>
                                        <th><strong>Date</strong></th>
                                        <th><strong>Active</strong></th>
                                        <th><strong>Advertiser ID</strong></th>
                                        <th><strong>Feed Name</strong></th>
                                        <th><strong>Feed API</strong></th>
                                        <th><strong>Feed Category</strong></th>
                                        <th><strong>Feed Type</strong></th>
                                        <th><strong>Entry Date</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableDataHistory.length > 0 &&
                                        tableDataHistory.map((item, key) => (
                                            <tr>
                                                <td>{item.date}</td>
                                                <td>{item.active === 1 ? 'Active' : 'Inactive'}</td>
                                                <td>{item.advertiser_id}</td>
                                                <td>{item.name}</td>
                                                <td className='text-truncate' style={{maxWidth: '200px'}}>{item.api}</td>
                                                <td>{item.category}</td>
                                                <td>
                                                    {item.type === 'Mobile' && (
                                                        <span><i className="fa fa-mobile-screen"></i> Mobile</span>
                                                    )}
                                                    {item.type === 'Desktop' && (
                                                        <span><i className="fa fa-computer"></i> Desktop</span>
                                                    )}
                                                </td>
                                                <td>{item.entry_date}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default FeedNew;