import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination, Modal } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import Cookies from 'js-cookie';
import { useSearchParams } from "react-router-dom";
import PermissionGate from '../PermissionGate';

var page_num_tmp = 1;

const Feed = () => {
    const [searchParams] = useSearchParams();

    const { mobileTrColor } = useContext(ThemeContext);
    const [basicModal, setBasicModal] = useState(false);
    useEffect(() => {
        getFeedOptionList();
        getFeeds(1);
        // eslint-disable-next-line
    }, []);

    
    const [feedOptionList, setFeedOptionList] = useState([]);
    const [feedOptionListAll, setFeedOptionListAll] = useState([]);
    const [advOptionList, setAdvOptionList] = useState([]);

    const [selectedFeedOptions, setSelectedFeedOptions] = useState([]);
    const [selectedAdvOptions, setSelectedAdvOptions] = useState([]);

    const [tableData, setTableData] = useState([]);
    const [feedActiveStatus] = useState(1);

    const [active, setActive] = useState(1);
    const [maxPage, setMaxPage] = useState(2);
    const [pageItems, setPageItems] = useState([{p:1}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);

    const [sortBy, setSortBy] = useState('ASC');
    const [orderBy, setOrderBy] = useState('id');

    const [resetDataFeedID, setResetDataFeedID] = useState(0);

    const [exportToCsvButtonText, setExportToCsvButtonText] = useState('Export to CSV');

    async function selectSavedAdvertisers(adv_option_list) {
        var selected_adv_string = Cookies.get('selected_adv_string');
        var selected_adv_arr = selected_adv_string.split(',');
        var selected_arr = [];
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.id) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        var selected_default_adv_string = Cookies.get('selected_default_adv_string');
        selected_adv_arr = selected_default_adv_string.split(',');
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.value) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        if(searchParams.get("set_saved_options") === "yes") {
            setSelectedAdvOptions(selected_arr);
            await delay(1000);
            setSelectedAdvOptions(selected_arr);
            getFeeds(1, undefined, undefined, selected_arr);
        } else {
            Cookies.set('selected_adv_string', '', { expires: 1, path: '/'});
            Cookies.set('selected_default_adv_string', '', { expires: 1, path: '/'});
        }
    }
    
    async function selectSavedFeeds(adv_option_list) {
        var selected_adv_string = Cookies.get('selected_feed_string');
        var selected_adv_arr = selected_adv_string.split(',');
        var selected_arr = [];
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.id) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        var selected_default_adv_string = Cookies.get('selected_default_feed_string');
        selected_adv_arr = selected_default_adv_string.split(',');
        for(const s of selected_adv_arr) {
            for(const a of adv_option_list) {
                if(parseInt(a.value) === parseInt(s)) {
                    selected_arr.push(a);
                }
            }
        }

        if(searchParams.get("set_saved_options") === "yes") {
            setSelectedFeedOptions(selected_arr);
            await delay(1000);
            setSelectedFeedOptions(selected_arr);
        } else {
            Cookies.set('selected_feed_string', '', { expires: 1, path: '/'});
            Cookies.set('selected_default_feed_string', '', { expires: 1, path: '/'});
        }
    }
    
    function handleSelectFeed(data) {
        setSelectedFeedOptions(data);

        var selected_feed_string = "";
        var selected_default_feed_string = "";
        for(const d of data) {
            if(d.id > 0) {
                selected_feed_string = selected_feed_string + d.id + ',';
            } else {
                selected_default_feed_string = selected_default_feed_string + d.value + ',';
            }
        }
        selected_feed_string = selected_feed_string.slice(0, -1);
        selected_default_feed_string = selected_default_feed_string.slice(0, -1);
        
        Cookies.set('selected_feed_string', selected_feed_string, { expires: 1, path: '/'});
        Cookies.set('selected_default_feed_string', selected_default_feed_string, { expires: 1, path: '/'});
    }

    function handleSelectAdv(data) {
        setSelectedAdvOptions(data);
        
        if(data.length === 0) {
            setFeedOptionList(feedOptionListAll);
        } else {
            var new_feeds = [];
            for(const feed of feedOptionListAll) {
                if(feed.id === 0) {
                    new_feeds.push(feed);
                } else {
                    for(const d of data) {
                        if(d.value === feed.advertiser) {
                            new_feeds.push(feed);
                        }
                    }
                }
            }
            setFeedOptionList(new_feeds);
        }

        var selected_adv_string = "";
        var selected_default_adv_string = "";
        for(const d of data) {
            if(d.id > 0) {
                selected_adv_string = selected_adv_string + d.id + ',';
            } else {
                selected_default_adv_string = selected_default_adv_string + d.value + ',';
            }
        }
        selected_adv_string = selected_adv_string.slice(0, -1);
        selected_default_adv_string = selected_default_adv_string.slice(0, -1);
        
        Cookies.set('selected_adv_string', selected_adv_string, { expires: 1, path: '/'});
        Cookies.set('selected_default_adv_string', selected_default_adv_string, { expires: 1, path: '/'});
    }

    async function getFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_feed_list?include_active=yes')
        setFeedOptionListAll(response.data);
        setFeedOptionList(response.data);
        selectSavedFeeds(response.data);

        getAdvOptionList();
    }

    async function getAdvOptionList() {
        const response = await axios.get(baseURL+'/api/get_advertiser_list?include_all=no')
        setAdvOptionList(response.data);
        selectSavedAdvertisers(response.data);
    }

    function setNavigationLinks(max_page) {
        var page_items_tmp = [];
        for (let number = active; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    async function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);
        page_num_tmp = page_number

        var number_first = 1;
        var max_page = maxPage;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
        getFeeds(page_number);
    }

    async function changePageNoReload(page, max_page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);
        page_num_tmp = page_number

        var number_first = 1;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getFeeds(page_number, sort_by, order_by, selected_adv_options) {
        var sort_by_value = sortBy;
        if(sort_by !== undefined) {
            sort_by_value = sort_by;
        }

        var order_by_value = orderBy;
        if(order_by !== undefined) {
            order_by_value = order_by;
        }

        var selected_adv_options_value = selectedAdvOptions;
        if(selected_adv_options !== undefined) {
            selected_adv_options_value = selected_adv_options;
        }

        const data = {
            feeds: selectedFeedOptions,
            advertisers: selected_adv_options_value,
            feedActiveStatus: feedActiveStatus,
            page: page_number,
            sort_by: sort_by_value,
            order_by: order_by_value,
        }
        const response = await axios.post(baseURL+'/api/feeds_get', data)
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        
        var result = [];
        var api_status_date = "";
        for(const d of response.data.data) {
            if(d.api_status_timestamp != null) {
                api_status_date = await convertTimestampToDate(d.api_status_timestamp);
            } else {
                api_status_date = "";
            }
            
            result.push({
                id: d.id,
                name: d.name,
                active: d.active,
                advertiser_id: d.advertiser_id,
                advertiser_name: d.advertiser_name,
                api: d.api,
                api_status: d.api_status,
                api_status_timestamp: d.api_status_timestamp,
                api_status_date: api_status_date,
                category: d.category,
                type: d.type,
                entry_date: d.entry_date,
                color: d.color,
            });
        }
        setTableData(result);
        setNavigationLinks(response.data.max_page);

        changePageNoReload(page_number, response.data.max_page);
        setLoadingBadge(false);

        await delay(1000);
        var reset_update_data_status = response.data.reset_update_data_status;
        var id = "";
        for(const r of reset_update_data_status) {
            if(r.status === null) {
                if(r.action === 'reset') {
                    id = `update_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Update";
                    document.getElementById(id).disabled = true;

                    id = `reset_data_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Reseting...";
                    document.getElementById(id).disabled = true;
                } else {
                    id = `update_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Updating...";
                    document.getElementById(id).disabled = true;

                    id = `reset_data_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Data Reset";
                    document.getElementById(id).disabled = true;
                }
            }

            if(r.status != null) {
                if(r.action === 'reset') {
                    id = `update_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Update";
                    document.getElementById(id).disabled = false;

                    id = `reset_data_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Data Reset";
                    document.getElementById(id).disabled = false;
                } else {
                    id = `update_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Update";
                    document.getElementById(id).disabled = false;

                    id = `reset_data_button_${r.id}`;
                    document.getElementById(id).innerHTML = "Data Reset";
                    document.getElementById(id).disabled = false;
                }
            }
        }

        if(parseInt(response.data.reset_update_data_status_null_amount) > 0) {
            await delay(10000);
            getFeeds(page_num_tmp);
        }

    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    async function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        var utcDate = new Date(date.toUTCString());
        utcDate.setHours(utcDate.getHours());
        var usDate = new Date(utcDate);
        const date_return = usDate.getHours() + ':' + usDate.getMinutes() + ' ' + (usDate.getMonth()+1) + '/' + usDate.getDate() + '/' + usDate.getFullYear();
        return date_return;
    }

    async function updateFeedDataAPI(feed_id) {
        var id = `reset_data_button_${feed_id}`;
        document.getElementById(id).disabled = true;

        id = `update_button_${feed_id}`;
        document.getElementById(id).innerHTML = "Updating...";
        document.getElementById(id).disabled = true;

        updateDataFeedAPI(feed_id);

        await delay(10000);
        getFeeds(active);
    }

    async function updateDataFeedAPI(id) {
        const data = { feed_id: id }
        await axios.post(baseURL+'/api/feed_update_feed_api', data)
    }

    async function resetFeedDataAPI() {
        var id = `update_button_${resetDataFeedID}`;
        document.getElementById(id).disabled = true;
        
        id = `reset_data_button_${resetDataFeedID}`;
        document.getElementById(id).innerHTML = "Reseting...";
        document.getElementById(id).disabled = true;

        resetFeedDataFeedAPI();
        setBasicModal(false);

        await delay(10000);
        getFeeds(active);
    }

    async function resetFeedDataFeedAPI(id) {
        const data = { feed_id: resetDataFeedID }
        await axios.post(baseURL+'/api/feed_reset_data_feed_api', data)
    }

    async function sortTable(value) {
        if(sortBy === 'ASC') {
            //Sort By Desc
            setSortBy('DESC')
            setOrderBy(value)
            setTableData([]);
            getFeeds(1, 'DESC', value);
        } else {
            //Sort By Asc
            setSortBy('ASC')
            setOrderBy(value)
            setTableData([]);
            getFeeds(1, 'ASC', value);
        }
    }

    async function exportToCsv() {
        setExportToCsvButtonText('Exporting...');

        const data = {
            feeds: selectedFeedOptions,
            advertisers: selectedAdvOptions,
            feedActiveStatus: feedActiveStatus,
            sort_by: sortBy,
            order_by: orderBy,
        }
        const response = await axios.post(baseURL+'/api/feeds_get_all', data)
        
        var result = [];
        var api_status_date = "";
        for(const d of response.data.data) {
            if(d.api_status_timestamp != null) {
                api_status_date = await convertTimestampToDate(d.api_status_timestamp);
            } else {
                api_status_date = "";
            }
            
            result.push({
                id: d.id,
                name: d.name,
                active: d.active,
                advertiser_id: d.advertiser_id,
                advertiser_name: d.advertiser_name,
                api: d.api,
                api_status: d.api_status,
                api_status_timestamp: d.api_status_timestamp,
                api_status_date: api_status_date,
                category: d.category,
                type: d.type,
                entry_date: d.entry_date,
                color: d.color,
            });
        }

        var usersCsv = [];
        var row = "";
        var headers = [];

        // Headers for each column
        headers = ['Active, Feed ID, Feed Name, Advertiser Name, Feed Category, Feed Type, API Status, Color']
        usersCsv = [];
        var active_status = "";
        var api_status = "";
        for(const item of result) {
            if(item.active === 1) {
                active_status = "Active";
            } else {
                active_status = "Inactive";
            }

            if(item.api_status === 'ok') {
                api_status = "[OK] " + item.api_status_date;
            } else {
                api_status = "Error";
            }

            row = '"' + active_status + '","' + item.id + '","' + item.name + '","' + item.advertiser_name + '","' + item.category + '","' + item.type + '","' + api_status + '","' + item.color + '"';
            usersCsv.push(row);
        }
        await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'feeds.csv', fileType: 'text/csv'})

        setExportToCsvButtonText('Export to CSV');
    }

    async function downloadFile({ data, fileName, fileType }) {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
        a.dispatchEvent(clickEvt)
        a.remove()
        return "";
    }
    
    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Feed Management</h2>
                <Link to={"/feed-new"} className="btn btn-secondary text-white mb-2"><i className="la la-list scale5 me-3"></i>New Feed</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Advertisers</label>
                                    <Select
                                        options={advOptionList}
                                        placeholder="All Advertisers"
                                        value={selectedAdvOptions}
                                        onChange={(event) => handleSelectAdv(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Feeds</label>
                                    <Select
                                        options={feedOptionList}
                                        placeholder="All Feeds"
                                        value={selectedFeedOptions}
                                        onChange={(event) => handleSelectFeed(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => {setSortBy('ASC'); setOrderBy('id'); getFeeds(1, 'ASC', 'id')} }>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('active')} >Active</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('id')} >Feed ID</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('name')} >Feed Name</strong></th>
                                            <th><strong>Advertiser Name</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('category')} >Feed Category</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('type')} >Feed Type</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('api_status')} >API Status</strong></th>
                                            <th><strong>Update Data</strong></th>
                                            <th><strong>Data Reset</strong></th>
                                            <th><strong>Color</strong></th>
                                            <th><strong>Manage</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                
                                                <tr style={ item.type === 'Mobile' ? {background: mobileTrColor} : {} }>
                                                    <td>
                                                        {item.active === 1 && (
                                                            <span>Active</span>
                                                        )}
                                                        {item.active === 0 && (
                                                            <span><i className="text-danger fa-solid fa-circle-stop"></i> Inactive</span>
                                                        )}
                                                    </td>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.advertiser_name}</td>
                                                    <td>{item.category}</td>
                                                    <td>
                                                        {item.type === 'Mobile' && (
                                                            <span><i className="fa fa-mobile-screen"></i> Mobile</span>
                                                        )}
                                                        {item.type === 'Desktop' && (
                                                            <span><i className="fa fa-computer"></i> Desktop</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.api_status === 'ok' && (
                                                            <Button className="me-2" variant="success" size="sm">
                                                                [OK] {item.api_status_date}
                                                            </Button>
                                                        )}
                                                        {item.api_status === 'error' && (
                                                            <Button className="me-2" variant="danger" size="sm">
                                                                Error
                                                            </Button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button className="me-2" variant="warning" size="sm" id={`update_button_${item.id}`} onClick={() => updateFeedDataAPI(item.id)}>
                                                            Update
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Button className="me-2" variant="danger" size="sm" id={`reset_data_button_${item.id}`} onClick={() => {setResetDataFeedID(item.id); setBasicModal(true);}}>
                                                            Data Reset
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <div style={{width: '40px', height: '20px', background: item.color}}></div>
                                                    </td>
                                                    <td>
                                                        <Link to={`/feed-edit?id=${item.id}`}>
                                                            <Button className="me-2" variant="info" size="sm">
                                                                Edit
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                    <Modal.Header>
                                        <h4 className="text-black mb-2">&nbsp;</h4>
                                        <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h4 className="text-black">Are you sure to remove all data?</h4>
                                        <div className="row">
                                            <div className="float-left" style={{width: 'fit-content'}}>
                                                <Button variant="danger" className="" onClick={() => resetFeedDataAPI()}>
                                                    Yes
                                                </Button>
                                            </div>
                                            <div className="float-left p-0" style={{width: 'fit-content'}}>
                                                <Button variant="info" className="" onClick={() => setBasicModal(false)}>
                                                    No
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>


                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}

                                <Pagination
                                    size={"sm"}
                                    className={`mt-4  ${true ? "pagination-gutter" : ""} ${
                                        "info" && `pagination-${"info"}`
                                    } ${!false && "no-bg"} ${false && "pagination-circle"}`}
                                >
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    {pageItems.length > 0 &&
                                        pageItems.map((item) => (
                                            <li className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}
                                    
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default Feed;