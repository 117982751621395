import React, { Fragment, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination } from "react-bootstrap";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import PermissionGate from '../PermissionGate';

const ReportUpdateLog = () => {
    useEffect(() => {
        getReportData(1);
        setNavigationLinks();
        // eslint-disable-next-line
    }, []);

    const [tableData, setTableData] = useState([]);
    const [active, setActive] = useState(1);
    const [maxPage] = useState(5);
    const [pageItems, setPageItems] = useState([{p:1},{p:2}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);
    
    function setNavigationLinks() {
        var page_items_tmp = [];
        for (let number = active; number <= maxPage; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        var max_page = maxPage;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
        getReportData(page_number);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getReportData(page_number) {
        const data = { page: page_number }
        const response = await axios.post(baseURL+'/api/get_report_update_log', data)
        setMaxAvailablePage(response.data.max_page);

        var result = [];
        if(response.data.status === 'ok') {
            for(const d of response.data.data) {
                var date = "";
                if(d.timestamp != null) {
                    date = await convertTimestampToDate(d.timestamp);
                }
                
                result.push({
                    id: d.id,
                    feed_id: d.feed_id,
                    feed_name: d.feed_name,
                    timestamp: d.timestamp,
                    date: date,
                    status: d.status,
                });
            }
            setLoadingBadge(false);
        }
        setTableData(result);
    }

    async function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        var utcDate = new Date(date.toUTCString());
        utcDate.setHours(utcDate.getHours());
        var usDate = new Date(utcDate);
        const date_return = usDate.getHours() + ':' + usDate.getMinutes() + ' ' + (usDate.getMonth()+1) + '/' + usDate.getDate() + '/' + usDate.getFullYear();
        return date_return;
    }
    
    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Report Update Log</h2>
            </div>

            {tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Timestamp</strong></th>
                                            <th><strong>Date</strong></th>
                                            <th><strong>Feed Name</strong></th>
                                            <th><strong>Status</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                
                                                <tr key={key+1100}>
                                                    <td>{item.timestamp}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.feed_name}</td>
                                                    <td>
                                                        {item.status === 'ok' && (
                                                            <Button className="me-2" variant="success" size="sm">
                                                                [OK]
                                                            </Button>
                                                        )}
                                                        {item.status === 'error' && (
                                                            <Button className="me-2" variant="danger" size="sm">
                                                                Error
                                                            </Button>
                                                        )}
                                                        {item.status === 'start' && (
                                                            <Button className="me-2" variant="info" size="sm">
                                                                In process
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}

                                <Pagination
                                    size={"sm"}
                                    className={`mt-4  ${true ? "pagination-gutter" : ""} ${
                                        "info" && `pagination-${"info"}`
                                    } ${!false && "no-bg"} ${false && "pagination-circle"}`}
                                >
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    
                                    {pageItems.length > 0 &&
                                        pageItems.map((item, key) => (
                                            <li key={key+1200} className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}
                                    
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>

                                
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default ReportUpdateLog;