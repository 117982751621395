import React, {
	// Fragment,
	// useState,
	// useContext,
	useEffect
} from 'react';
// import { Button, Table, Badge } from "react-bootstrap";
// import { ThemeContext } from "../../../context/ThemeContext";
// import Select from "react-select";
// import { Bar } from "react-chartjs-2";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';
import {baseURL} from '../../baseURL';
import Cookies from 'js-cookie';
// import DatePicker from "react-datepicker";
// import 'chartjs-plugin-datalabels';
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";

const FeedDetails = () => {
	// const [selectedFeedOptions, setSelectedFeedOptions] = useState([]);

	useEffect(() => {
		checkPermission();
	}, []);

	async function checkPermission() {
		var data = {
			token: Cookies.get('token'),
			pathname: window.location.pathname
		}
		const response = await axios.post(baseURL+'/api/user_management/check_permission', data)
		if(response.data.status === 'error') {
			Cookies.remove('token');
			window.location.href = '/login';
		}
	}


	return(
		<div></div>
	)
	
}

export default FeedDetails;