import React,{Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import PermissionGate from '../PermissionGate';

const FeedNew = () => {
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    //Permissions - START//
    const [advertiserReport, setAdvertiserReport] = useState(false);
    const [dailyStatReport, setDailyStatReport] = useState(false);
    const [feedDetailsReport, setFeedDetailsReport] = useState(false);
    const [channelDetailsReport, setChannelDetailsReport] = useState(false);
    const [publisherReport, setPublisherReport] = useState(false);
    const [incomeStatement, setIncomeStatement] = useState(false);

    const [advertiserManagement, setAdvertiserManagement] = useState(false);
    const [feedManagement, setFeedManagement] = useState(false);
    const [channelManagement, setChannelManagement] = useState(false);
    const [publisherManagement, setPublisherManagement] = useState(false);
    const [campaignManagement, setCampaignManagement] = useState(false);

    const [ipqsLog, setIpqsLog] = useState(false);
    const [ipqsCampaigns, setIpqsCampaigns] = useState(false);
    const [ipqsQuery, setIpqsQuery] = useState(false);

    const [reportUpdateInterval, setReportUpdateInterval] = useState(false);
    const [reportUpdateLog, setReportUpdateLog] = useState(false);

    const [userManagement, setUserManagement] = useState(false);
    //Permissions - END//
    
    async function createUser() {
        const data = {
            email: email,
            password: password,
            permissions: {
                advertiser_report: advertiserReport,
                daily_stat_report: dailyStatReport,
                feed_details_report: feedDetailsReport,
                channel_details_report: channelDetailsReport,
                publisher_report: publisherReport,
                income_statement: incomeStatement,
                advertiser_management: advertiserManagement,
                feed_management: feedManagement,
                channel_management: channelManagement,
                publisher_management: publisherManagement,
                campaign_management: campaignManagement,
                ipqs_log: ipqsLog,
                ipqs_campaigns: ipqsCampaigns,
                ipqs_query: ipqsQuery,
                report_update_interval: reportUpdateInterval,
                report_update_log: reportUpdateLog,
                user_management: userManagement,
            }
        }
        const response = await axios.post(baseURL+'/api/user_management/create_user', data)

        if(response.data.status === 'ok') {
            navigate("/users");
        }
    }
    
    async function selectAllPermissions() {
        setAdvertiserReport(true);
        setDailyStatReport(true);
        setFeedDetailsReport(true);
        setChannelDetailsReport(true);
        setPublisherReport(true);
        setIncomeStatement(true);
        setAdvertiserManagement(true);
        setFeedManagement(true);
        setChannelManagement(true);
        setPublisherManagement(true);
        setCampaignManagement(true);
        setIpqsLog(true);
        setIpqsCampaigns(true);
        setIpqsQuery(true);
        setReportUpdateInterval(true);
        setReportUpdateLog(true);
        setUserManagement(true);
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">New User</h2>
                <Link to={"/users"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-email">Email</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Email"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-password">Password</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-password" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Password"/>
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <div className="">
                                <h4 className="fs-20 text-black">Permissions</h4>
                                <span className="text-info" style={{cursor: 'pointer'}} onClick={() => selectAllPermissions()}>Select All Permissions</span>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>Reports</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="advertiser_report" checked={advertiserReport} onChange={(event) => setAdvertiserReport(!advertiserReport) } />
                                        <label className="form-check-label" htmlFor="advertiser_report">Advertiser Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="daily_stat_report" checked={dailyStatReport} onChange={(event) => setDailyStatReport(!dailyStatReport) } />
                                        <label className="form-check-label" htmlFor="daily_stat_report">Daily Stat Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="feed_retails_report" checked={feedDetailsReport} onChange={(event) => setFeedDetailsReport(!feedDetailsReport) } />
                                        <label className="form-check-label" htmlFor="feed_retails_report">Feed Details Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="channel_details_report" checked={channelDetailsReport} onChange={(event) => setChannelDetailsReport(!channelDetailsReport) } />
                                        <label className="form-check-label" htmlFor="channel_details_report">Channel Details Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="publisher_report" checked={publisherReport} onChange={(event) => setPublisherReport(!publisherReport) } />
                                        <label className="form-check-label" htmlFor="publisher_report">Publisher Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="income_statement" checked={incomeStatement} onChange={(event) => setIncomeStatement(!incomeStatement) } />
                                        <label className="form-check-label" htmlFor="income_statement">Income Statement</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>Advertisers & Feeds</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="advertiser_management" checked={advertiserManagement} onChange={(event) => setAdvertiserManagement(!advertiserManagement) } />
                                        <label className="form-check-label" htmlFor="advertiser_management">Advertiser Management</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="feed_management" checked={feedManagement} onChange={(event) => setFeedManagement(!feedManagement) } />
                                        <label className="form-check-label" htmlFor="feed_management">Feed Management</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="channel_management" checked={channelManagement} onChange={(event) => setChannelManagement(!channelManagement) } />
                                        <label className="form-check-label" htmlFor="channel_management">Channel Management</label>
                                    </div>

                                    <p className="mb-2"><b>Publishers & Campaigns</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="publisher_management" checked={publisherManagement} onChange={(event) => setPublisherManagement(!publisherManagement) } />
                                        <label className="form-check-label" htmlFor="publisher_management">Publisher Management</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="campaign_management" checked={campaignManagement} onChange={(event) => setCampaignManagement(!campaignManagement) } />
                                        <label className="form-check-label" htmlFor="campaign_management">Campaign Management</label>
                                    </div>

                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>IPQS Management</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="ipqs_log" checked={ipqsLog} onChange={(event) => setIpqsLog(!ipqsLog) } />
                                        <label className="form-check-label" htmlFor="ipqs_log">IPQS Log</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="ipqs_campaigns" checked={ipqsCampaigns} onChange={(event) => setIpqsCampaigns(!ipqsCampaigns) } />
                                        <label className="form-check-label" htmlFor="ipqs_campaigns">IPQS Campaigns</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="ipqs_query" checked={ipqsQuery} onChange={(event) => setIpqsQuery(!ipqsQuery) } />
                                        <label className="form-check-label" htmlFor="ipqs_query">IPQS Query</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>Schedule</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="report_update_interval" checked={reportUpdateInterval} onChange={(event) => setReportUpdateInterval(!reportUpdateInterval) } />
                                        <label className="form-check-label" htmlFor="report_update_interval">Report Update Interval</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="report_update_log" checked={reportUpdateLog} onChange={(event) => setReportUpdateLog(!reportUpdateLog) } />
                                        <label className="form-check-label" htmlFor="report_update_log">Report Update Log</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>User Management</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="report_update_interval" checked={userManagement} onChange={(event) => setUserManagement(!userManagement) } />
                                        <label className="form-check-label" htmlFor="report_update_interval">User Management</label>
                                    </div>
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => createUser()}>
                                Save
                            </Button>

                            <Link to="/users" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default FeedNew;