import React,{Fragment, useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert } from "react-bootstrap";
import {reducer} from '../bootstrap/alertReducer';
import DatePicker  from "react-datepicker";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { SketchPicker } from 'react-color';
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const FeedNew = () => {
    let navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initial);
    const [activeStatus, setActiveStatus] = useState('yes');
    const [refid, setRefid] = useState('');
    const [name, setName] = useState('');
    const [api, setAPI] = useState('');
    const [feedType, setFeedType] = useState('Desktop');
    const [publisherId, setpublisherId] = useState('');
    const [publisherIdText, setpublisherIdText] = useState('');
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [advertiserList, setAdvertiserList] = useState([]);
    const [advertiserListStatus, setAdvertiserListStatus] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [advFeedName, setAdvFeedName] = useState('');
    const [advFeedNameText, setAdvFeedNameText] = useState('');
    const [advFeedNameList, setAdvFeedNameList] = useState([]);
    const [advFeedNameListStatus, setAdvFeedNameListStatus] = useState('');
    const [color, setColor] = useState('#ffffff');
    const [note, setNote] = useState('');
    const [channelName, setChannelName] = useState('');
    const [allChannelList, setAllChannelList] = useState([]);
    const [showChannelList, setShowChannelList] = useState([]);
    const [showChannelListStatus, setShowChannelListStatus] = useState('');
    const [campaignServer, setCampaignServer] = useState('clarityseek.com');
    const [editCampaignID, setEditCampaignID] = useState(0);

    function handleChangeColor(color) {
        setColor(color.hex);
    };

    useEffect(() => {
        getColor();
    }, []);

    function chooseChannel(name) {
        setChannelName(name);
        setShowChannelList([]);
        setShowChannelListStatus('')
    }

    function changeShowChannelList(name) {
        setShowChannelListStatus('')
        setShowChannelList([]);
        var selected_list = [];

        if(name !== '') {
            for(const channel_name of allChannelList) {
                var contains = channel_name.name.includes(name)
                if(contains === true) {
                    selected_list.push(channel_name);
                }
            }
            setShowChannelList(selected_list);
            if(selected_list.length === 0) {
                setShowChannelListStatus('no')
            }
        }
    }

    async function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
      
        for (let i = 0; i < 6; i++) {
          const randomIndex = Math.floor(Math.random() * 16);
          color += letters.charAt(randomIndex);
        }
      
        return color;
    }

    async function getColor() {
        // const data = {
        //     type: 'campaign'
        // }
        // const response = await axios.post(baseURL+'/api/get_color', data)
        // setColor(response.data.data.color);

        const color_result = await getRandomColor();
        setColor(color_result);
    }

    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function handleSelectFeedType(value) {
        setFeedType(value);
    }

    function changeRefid(event) {
        setRefid(event.target.value);
    }

    function changeName(event) {
        setName(event.target.value);
    }

    function changeAPI(event) {
        setAPI(event.target.value);
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    function choosepublisherId(id, name) {
        setpublisherId(id);
        const text = id+' '+name;
        setpublisherIdText(text);

        setAdvertiserList([]);
        setAdvertiserListStatus('');
    }

    async function changepublisherId(event) {
        setpublisherIdText(event.target.value);
        setpublisherId(event.target.value);

        if(event.target.value !== "") {
            const data = { search: event.target.value }
            const response = await axios.post(baseURL+'/api/publisher_search', data)
            setAdvertiserList(response.data.data);
            setAdvertiserListStatus(response.data.status);
        } else {
            setAdvertiserList([]);
            setAdvertiserListStatus('');
        }
    }

    async function changeAdvFeedName(event) {
        setAdvFeedName(event.target.value);
        setAdvFeedNameText(event.target.value);

        if(event.target.value !== "") {
            const data = { search: event.target.value }
            const response = await axios.post(baseURL+'/api/feed_search', data)
            setAdvFeedNameList(response.data.data);
            setAdvFeedNameListStatus(response.data.status);
        } else {
            setAdvFeedNameList([]);
            setAdvFeedNameListStatus('');
        }
    }

    function chooseAdvFeedName(id, name) {
        setAdvFeedName(name);
        const text = id+' '+name;
        setAdvFeedNameText(text);

        setAdvFeedNameList([]);
        setAdvFeedNameListStatus('');

        getFeedChannels(id);
    }

    async function saveFeed() {
        const data = {
            active: activeStatus,
            publisher_id: publisherId,
            refid: refid,
            name: name,
            api: api,
            campaign_server: campaignServer,
            feed_type: feedType,
            adv_feed_name: advFeedName,
            entry_date: entryDate,
            color: color,
            note: note,
            channel_name: channelName
        }
        const response = await axios.post(baseURL+'/api/publisher_feed_save', data)

        setAlertStatus(response.data.status);
        if(response.data.status === 'error') {
            setAlertText(response.data.text);
            setEditCampaignID(response.data.campaign_id);
        }
        if(response.data.status === 'insert') {
            navigate('/campaign-management?set_saved_options=yes');
        }
    }

    async function getFeedChannels(feed_id) {
        const data = { feed_id: feed_id }
        const response = await axios.post(baseURL+'/api/feed_channels_get', data)
        setAllChannelList(response.data.channel_list);
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">New Campaign</h2>
                <Link to={"/campaign-management?set_saved_options=yes"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Publisher ID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={publisherIdText} onChange={(event) => changepublisherId(event)} placeholder="Publisher ID"/>
                                                {advertiserList &&
                                                    advertiserList.map((item, key) => (
                                                        <li key={key} className='pt-1 pb-1' style={{cursor:'pointer'}} onClick={() => choosepublisherId(item.id, item.name)}>Advertiser ID: {item.id}, Name: {item.name}</li>
                                                    )
                                                )}

                                                {advertiserListStatus === 'no' && (
                                                    <li className='pt-1 pb-1'>There is no publisher with this ID or name.</li>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">REFID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={refid} onChange={(event) => changeRefid(event)} placeholder="REFID"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Campaign Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={name} onChange={(event) => changeName(event)} placeholder="Campaign Name"/>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-api">Campaign API</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-api" value={api} onChange={(event) => changeAPI(event)} placeholder="Campaign API"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-campaign-server">Campaign Server</label>
                                        <div className="col-lg-6">
                                            <select value={campaignServer} onChange={(event) => setCampaignServer(event.target.value)} className="form-control" id="val-campaign-server" name="val-campaign-server">
                                                <option value="clarityseek.com">clarityseek.com</option>
                                                <option value="metasearch1.com">metasearch1.com</option>
                                                <option value="a.manage.clarityseek.com">a.manage.clarityseek.com</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-type">Campaign Type</label>
                                        <div className="col-lg-6">
                                            <select value={feedType} onChange={(event) => handleSelectFeedType(event.target.value)} className="form-control" id="feed-type">
                                                <option value="Desktop">Desktop</option>
                                                <option value="Mobile">Mobile</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-adv-feed-name">Adv Feed Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-adv-feed-name" value={advFeedNameText} onChange={(event) => changeAdvFeedName(event)} placeholder="Adv Feed Name"/>
                                                {advFeedNameList &&
                                                    advFeedNameList.map((item, key) => (
                                                        <li key={key+1000} className='pt-1 pb-1' style={{cursor:'pointer'}} onClick={() => chooseAdvFeedName(item.id, item.name)}>Adv Feed ID: {item.id}, Name: {item.name}</li>
                                                    )
                                                )}

                                                {advFeedNameListStatus === 'no' && (
                                                    <li className='pt-1 pb-1'>There is no feed with this ID or name.</li>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-adv-feed-name">Channel</label>
                                        <div className="col-lg-6">

                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" value={channelName} onChange={(e) => {changeShowChannelList(e.target.value); setChannelName(e.target.value)}} placeholder="Channel" />
                                            </div>

                                            {showChannelList &&
                                                showChannelList.map((item, key) => (
                                                    <li key={key+2000} className='pt-1 pb-1' style={{cursor: 'pointer'}} onClick={() => chooseChannel(item.name)}>{item.name}</li>
                                                )
                                            )}

                                            {showChannelListStatus === 'no' && (
                                                <li className='pt-1 pb-1'>There is no channel with this name.</li>
                                            )}
                                            
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Note</label>
                                        <div className="col-lg-6">
                                            <textarea rows={4} className="form-control" id="val-name" value={note} onChange={(event) => setNote(event.target.value)} placeholder="Note"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveFeed()}>
                                Save
                            </Button>

                            <Link to="/campaign-management?set_saved_options=yes" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    {editCampaignID > 0 &&
                                        <strong style={{cursor: 'pointer'}} onClick={() => navigate(`/campaign-edit?id=${editCampaignID}`)}><br/>Edit Campaign</strong>
                                    }
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'insert' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The New Feed has been added.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default FeedNew;