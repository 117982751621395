import React,{Fragment,useState,useContext, useEffect} from 'react';
import { Button, Table, Badge } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import  DatePicker  from "react-datepicker";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PermissionGate from '../PermissionGate';

const IPQS = () => {
    const { background } = useContext(ThemeContext);
    const [selectedReportType, setSelectedReportType] = useState('day');
    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState(false);
    const [campaignOptionList, setCampaignOptionList] = useState([]);
    const [campaignOptionListAll, setCampaignOptionListAll] = useState([]);
    const [selectedDateRangeOptions, setSelectedDateRangeOptions] = useState('today');
    const [selectedParameter, setselectedParameter] = useState('ip_repeats');
    const [dailySampleSize, setDailySampleSize] = useState('');
    const [tableData, setTableData] = useState([]);
    const [tableDataTotal, setTableDataTotal] = useState([]);
    const [dateRangeStart, setDateRangeStart] = useState(new Date().toISOString().split('T')[0]);
    const [dateRangeEnd, setDateRangeEnd] = useState(new Date().toISOString().split('T')[0]);
    const [allResponseData, setAllResponseData] = useState({});

    const [selectedAdvFeedOptions, setSelectedAdvFeedOptions] = useState([]);
    const [advFeedOptionList, setAdvFeedOptionList] = useState([]);

    const [selectedPubOptions, setSelectedPubOptions] = useState([]);
    const [pubOptionList, setPubOptionList] = useState([]);
    const [pubOptionListAll, setPubOptionListAll] = useState([]);

    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartDataset, setChartDataset] = useState([]);

    const [chartHeight, setChartHeight] = useState(20);
    const [exportToCsvButtonText, setExportToCsvButtonText] = useState('Save data');
    const [sortBy, setSortBy] = useState('asc');
    const [expandCollapseText, setExpandCollapseText] = useState('Expand All');
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [noDataBadge, setNoDataBadge] = useState(false);

    const [expandCollapseTable, setExpandCollapseTable] = useState('IPQS Table');
    const [showTable, setShowTable] = useState(false);

    const [summaryData, setSummaryData] = useState(false);

    useEffect(() => {
        getCampaignOptionList();
        getPubOptionList();
        getAdvFeedOptionList();
    }, []);

    // Multi-Select Conf - START //
    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
        return (<div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props)}><div style={{ padding: "6px 5px" }}>{children}</div></div>);
    };
    const ClearIndicatorStyles = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "black" : "black", minHeight: "50px" });
    const ClearIndicatorStylesDark = (base, state) => ({ ...base, cursor: "pointer", color: state.isFocused ? "white" : "white", minHeight: "50px" });
    // Multi-Select Conf - END //

    // Charts - START //
    const chartDataMain = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: [
            {
                label: "",
                backgroundColor: (context) => { return getGradient(chartData, context.raw); },
                hoverBackgroundColor: (context) => { return getGradient(chartData, context.raw); },
                data: chartData
            },
        ],
    };

    const chartOptionsMain = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltips: { mode: "index", intersect: false },
            responsive: true,
            datalabels: {
                display: true,
                color: `${checkDatalabelColor()}`,
                anchor: "end",
                offset: -20,
                align: "start"
            }
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    const chartDataDay = {
        defaultFontFamily: "Poppins",
        labels: chartLabels,
        datasets: chartDataset,
        grouped: true,
    };

    const chartOptionsDayVpnTorBot = {
        plugins:{
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    footer: function(items) {
                        // return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0).toFixed(2)
                    },
                    label: function (items) {
                        if(items.raw > 0) {
                            return items.dataset.stack + ': ' + items.raw;
                        } else {
                            return "";
                        }
                    }
                },
                itemSort: function(a, b) {
                    if(selectedParameter === 'vpn_tor_bot') {
                        return a.datasetIndex - b.datasetIndex;
                    } else {
                        return b.datasetIndex - a.datasetIndex;
                    }
                },
            },
            responsive: true,
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: { stacked: true, position: 'bottom' },
            y: { stacked: true },
        }
    };

    function getGradient(chartData, raw) {
        const colors = ['#0095EF', '#3C50B1', '#6A38B3', '#A224AD', '#F31D64', '#FE433C'];
        const max_value = Math.max(...chartData);
        const percent = raw * 100 / max_value;
        if(percent < 17) { return colors[0];
        } else if(percent >= 17 && percent < 34) { return colors[1];
        } else if(percent >= 34 && percent < 51) { return colors[2];
        } else if(percent >= 51 && percent < 68) { return colors[3];
        } else if(percent >= 68 && percent < 85) { return colors[4];
        } else if(percent >= 85) { return colors[5]; }
    }

    function checkDatalabelColor() {
        if(background.value === 'dark') { return "white"; }
        else { return "black"; }
    }
    // Charts - END //

    function handleSelectAdvFeed(data) {
        setSelectedAdvFeedOptions(data);

        if(data.length === 0) {
            setPubOptionList(pubOptionListAll);
        } else {
            var new_publishers = [];
            for(const publisher of pubOptionListAll) {
                if(publisher.id === 0) {
                    new_publishers.push(publisher);
                } else {
                    for(const feed_id of publisher.feed_ids) {
                        if(data.id === feed_id) {
                            new_publishers.push(publisher);
                        }
                    }
                }
            }
            new_publishers = [...new Set(new_publishers)]
            setPubOptionList(new_publishers);
        }
    }

    function handleSelectPub(data) {
        setSelectedPubOptions(data);

        if(data.length === 0) {
            setCampaignOptionList(campaignOptionListAll);
        } else {
            var new_campaigns = [];
            for(const campaign of campaignOptionListAll) {
                if(campaign.id === 0) {
                    new_campaigns.push(campaign);
                } else {
                    if(data.id === campaign.publisher_id) {
                        new_campaigns.push(campaign);
                    }
                }
            }
            setCampaignOptionList(new_campaigns);
        }
    }

    async function getPubOptionList() {
        const response = await axios.get(baseURL+'/api/get_publisher_list')
        setPubOptionListAll(response.data);
        setPubOptionList(response.data);
    }

    async function getAdvFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_adv_feed_list')
        setAdvFeedOptionList(response.data);
    }

    async function sortTable(sort_by) {
        var tableDataTmp = [];
        if(sort_by === 'revenue') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.revenue < p2.revenue) ? 1 : (p1.revenue > p2.revenue) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.revenue > p2.revenue) ? 1 : (p1.revenue < p2.revenue) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'pub_rev') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.pub_rev < p2.pub_rev) ? 1 : (p1.pub_rev > p2.pub_rev) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.pub_rev > p2.pub_rev) ? 1 : (p1.pub_rev < p2.pub_rev) ? -1 : 0);
                setSortBy('asc')
            }
            
        }
        if(sort_by === 'impressions') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.impressions < p2.impressions) ? 1 : (p1.impressions > p2.impressions) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.impressions > p2.impressions) ? 1 : (p1.impressions < p2.impressions) ? -1 : 0);
                setSortBy('asc')
            }
            
        }
        if(sort_by === 'searches') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.searches < p2.searches) ? 1 : (p1.searches > p2.searches) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.searches > p2.searches) ? 1 : (p1.searches < p2.searches) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'clicks') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.clicks < p2.clicks) ? 1 : (p1.clicks > p2.clicks) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.clicks > p2.clicks) ? 1 : (p1.clicks < p2.clicks) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'rpm') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm < p2.rpm) ? 1 : (p1.rpm > p2.rpm) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm > p2.rpm) ? 1 : (p1.rpm < p2.rpm) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'rpm') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm < p2.rpm) ? 1 : (p1.rpm > p2.rpm) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.rpm > p2.rpm) ? 1 : (p1.rpm < p2.rpm) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'cpc') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.cpc < p2.cpc) ? 1 : (p1.cpc > p2.cpc) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.cpc > p2.cpc) ? 1 : (p1.cpc < p2.cpc) ? -1 : 0);
                setSortBy('asc')
            }
        }
        if(sort_by === 'ctr') {
            if(sortBy === 'asc') {
                //Sort By Desc
                tableDataTmp = tableData.sort((p1, p2) => (p1.ctr < p2.ctr) ? 1 : (p1.ctr > p2.ctr) ? -1 : 0);
                setSortBy('desc')
            } else {
                //Sort By Asc
                tableDataTmp = tableData.sort((p1, p2) => (p1.ctr > p2.ctr) ? 1 : (p1.ctr < p2.ctr) ? -1 : 0);
                setSortBy('asc')
            }
        }
        setExportToCsvButtonText('Save data ');
        setTableData(tableDataTmp);
        await delay(1000);
        setExportToCsvButtonText('Save data');
    }

    function selectCampaign(data) {
        setSelectedCampaignOptions(data);
    }
    
    function handleSelectDateRange(event) {
        setSelectedDateRangeOptions(event);
    }

    function handleSelectParameter(event) {
        setselectedParameter(event);
        if(selectedReportType === 'day') {
            setChart(allResponseData, event);
        } else {
            setChart(allResponseData, event);
            
        }
    }

    async function getCampaignOptionList() {
        const response = await axios.post(baseURL+'/api/ipqs_report/get_campaign_list')
        setCampaignOptionListAll(response.data.data);
        setCampaignOptionList(response.data.data);
    }

    async function getReport() {
        setAllResponseData([]);
        setTableData([]);
        setTableDataTotal([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);
        setLoadingBadge(true);
        setNoDataBadge(false);
        setShowTable(false);
        setExpandCollapseTable('IPQS Table');
        setSummaryData(false);

        var data = {
            campaign: selectedCampaignOptions,
            date: selectedDateRangeOptions,
            date_range_start: dateRangeStart,
            date_range_end: dateRangeEnd,
            daily_sample_size: dailySampleSize
        }

        var api_url = '';
        if(selectedReportType === 'day') {
            api_url = '/api/ipqs_report/by_day';
        } else if(selectedReportType === 'ip') {
            api_url = '/api/ipqs_report/by_ip';
        }
        const response = await axios.post(baseURL+api_url, data)

        if(response.data.table_data.length === 0) {
            setNoDataBadge(true);
        } else {
            setSummaryData(response.data.summary_data);
            setTableData(response.data.table_data);
            setTableDataTotal(response.data.table_data_total);

            setAllResponseData(response.data);
            setChartHeight(60);

            if(selectedReportType === 'day') {
                setChart(response.data, selectedParameter);
            } else {
                setChartHeight(100);
                setChart(response.data, selectedParameter);
            }
        }

        setLoadingBadge(false);
    }

    async function setChart(data, sp) {
        setChartLabels(data.chart_data.labels);
        if(sp === 'ip_repeats') {
            setChartLabels(data.chart_data.labels);
            setChartData(data.chart_data.ip_repeats);
        }
        if(sp === 'fraud_score') {
            setChartLabels(data.chart_data.labels);
            setChartData(data.chart_data.fraud_score);
        }
        if(sp === 'vpn_tor_bot') {
            setChartLabels(data.labels);
            setChartDataset(data.datasets.vpn_tor_bot);
        }
        if(sp === 'vpn') {
            setChartLabels(data.chart_data.labels);
            setChartData(data.chart_data.vpn);
        }
        if(sp === 'tor') {
            setChartLabels(data.chart_data.labels);
            setChartData(data.chart_data.tor);
        }
        if(sp === 'bot') {
            setChartLabels(data.chart_data.labels);
            setChartData(data.chart_data.bot);
        }
    }

    async function openAllFeed() {
        tableData.forEach(el => {
            var tr_feeds = document.getElementsByName(el.feed_name);

            if(el.feed_display === 'none') {
                if(expandCollapseText === 'Expand All') {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                
                    el.feed_display = 'revert';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-minus";
                    setExpandCollapseText('Collapse All');
                }
            } else {
                if(expandCollapseText === 'Collapse All') {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                
                    el.feed_display = 'none';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-plus";
                    setExpandCollapseText('Expand All');
                }
            }
        });
    }

    async function openFeed(advertiser_id, feed_name) {
        let tr_feeds = document.getElementsByName(feed_name);

        tableData.forEach(el => {
            if(el.feed_display === 'none') {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'revert'
                    });
                    el.feed_display = 'revert';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-minus";
                }
            } else {
                if(el.advertiser_id === advertiser_id) {
                    tr_feeds.forEach(element => {
                        element.style.display = 'none'
                    });
                    el.feed_display = 'none';
                    document.getElementsByName(el.advertiser_name)[0].className = "fa-solid fa-circle-plus";
                }
            }
        });
    }

    function changeDateRange(date, type) {
        const d = new Date(date).toISOString().split('T')[0];
        if(type === 'start') {
            setDateRangeStart(d);
        } else {
            setDateRangeEnd(d);
        }
    }

    async function downloadFile({ data, fileName, fileType }) {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
        a.dispatchEvent(clickEvt)
        a.remove()
        return "";
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    async function exportToCsv() {
        setExportToCsvButtonText('Saving...');

        var usersCsv = [];
        var row = "";
        var headers = [];
        if(selectedReportType === 'ip') {
            // Headers for each column
            headers = ['date, ip, # of repeat, fraud score, vpn, tor, bot']
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { day, ip, ip_repeat_ratio, fraud_score, vpn, tor, bot } = user
                acc.push(['"' + day + '"', ip, ip_repeat_ratio, fraud_score, vpn, tor, bot].join(','))
                return acc
            }, [])
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'ip_data.csv', fileType: 'text/csv' })
        }

        if(selectedReportType === 'day') {
            // Headers for each column
            headers = ['date, ip, # of repeat, fraud score, vpn, tor, bot']
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { day, ip, ip_repeat_ratio, fraud_score, vpn, tor, bot } = user
                acc.push(['"' + day + '"', ip, ip_repeat_ratio, fraud_score, vpn, tor, bot].join(','))
                return acc
            }, [])
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'ip_data.csv', fileType: 'text/csv' })
        }

        if(selectedReportType === 'country') {
            // Headers for each column
            headers = ['country, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr']
            // Convert users data to a csv
            usersCsv = tableData.reduce((acc, user) => {
                const { country, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr } = user
                acc.push([country, Math.round(revenue*100)/100, Math.round(pub_rev*100)/100, impressions, searches, clicks, Math.round(rpm*100)/100, Math.round(cpc*100)/100, Math.round(ctr*100)/100].join(','))
                return acc
            }, [])
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report_country.csv', fileType: 'text/csv'})
            await delay(3000);

            // Headers for each column
            headers = ['country, advertiser_name, revenue, pub_rev, impressions, searches, clicks, rpm, cpc, ctr']
            // Convert users data to a csv
            usersCsv = [];
            for(const table of tableData) {
                row = "";
                var country = table.country;
                for(const f of table.feeds) {
                    row = country + ',' + f.feed_name + ',' + Math.round(f.revenue*100)/100 + ',' + Math.round(f.pub_rev*100)/100 + ',' + f.impressions + ',' + f.searches + ',' + f.clicks + ',' + Math.round(f.rpm*100)/100 + ',' + Math.round(f.cpc*100)/100 + ',' + Math.round(f.ctr*100)/100;
                    usersCsv.push(row);
                }
            }
            await downloadFile({data: [...headers, ...usersCsv].join('\n'), fileName: 'advertiser_report_country_details.csv', fileType: 'text/csv'})
        }

        setExportToCsvButtonText('Save data');
    }

    function changeDailySampleSize(event) {
        setDailySampleSize(parseInt(event.target.value));
    }

    function handleSelectReportType(event) {
        setAllResponseData([]);
        setTableData([]);
        setTableDataTotal([]);
        setChartLabels([]);
        setChartData([]);
        setChartDataset([]);
        setSelectedReportType(event);
    }

    function expandTable() {
        if(showTable === false) {
            setShowTable(true);
            setExpandCollapseTable('Collapse Table');
        } else {
            setShowTable(false);
            setExpandCollapseTable('IPQS Table');
        }
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">IPQS Query</h2>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Adv Feeds</label>
                                    <Select
                                        options={advFeedOptionList}
                                        placeholder="All Adv Feeds"
                                        value={selectedAdvFeedOptions}
                                        onChange={(event) => handleSelectAdvFeed(event)}
                                        isSearchable={true}
                                        isMulti={false}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Publishers</label>
                                    <Select
                                        options={pubOptionList}
                                        placeholder="All Publishers"
                                        value={selectedPubOptions}
                                        onChange={(event) => {handleSelectPub(event)}}
                                        isSearchable={true}
                                        isMulti={false}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Campaign</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={{ ClearIndicator }}
                                        styles={{ clearIndicator: background.value === 'dark' ? ClearIndicatorStylesDark : ClearIndicatorStyles}}
                                        className="react-select-container"
                                        classNamePrefix={background.value === 'dark' ? "react-select-dark" : "react-select"}
                                        placeholder="Select Campaign"
                                        defaultValue={''}
                                        onChange={(event) => {selectCampaign(event)}}
                                        isMulti={false}
                                        options={campaignOptionList}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#6418c3',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Date Range</label>
                                    <select value={selectedDateRangeOptions} onChange={(event) => handleSelectDateRange(event.target.value)} className="form-control">
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="last_3days">Last 3 days</option>
                                        <option value="date_range">Custom Range</option>
                                    </select>
                                </div>
                            </div>

                            {selectedDateRangeOptions === "date_range" && (
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date Start</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'start')} value={dateRangeStart} className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Custom Range: Date End</label>
                                        <DatePicker onChange={(date) => changeDateRange(date, 'end')} value={dateRangeEnd} className="form-control"/>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Daily Sample Size</label>
                                    <input type="number" className="form-control" id="val-daily-sample-size" value={dailySampleSize} onWheel={(e) => e.target.blur()} onChange={(event) => changeDailySampleSize(event)} placeholder="Daily Sample Size"/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Report Type</label>
                                    <select value={selectedReportType}  onChange={(event) => handleSelectReportType(event.target.value)} className="form-control">
                                        <option value="day">By Day</option>
                                        <option value="ip">By IPs</option>
                                    </select>
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => getReport()}>
                                Execute Query
                            </Button>

                        </div>
                    </div>
                </div>
            </div>


            {loadingBadge === true && (
                <Badge bg="info">Loading</Badge>
            )}

            {noDataBadge === true && (
                <Badge bg="primary">No Data Available</Badge>
            )}

            {summaryData && 
                <div className="card pt-3 pb-3">
                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                Publisher Name
                                <h4>{summaryData.publisher_name}</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                Campaign Name
                                <h4>{summaryData.campaign_name}</h4>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 xs-pt-20">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                Date Range
                                <h4>{summaryData.days}</h4>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 xs-pt-20">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                Sample Size
                                <h4>{summaryData.daily_sample_size}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {summaryData && 
                <div className="card pt-3 pb-3">
                    <div className="row">
                        <div className="col-6 col-md">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                IP Repeat Ratio
                                <h4>{summaryData.ip_repeat_ratio}</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                Fraud Score
                                <h4>{summaryData.fraud_score}</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md xs-pt-20">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                VPN Rate
                                <h4>{summaryData.vpn}%</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md xs-pt-20">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                TOR Rate
                                <h4>{summaryData.tor}%</h4>
                            </div>
                        </div>
                        <div className="col-12 col-md xs-pt-20">
                            <div className="xs-text-center sm-text-center" style={{display: 'table', margin: '0px auto'}}>
                                Bot Rate
                                <h4>{summaryData.bot}%</h4>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {(chartData.length > 0 || chartDataset.length > 0) && (
                <div className="">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body pb-0">
                                <div className="form-group mb-3 row">
                                    <label className="col-6 col-sm-3 col-md-3 col-lg-2 col-form-label pt-2" htmlFor="val-api">Display By</label>
                                    <div className="col-6 col-sm-9 col-md-9 col-lg-10">
                                        <select value={selectedParameter}  onChange={(event) => handleSelectParameter(event.target.value)} className="form-control">
                                            <option value="ip_repeats">IP Repeats</option>
                                            <option value="fraud_score">Fraud Score</option>
                                            <option value="vpn_tor_bot">VPN / TOR / BOT Ratio</option>
                                            <option value="vpn">VPN</option>
                                            <option value="tor">TOR</option>
                                            <option value="bot">BOT</option>
                                        </select>
                                    </div>
                                </div>

                                {selectedReportType === 'day' && selectedParameter !== 'vpn_tor_bot' && (
                                    <Bar data={chartDataMain} options={chartOptionsMain} />
                                )}
                                {selectedReportType === 'day' && selectedParameter === 'vpn_tor_bot' && (
                                    <Bar data={chartDataDay} options={chartOptionsDayVpnTorBot} />
                                )}
                                
                                {selectedReportType === 'ip' && selectedParameter !== 'vpn_tor_bot' && (
                                    <Bar data={chartDataMain} height={chartHeight} options={chartOptionsMain} plugins={[ChartDataLabels]} />
                                )}
                                {selectedReportType === 'ip' && selectedParameter === 'vpn_tor_bot' && (
                                    <Bar data={chartDataDay} height={chartHeight} options={chartOptionsDayVpnTorBot} />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            )}

            {selectedReportType === 'advertiser' && tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Name</strong> <span style={{cursor: 'pointer'}} onClick={() => openAllFeed()}>({expandCollapseText})</span></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('revenue')}>Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('pub_rev')}>Pub Revenue</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('impressions')}>Impressions</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('searches')}>Searches</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('clicks')}>Clicks</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('rpm')}>RPM</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('cpc')}>CPC</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('ctr')}>CTR</strong></th>
                                        </tr>
                                    </thead>
                                    {tableDataTotal &&
                                        tableDataTotal.map((item, key) => (
                                            <tbody key={key+1100}>
                                                <tr id={item.advertiser_id}>
                                                    <td><strong>{item.name}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.revenue)}</strong></td>
                                                    <td><strong>${Intl.NumberFormat("en").format(item.pub_rev)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.impressions)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.searches)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.clicks)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.rpm)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.cpc)}</strong></td>
                                                    <td><strong>{Intl.NumberFormat("en").format(item.ctr)}</strong></td>
                                                </tr>
                                            </tbody>
                                        )
                                    )}

                                    { (tableData && showTable === true) &&
                                        tableData.map((item, key) => (
                                            <tbody key={key+1200}>
                                                <tr id={item.advertiser_id}>
                                                    <td>
                                                        <i className="fa-solid fa-circle-plus" name={item.advertiser_name} onClick={() => openFeed(item.advertiser_id, item.feed_name)}></i> {item.advertiser}
                                                    </td>
                                                    <td>${Intl.NumberFormat("en").format(item.revenue)}</td>
                                                    <td>${Intl.NumberFormat("en").format(item.pub_rev)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.impressions)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.searches)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.clicks)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.rpm)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.cpc)}</td>
                                                    <td>{Intl.NumberFormat("en").format(item.ctr)} %</td>
                                                </tr>

                                                {item.feeds.map((feed, key) => {
                                                    return (
                                                    <tr name={item.feed_name} key={key+1300} style={{display:'none'}}>
                                                        <td style={{paddingLeft: '30px'}}>
                                                            {feed.feed_name}
                                                        </td>
                                                        <td>${Intl.NumberFormat("en").format(feed.revenue)}</td>
                                                        <td>${Intl.NumberFormat("en").format(feed.pub_rev)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.impressions)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.searches)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.clicks)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.rpm)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.cpc)}</td>
                                                        <td>{Intl.NumberFormat("en").format(feed.ctr)} %</td>
                                                    </tr>
                                                    );
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </Table>

                                <div style={{display: 'table', margin: '0px auto'}}>
                                    <Button className="me-2" variant="success" onClick={() => expandTable()}>
                                        {expandCollapseTable}
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}

            {tableData.length > 0 && (
                <div style={{display: 'table', margin: '20px auto'}}>
                    <Button className="me-2" variant="success" onClick={() => expandTable()}>
                        {expandCollapseTable}
                    </Button>
                </div>
            )}

            {tableData.length > 0 && showTable === true && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body mt-0 mb-0 pt-4 pb-0">
                                <div className="col-12">
                                    <div style={{display:'inline-block', float: 'right'}}>
                                        <Button className="me-2" variant="info" onClick={(e) => exportToCsv(e)}>
                                            {exportToCsvButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong>Timestamp</strong></th>
                                            <th><strong>IP Address</strong></th>
                                            <th><strong># of Repeat</strong></th>
                                            <th><strong>Fraud Score</strong></th>
                                            <th><strong>VPN</strong></th>
                                            <th><strong>TOR</strong></th>
                                            <th><strong>BOT</strong></th>
                                        </tr>
                                    </thead>
                                        { tableData &&
                                            tableData.map((item, key) => (
                                                <tbody key={key+1400}>
                                                    <tr>
                                                        <td>{item.day_timestamp}</td>
                                                        <td>{item.ip}</td>
                                                        <td>{item.ip_repeat_ratio}</td>
                                                        <td>{item.fraud_score}</td>
                                                        <td>{item.vpn}</td>
                                                        <td>{item.tor}</td>
                                                        <td>{item.bot}</td>
                                                    </tr>
                                                </tbody>
                                            )
                                        )}

                                        {tableDataTotal &&
                                            tableDataTotal.map((item, key) => (
                                                <tbody key={key+1500}>
                                                    <tr>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong></strong></td>
                                                        <td><strong>{item.ip_repeat_ratio}</strong></td>
                                                        <td><strong>{item.fraud_score}</strong></td>
                                                        <td><strong>{item.vpn}</strong></td>
                                                        <td><strong>{item.tor}</strong></td>
                                                        <td><strong>{item.bot}</strong></td>
                                                    </tr>
                                                </tbody>
                                            )
                                        )}
                                </Table>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default IPQS;