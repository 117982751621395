import React, { Fragment, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination, Modal } from "react-bootstrap";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { SketchPicker } from 'react-color';
import PermissionGate from '../PermissionGate';

const Channel = () => {
    const [basicModal, setBasicModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [active, setActive] = useState(1);
    const [maxPage, setMaxPage] = useState(5);
    const [pageItems, setPageItems] = useState([{p:1},{p:2}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);

    const [sortBy, setSortBy] = useState('ASC');
    const [orderBy, setOrderBy] = useState('id');

    const [search, setSearch] = useState('');
    const [editChannel, setEditChannel] = useState({});
    const [color, setColor] = useState({});

    useEffect(() => {
        getAdvertisers(1);
        // eslint-disable-next-line
    }, []);

    function handleChangeColor(color) {
        var edit_channel = editChannel;
        edit_channel.color = color.hex;

        setEditChannel(edit_channel);
        setColor(color.hex);
    };

    async function changeSearch(data) {
        setSearch(data.target.value);

        // setActive(1);
        // changePage(1);
        // await delay(1000);
        // setActive(1);
        // changePage(1);
    }

    function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        // var max_page = maxPage;
        var max_page = 5;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
        getAdvertisers(page_number);
    }

    function changePageNoReload(page, max_page) {
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        var max_page_new = 5;
        if(page_number >= 5) {
            number_first = page_number - 5;
            // max_page = max_page + number_first;
            max_page_new = 10 + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page_new; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getAdvertisers(page_number, sort_by, order_by) {
        var sort_by_value = sortBy;
        if(sort_by !== undefined) {
            sort_by_value = sort_by;
        }

        var order_by_value = orderBy;
        if(order_by !== undefined) {
            order_by_value = order_by;
        }

        const data = {
            search: search,
            page: page_number,
            sort_by: sort_by_value,
            order_by: order_by_value,
        }
        const response = await axios.post(baseURL+'/api/channels_get', data)
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);

        await delay(2000);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        
        setTableData(response.data.data);
        setLoadingBadge(false);
        changePageNoReload(page_number, response.data.max_page);
        return "";
    }

    async function saveChannelColor() {
        const data = {
            channel: editChannel
        }
        const response = await axios.post(baseURL+'/api/channels_save_color', data)
        if(response.data.status === 'ok') {
            setBasicModal(false);
        }
    }

    async function sortTable(value) {
        if(sortBy === 'ASC') {
            //Sort By Desc
            setSortBy('DESC')
            setOrderBy(value)
            setTableData([]);
            getAdvertisers(1, 'DESC', value);
        } else {
            //Sort By Asc
            setSortBy('ASC')
            setOrderBy(value)
            setTableData([]);
            getAdvertisers(1, 'ASC', value);
        }
    }

    async function selectColor(color) {
        if(color == null) {
            setColor('#000');
        } else {
            setColor(color);
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">Channel Management</h2>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Search channel</label>
                                    <input type="text" className="form-control" id="val-name" value={search} onChange={(event) => changeSearch(event)} placeholder="Search channel, advertiser or feed"/>
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => {setSortBy('ASC'); setOrderBy('id'); getAdvertisers(1, 'ASC', 'id')} }>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('channel')} >Channel</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('advertiser')} >Advertiser Name</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('feed_name')} >Feed Name</strong></th>
                                            <th><strong>Color</strong></th>
                                            <th><strong>Action</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                <tr key={item.id+key}>
                                                    <td>{item.channel}</td>
                                                    <td>{item.advertiser}</td>
                                                    <td>{item.feed_name}</td>
                                                    <td>
                                                        <div style={{width: '40px', height: '20px', background: item.color}}></div>
                                                    </td>
                                                    <td>
                                                        <Button className="me-2" variant="info" size="sm" onClick={() => {setEditChannel(item); selectColor(item.color); setBasicModal(true)} }>
                                                            Edit
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}

                                <Pagination
                                    size={"sm"}
                                    className={`mt-4  ${true ? "pagination-gutter" : ""} ${
                                        "info" && `pagination-${"info"}`
                                    } ${!false && "no-bg"} ${false && "pagination-circle"}`}
                                >
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    {pageItems.length > 0 &&
                                        pageItems.map((item, key) => (
                                            <li key={key} className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}

                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>

                                <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                    <Modal.Header>
                                        <h4 className="text-black mb-2">&nbsp;</h4>
                                        <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h4 className="text-black">{editChannel.channel}</h4>

                                        <div className="form-group mb-3 row">
                                            <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                            <div className="col-lg-6">
                                                <SketchPicker color={color} onChangeComplete={(color) => handleChangeColor(color) } />
                                            </div>
                                        </div>

                                        <Button variant="success" className="" onClick={() => saveChannelColor()}>
                                            Save
                                        </Button>
                                    </Modal.Body>
                                </Modal>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default Channel;