import React,{Fragment, useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert } from "react-bootstrap";
import {reducer} from '../bootstrap/alertReducer';
import DatePicker  from "react-datepicker";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import { SketchPicker } from 'react-color';
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const FeedNew = () => {
    let navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initial);
    const [activeStatus, setActiveStatus] = useState('yes');
    const [name, setName] = useState('');
    const [api, setAPI] = useState('');
    const [category, setCategory] = useState('BHS');
    const [feedType, setFeedType] = useState('Desktop');
    const [advertiserId, setAdvertiserId] = useState('');
    const [advertiserIdText, setAdvertiserIdText] = useState('');
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [advertiserList, setAdvertiserList] = useState([]);
    const [advertiserListStatus, setAdvertiserListStatus] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [color, setColor] = useState('#ffffff');
    const [channelName, setChannelName] = useState('');
    const [channelList, setChannelList] = useState([]);

    useEffect(() => {
        getColor();
    }, []);

    function chooseChannel(name) {
        var selected_list = channelList;
        selected_list.push({name:name});
        setChannelList(selected_list);

        setChannelName('');
    }

    function removeChannel(name) {
        var selected_list = [];
        for(const channel_name of channelList) {
            if(channel_name.name !== name) {
                selected_list.push(channel_name);
            }
        }
        setChannelList(selected_list);
    }

    function handleChangeColor(color) {
        setColor(color.hex);
    };

    function handleSelectActive(value) {
        setActiveStatus(value);
    }

    function handleSelectCategory(value) {
        setCategory(value);
    }
    
    function handleSelectFeedType(value) {
        setFeedType(value);
    }

    function changeName(event) {
        setName(event.target.value);
    }

    function changeAPI(event) {
        setAPI(event.target.value);
    }

    function changeEntryDate(date) {
        const d = new Date(date).toISOString().split('T')[0];
        setEntryDate(d);
    }

    function chooseAdvertiserId(id, name) {
        setAdvertiserId(id);
        const text = id+' '+name;
        setAdvertiserIdText(text);

        setAdvertiserList([]);
        setAdvertiserListStatus('');
    }

    async function changeAdvertiserID(event) {
        setAdvertiserIdText(event.target.value);
        setAdvertiserId(event.target.value);

        if(event.target.value !== "") {
            const data = { search: event.target.value }
            const response = await axios.post(baseURL+'/api/advertiser_search', data)
            setAdvertiserList(response.data.data);
            setAdvertiserListStatus(response.data.status);
        } else {
            setAdvertiserList([]);
            setAdvertiserListStatus('');
        }
    }

    async function saveFeed() {
        const data = {
            active: activeStatus,
            name: name,
            api: api,
            category: category,
            feed_type: feedType,
            advertiser_id: advertiserId,
            entry_date: entryDate,
            color: color,
            channel_list: channelList
        }
        const response = await axios.post(baseURL+'/api/feed_save', data)

        setAlertStatus(response.data.status);
        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
        if(response.data.status === 'insert') {
            navigate('/feed-management?set_saved_options=yes');
        }
    }

    async function getColor() {
        const data = {
            type: 'feed'
        }
        const response = await axios.post(baseURL+'/api/get_color', data)
        setColor(response.data.data.color);
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">New Feed</h2>
                <Link to={"/feed-management?set_saved_options=yes"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-skill">Active</label>
                                        <div className="col-lg-6">
                                            <select value={activeStatus} onChange={(event) => handleSelectActive(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-username">Advertiser ID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-username" value={advertiserIdText} onChange={(event) => changeAdvertiserID(event)} placeholder="Advertiser ID"/>
                                                {advertiserList &&
                                                    advertiserList.map((item, key) => (
                                                        <li className='pt-1 pb-1' style={{cursor:'pointer'}} onClick={() => chooseAdvertiserId(item.id, item.name)}>Advertiser ID: {item.id}, Name: {item.name}</li>
                                                    )
                                                )}

                                                {advertiserListStatus === 'no' && (
                                                    <li className='pt-1 pb-1'>There is no advertiser with this ID or name.</li>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Feed Name</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-name" value={name} onChange={(event) => changeName(event)} placeholder="Feed Name"/>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-api">Feed API</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-api" value={api} onChange={(event) => changeAPI(event)} placeholder="Feed API"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Channel IDs</label>
                                        
                                        <div className="col-lg-6">
                                            {channelList &&
                                                channelList.map((item, key) => (
                                                    <div className="float-left mt-1 mb-1" style={{display: 'contents'}}>
                                                        <Button className="me-2 btn-xs mt-1 mb-1" variant="info">
                                                            {item.name}{" "} <span className="btn-icon-right" onClick={() => removeChannel(item.name)}><i className="fa fa-close" /></span>
                                                        </Button>
                                                    </div>
                                                )
                                            )}

                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" value={channelName} onChange={(e) => setChannelName(e.target.value)} placeholder="Channel ID" />
                                                <button className="btn btn-primary" type="info" onClick={() => chooseChannel(channelName)} >Add</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-category">Feed Category</label>
                                        <div className="col-lg-6">
                                            <select value={category} onChange={(event) => handleSelectCategory(event.target.value)} className="form-control" id="feed-category">
                                                <option value="BHS">BHS</option>
                                                <option value="YHS">YHS</option>
                                                <option value="D2S">D2S</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-type">Feed Type</label>
                                        <div className="col-lg-6">
                                            <select value={feedType} onChange={(event) => handleSelectFeedType(event.target.value)} className="form-control" id="feed-type">
                                                <option value="Desktop">Desktop</option>
                                                <option value="Mobile">Mobile</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Entry Date</label>
                                        <div className="col-lg-6">
                                            <DatePicker onChange={(date) => changeEntryDate(date)} value={entryDate} className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="feed-entry-date">Color</label>
                                        <div className="col-lg-6">
                                            <SketchPicker color={ color } onChangeComplete={(color) => handleChangeColor(color) } />
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveFeed()}>
                                Save
                            </Button>

                            <Link to="/feed-management?set_saved_options=yes" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'insert' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The New Feed has been added.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default FeedNew;