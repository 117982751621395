import React,{Fragment, useState, useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import { Button, Alert, Modal } from "react-bootstrap";
import {reducer} from '../bootstrap/alertReducer';
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import PermissionGate from '../PermissionGate';

const emojis = {
    success: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polyline points='9 11 12 14 22 4'></polyline>
            <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
        </svg>
    ),

    error: (
        <svg viewBox='0 0 24 24' width='24' height='24' stroke='currentColor' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='me-2'>
            <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
            <line x1='15' y1='9' x2='9' y2='15'></line>
            <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
    )
}
const initial = true;

const FeedNew = () => {
    let navigate = useNavigate();
    const [basicModal, setBasicModal] = useState(false);
    useEffect(() => {
        getUser();
        getDataHistory();
        // eslint-disable-next-line
    }, []);

    const [state, dispatch] = useReducer(reducer, initial);
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [alertText, setAlertText] = useState('');
    const [alertPermissionStatus, setAlertPermissionStatus] = useState('');

    //Permissions - START//
    const [advertiserReport, setAdvertiserReport] = useState(false);
    const [dailyStatReport, setDailyStatReport] = useState(false);
    const [feedDetailsReport, setFeedDetailsReport] = useState(false);
    const [channelDetailsReport, setChannelDetailsReport] = useState(false);
    const [publisherReport, setPublisherReport] = useState(false);
    const [incomeStatement, setIncomeStatement] = useState(false);

    const [advertiserManagement, setAdvertiserManagement] = useState(false);
    const [feedManagement, setFeedManagement] = useState(false);
    const [channelManagement, setChannelManagement] = useState(false);
    const [publisherManagement, setPublisherManagement] = useState(false);
    const [campaignManagement, setCampaignManagement] = useState(false);

    const [ipqsLog, setIpqsLog] = useState(false);
    const [ipqsCampaigns, setIpqsCampaigns] = useState(false);
    const [ipqsQuery, setIpqsQuery] = useState(false);

    const [reportUpdateInterval, setReportUpdateInterval] = useState(false);
    const [reportUpdateLog, setReportUpdateLog] = useState(false);

    const [userManagement, setUserManagement] = useState(false);
    //Permissions - END//
    
    async function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        var utcDate = new Date(date.toUTCString());
        utcDate.setHours(utcDate.getHours());
        var usDate = new Date(utcDate);
        const date_return = usDate.getHours() + ':' + usDate.getMinutes() + ' ' + (usDate.getMonth()+1) + '/' + usDate.getDate() + '/' + usDate.getFullYear();
        return date_return;
    }

    async function getDataHistory() {
        const data = { feed_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/feeds_get_history', data)

        var result = [];
        for(const d of response.data.data) {
            var date = "";
            if(d.date_timestamp !== null) {
                date = await convertTimestampToDate(d.date_timestamp);
            }
            
            result.push({
                id: d.id,
                date: date,
                name: d.name,
                active: d.active,
                advertiser_id: d.advertiser_id,
                api: d.api,
                category: d.category,
                type: d.type,
                entry_date: d.entry_date,
            });
        }
    }

    async function savePermissions() {
        const data = {
            user_id: searchParams.get("id"),
            permissions: {
                advertiser_report: advertiserReport,
                daily_stat_report: dailyStatReport,
                feed_details_report: feedDetailsReport,
                channel_details_report: channelDetailsReport,
                publisher_report: publisherReport,
                income_statement: incomeStatement,
                advertiser_management: advertiserManagement,
                feed_management: feedManagement,
                channel_management: channelManagement,
                publisher_management: publisherManagement,
                campaign_management: campaignManagement,
                ipqs_log: ipqsLog,
                ipqs_campaigns: ipqsCampaigns,
                ipqs_query: ipqsQuery,
                report_update_interval: reportUpdateInterval,
                report_update_log: reportUpdateLog,
                user_management: userManagement
            }
        }
        const response = await axios.post(baseURL+'/api/user_management/save_permissions', data)
        setAlertPermissionStatus(response.data.status);
    }

    async function saveUser() {
        const data = {
            user_id: searchParams.get("id"),
            email: email,
            password: password
        }
        const response = await axios.post(baseURL+'/api/user_management/save_user_settings', data)

        setAlertStatus(response.data.status);
        if(response.data.status === 'error') {
            setAlertText(response.data.text);
        }
    }

    async function getUser() {
        const data = { user_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/user_management/get_user', data)

        if(response.data.status === 'ok') {
            setEmail(response.data.data[0].email);

            setAdvertiserReport(response.data.permissions.advertiser_report);
            setDailyStatReport(response.data.permissions.daily_stat_report);
            setFeedDetailsReport(response.data.permissions.feed_details_report);
            setChannelDetailsReport(response.data.permissions.channel_details_report);
            setPublisherReport(response.data.permissions.publisher_report);
            setIncomeStatement(response.data.permissions.income_statement);
            setAdvertiserManagement(response.data.permissions.advertiser_management);
            setFeedManagement(response.data.permissions.feed_management);
            setChannelManagement(response.data.permissions.channel_management);
            setPublisherManagement(response.data.permissions.publisher_management);
            setCampaignManagement(response.data.permissions.campaign_management);
            setIpqsLog(response.data.permissions.ipqs_log);
            setIpqsCampaigns(response.data.permissions.ipqs_campaigns);
            setIpqsQuery(response.data.permissions.ipqs_query);
            setReportUpdateInterval(response.data.permissions.report_update_interval);
            setReportUpdateLog(response.data.permissions.report_update_log);
            setUserManagement(response.data.permissions.user_management);

        }
    }

    async function deleteUser() {
        const data = { user_id: searchParams.get("id") }
        const response = await axios.post(baseURL+'/api/user_management/delete_user', data)
        if(response.data.status === 'ok') {
            navigate("/users");
        }
    }
    
    async function selectAllPermissions() {
        setAdvertiserReport(true);
        setDailyStatReport(true);
        setFeedDetailsReport(true);
        setChannelDetailsReport(true);
        setPublisherReport(true);
        setIncomeStatement(true);
        setAdvertiserManagement(true);
        setFeedManagement(true);
        setChannelManagement(true);
        setPublisherManagement(true);
        setCampaignManagement(true);
        setIpqsLog(true);
        setIpqsCampaigns(true);
        setIpqsQuery(true);
        setReportUpdateInterval(true);
        setReportUpdateLog(true);
        setUserManagement(true);
    }

    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">User Edit</h2>
                <Link to={"/users"} className="btn btn-secondary text-white mb-2"><i className="la la-angle-left scale5 me-3"></i>Back</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <form className="form-valide" action="#" method="post" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-id">User ID</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-id" value={searchParams.get("id")} placeholder="User ID" readOnly />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-email">Email</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Email"/>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="val-password">Password</label>
                                        <div className="col-lg-6">
                                            <input type="text" className="form-control" id="val-password" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Password"/>
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <Button className="me-2" variant="info" onClick={() => saveUser()}>
                                Save
                            </Button>

                            <Link to="/users" id="cancel_link">
                                <Button className="me-2" variant="warning">
                                    Cancel
                                </Button>
                            </Link>

                            <Button variant="danger" className="" onClick={() => setBasicModal(true)}>
                                Delete
                            </Button>

                            <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                <Modal.Header>
                                    <h4 className="text-black mb-2">&nbsp;</h4>
                                    <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4 className="text-black">Are you sure to delete this user?</h4>
                                    <Button variant="danger" className="" onClick={() => deleteUser()}>
                                        Delete
                                    </Button>
                                </Modal.Body>
                            </Modal>

                            {alertStatus === 'error' && (
                                <Alert  variant="danger" dismissible show={state.altdanger} className='alert-alt mt-3'>
                                    {emojis.error}
                                    <strong>Error! </strong> {alertText}
                                    <button className="btn-close" onClick={()=>dispatch({type:'altdanger'})}></button>
                                </Alert>
                            )}

                            {alertStatus === 'update' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The data has been updated successfully.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header border-0 flex-wrap pb-0 select-btn">
                            <div className="">
                                <h4 className="fs-20 text-black">Permissions</h4>
                                <span className="text-info" style={{cursor: 'pointer'}} onClick={() => selectAllPermissions()}>Select All Permissions</span>
                            </div>
                        </div>
                        <div className="card-body pt-1">

                            <div className="row">
                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>Reports</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="advertiser_report" checked={advertiserReport} onChange={(event) => setAdvertiserReport(!advertiserReport) } />
                                        <label className="form-check-label" htmlFor="advertiser_report">Advertiser Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="daily_stat_report" checked={dailyStatReport} onChange={(event) => setDailyStatReport(!dailyStatReport) } />
                                        <label className="form-check-label" htmlFor="daily_stat_report">Daily Stat Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="feed_retails_report" checked={feedDetailsReport} onChange={(event) => setFeedDetailsReport(!feedDetailsReport) } />
                                        <label className="form-check-label" htmlFor="feed_retails_report">Feed Details Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="channel_details_report" checked={channelDetailsReport} onChange={(event) => setChannelDetailsReport(!channelDetailsReport) } />
                                        <label className="form-check-label" htmlFor="channel_details_report">Channel Details Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="publisher_report" checked={publisherReport} onChange={(event) => setPublisherReport(!publisherReport) } />
                                        <label className="form-check-label" htmlFor="publisher_report">Publisher Report</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="income_statement" checked={incomeStatement} onChange={(event) => setIncomeStatement(!incomeStatement) } />
                                        <label className="form-check-label" htmlFor="income_statement">Income Statement</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>Advertisers & Feeds</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="advertiser_management" checked={advertiserManagement} onChange={(event) => setAdvertiserManagement(!advertiserManagement) } />
                                        <label className="form-check-label" htmlFor="advertiser_management">Advertiser Management</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="feed_management" checked={feedManagement} onChange={(event) => setFeedManagement(!feedManagement) } />
                                        <label className="form-check-label" htmlFor="feed_management">Feed Management</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="channel_management" checked={channelManagement} onChange={(event) => setChannelManagement(!channelManagement) } />
                                        <label className="form-check-label" htmlFor="channel_management">Channel Management</label>
                                    </div>

                                    <p className="mb-2"><b>Publishers & Campaigns</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="publisher_management" checked={publisherManagement} onChange={(event) => setPublisherManagement(!publisherManagement) } />
                                        <label className="form-check-label" htmlFor="publisher_management">Publisher Management</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="campaign_management" checked={campaignManagement} onChange={(event) => setCampaignManagement(!campaignManagement) } />
                                        <label className="form-check-label" htmlFor="campaign_management">Campaign Management</label>
                                    </div>

                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>IPQS Management</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="ipqs_log" checked={ipqsLog} onChange={(event) => setIpqsLog(!ipqsLog) } />
                                        <label className="form-check-label" htmlFor="ipqs_log">IPQS Log</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="ipqs_campaigns" checked={ipqsCampaigns} onChange={(event) => setIpqsCampaigns(!ipqsCampaigns) } />
                                        <label className="form-check-label" htmlFor="ipqs_campaigns">IPQS Campaigns</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="ipqs_query" checked={ipqsQuery} onChange={(event) => setIpqsQuery(!ipqsQuery) } />
                                        <label className="form-check-label" htmlFor="ipqs_query">IPQS Query</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>Schedule</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="report_update_interval" checked={reportUpdateInterval} onChange={(event) => setReportUpdateInterval(!reportUpdateInterval) } />
                                        <label className="form-check-label" htmlFor="report_update_interval">Report Update Interval</label>
                                    </div>

                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="report_update_log" checked={reportUpdateLog} onChange={(event) => setReportUpdateLog(!reportUpdateLog) } />
                                        <label className="form-check-label" htmlFor="report_update_log">Report Update Log</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 pt-1 pb-1">
                                    <p className="mb-2"><b>User Management</b></p>
                                    <div className="form-check custom-checkbox mb-1">
                                        <input type="checkbox" className="form-check-input" id="report_update_interval" checked={userManagement} onChange={(event) => setUserManagement(!userManagement) } />
                                        <label className="form-check-label" htmlFor="report_update_interval">User Management</label>
                                    </div>
                                </div>

                            </div>

                            <Button className="me-2" variant="info" onClick={() => savePermissions()}>
                                Save
                            </Button>

                            {alertPermissionStatus === 'ok' && (
                                <Alert variant="success" dismissible show={state.altsuccess} className='alert-alt mt-3'>
                                    {emojis.success}
                                    <strong>Success! </strong> <span>The permissions have been saved successfully.</span>
                                    <button className="btn-close" onClick={()=>dispatch({type:'altsuccess'})}></button>
                                </Alert>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )

}

export default FeedNew;