import React,{Fragment,useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button, Table, Badge, Pagination, Modal } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import Select from "react-select";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import PermissionGate from '../PermissionGate';

const IPQS = () => {
    const { mobileTrColor } = useContext(ThemeContext);
    const [basicModal, setBasicModal] = useState(false);

    useEffect(() => {
        getCampaignOptionList();
        getAdvFeedOptionList();
        getFeeds(1);
        // eslint-disable-next-line
    }, []);

    const [advFeedOptionList, setAdvFeedOptionList] = useState([]);
    const [campaignOptionList, setCampaignOptionList] = useState([]);
    const [campaignOptionListAll, setCampaignOptionListAll] = useState([]);

    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([]);
    const [selectedAdvFeedOptions, setSelectedAdvFeedOptions] = useState([]);

    const [tableData, setTableData] = useState([]);
    const [feedActiveStatus] = useState(1);

    const [active, setActive] = useState(1);
    const [maxPage, setMaxPage] = useState(5);
    const [pageItems, setPageItems] = useState([{p:1},{p:2}]);
    const [loadingBadge, setLoadingBadge] = useState(false);
    const [maxAvailablePage, setMaxAvailablePage] = useState(1);

    const [sortBy, setSortBy] = useState('ASC');
    const [orderBy, setOrderBy] = useState('id');

    const [deleteCampaignID, setDeleteCampaignID] = useState(0);

    
    function selectCampaign(data) {
        setSelectedCampaignOptions(data);
    }

    function handleSelectAdvFeed(data) {
        setSelectedAdvFeedOptions(data);

        if(data.length === 0) {
            setCampaignOptionList(campaignOptionListAll);
        } else {
            var new_campaigns = [];
            for(const campaign of campaignOptionListAll) {
                if(campaign.id === 0) {
                    new_campaigns.push(campaign);
                } else {
                    for(const d of data) {
                        if(d.id === campaign.feed_id) {
                            new_campaigns.push(campaign);
                        }
                    }
                }
            }
            setCampaignOptionList(new_campaigns);
        }
    }

    async function getCampaignOptionList() {
        const response = await axios.get(baseURL+'/api/get_campaign_list?include_all=no')
        setCampaignOptionListAll(response.data);
        setCampaignOptionList(response.data);
    }

    async function getAdvFeedOptionList() {
        const response = await axios.get(baseURL+'/api/get_adv_feed_list')
        setAdvFeedOptionList(response.data);
    }

    function setNavigationLinks(max_page) {
        var page_items_tmp = [];
        for (let number = active; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePage(page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        var max_page = maxPage;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
        getFeeds(page_number);
    }

    function changePageNoReload(page, max_page) {
        setLoadingBadge(true);
        var page_number = parseInt(page);
        setActive(page_number);

        var number_first = 1;
        if(page_number > 5) {
            number_first = page_number - 5;
            max_page = max_page + number_first;
        }

        var page_items_tmp = [];
        for (let number = number_first; number <= max_page; number++) {
            page_items_tmp.push({p:number});
        }
        setPageItems(page_items_tmp);
    }

    function changePagePrev() {
        var next_page = active - 1;
        if(next_page < 1) {
            next_page = 1;
        }
        setActive(next_page);
        changePage(next_page);
    }

    function changePageNext() {
        const next_page = active + 1;
        if(next_page <= maxAvailablePage) {
            setActive(next_page);
            changePage(next_page);
        }
    }

    async function getFeeds(page_number, sort_by, order_by) {
        var sort_by_value = sortBy;
        if(sort_by !== undefined) {
            sort_by_value = sort_by;
        }

        var order_by_value = orderBy;
        if(order_by !== undefined) {
            order_by_value = order_by;
        }

        const data = {
            adv_feeds: selectedAdvFeedOptions,
            campaigns: selectedCampaignOptions,
            publishers: [],
            feedActiveStatus: feedActiveStatus,
            page: page_number,
            sort_by: sort_by_value,
            order_by: order_by_value,
        }
        const response = await axios.post(baseURL+'/api/ipqs_campaigns_get', data)
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        
        var result = [];
        var date_added = "";
        for(const d of response.data.data) {
            if(d.timestamp_added != null) {
                date_added = await convertTimestampToDate(d.timestamp_added);
            } else {
                date_added = "";
            }
            
            result.push({
                id: d.id,
                active: d.active,
                campaign_id: d.campaign_id,
                campaign_name: d.campaign_name,
                campaign_active: d.campaign_active,
                daily_sample_size: d.daily_sample_size,
                entry_date: d.entry_date,
                color: d.color,
                timestamp_added: d.timestamp_added,
                date_added: date_added,
                adv_feed_name: d.adv_feed_name,
                publisher_name: d.publisher_name,
            });
        }
        setTableData(result);
        setNavigationLinks(response.data.max_page);

        changePageNoReload(page_number, response.data.max_page)
        setLoadingBadge(false);
    }

    async function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp * 1000);
        var utcDate = new Date(date.toUTCString());
        utcDate.setHours(utcDate.getHours());
        var usDate = new Date(utcDate);
        const date_return = usDate.getHours() + ':' + usDate.getMinutes() + ' ' + (usDate.getMonth()+1) + '/' + usDate.getDate() + '/' + usDate.getFullYear();
        return date_return;
    }

    async function deleteCampaignRequest() {
        const data = {
            campaign_id: deleteCampaignID
        }
        const response = await axios.post(baseURL+'/api/ipqs_campaign_delete', data)
        if(response.data.status === 'ok') {
            getFeeds(active);
            setBasicModal(false);
        }
    }

    async function sortTable(value) {
        if(sortBy === 'ASC') {
            setSortBy('DESC');
            setOrderBy(value);
            setTableData([]);
            getFeeds(1, 'DESC', value);
        } else {
            setSortBy('ASC');
            setOrderBy(value);
            setTableData([]);
            getFeeds(1, 'ASC', value);
        }
    }
    
    return(
        <Fragment>
            <PermissionGate/>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 me-auto ">IPQS Campaigns</h2>
                <Link to={"/ipqs-campaign-new"} className="btn btn-secondary text-white mb-2"><i className="la la-list scale5 me-3"></i>New IPQS Campaign</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Adv Feeds</label>
                                    <Select
                                        options={advFeedOptionList}
                                        placeholder="All Adv Feeds"
                                        value={selectedAdvFeedOptions}
                                        onChange={(event) => handleSelectAdvFeed(event)}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Select Campaigns</label>
                                    <Select
                                        options={campaignOptionList}
                                        placeholder="All Campaigns"
                                        value={selectedCampaignOptions}
                                        onChange={(event) => {selectCampaign(event)}}
                                        isSearchable={true}
                                        isMulti={true}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>

                            <Button className="me-2" variant="info" onClick={() => {setSortBy('ASC'); setOrderBy('id'); getFeeds(1, 'ASC', 'id')} }>
                                Search
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            {tableData.length > 0 && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Table responsive className="header-border respon-table-data" hover>
                                    <thead>
                                        <tr>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('active')} >Date</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('name')} >Campaign</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('id')} >Publisher</strong></th>
                                            <th><strong style={{cursor:'pointer'}} onClick={() => sortTable('refid')} >Adv Feed</strong></th>
                                            <th><strong>Sample Size</strong></th>
                                            <th><strong>Status</strong></th>
                                            <th><strong>Color</strong></th>
                                            <th><strong>Delete</strong></th>
                                            <th><strong>Edit</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.length > 0 &&
                                            tableData.map((item, key) => (
                                                
                                                <tr style={ item.type === 'Mobile' ? {background: mobileTrColor} : {} } key={key+1100} >
                                                    <td>{item.date_added}</td>
                                                    <td style={{whiteSpace: 'nowrap', overflow:'hidden'}}>
                                                        <div style={{display: 'flex', justifyContent: 'start'}}>
                                                            <p style={ item.campaign_active === 1 ? {background: 'green', width: '16px', height: '16px', borderRadius: '8px', marginTop: '3px', marginRight: '5px'} : {background: 'grey', width: '16px', height: '16px', borderRadius: '8px', marginTop: '3px', marginRight: '5px'}}></p>
                                                            <p>{item.campaign_name}</p>
                                                        </div>
                                                    </td>
                                                    <td>{item.publisher_name}</td>
                                                    <td>{item.adv_feed_name}</td>
                                                    <td>{item.daily_sample_size}</td>
                                                    <td>
                                                        {item.active === 1 && (
                                                            <span>Active</span>
                                                        )}
                                                        {item.active === 0 && (
                                                            <span><i className="text-danger fa-solid fa-circle-stop"></i> Inactive</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div style={{width: '40px', height: '20px', background: item.color}}></div>
                                                    </td>
                                                    <td>
                                                        <Button className="me-2" variant="danger" size="sm" onClick={() => {setDeleteCampaignID(item.id); setBasicModal(true);}}>
                                                            Delete
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Link to={`/ipqs-campaign-edit?id=${item.id}`}>
                                                            <Button className="me-2" variant="info" size="sm">
                                                                Edit
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>

                                <Modal className="fade" show={basicModal} onHide={setBasicModal}>
                                    <Modal.Header>
                                        <h4 className="text-black mb-2">&nbsp;</h4>
                                        <Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h4 className="text-black mb-3">Are you sure that you want to delete this IQPS Campaign?</h4>

                                        <Button variant="danger" className="" onClick={() => deleteCampaignRequest()}>
                                            Delete
                                        </Button>
                                    </Modal.Body>
                                </Modal>

                                {loadingBadge === true && (
                                    <Badge bg="info">Loading</Badge>
                                )}

                                <Pagination size={"sm"} className={`mt-4  ${true ? "pagination-gutter" : ""} ${ "info" && `pagination-${"info"}` } ${!false && "no-bg"} ${false && "pagination-circle"}`}>
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePagePrev()}}>
                                        <i className="la la-angle-left" />
                                        </Link>
                                    </li>

                                    {pageItems.length > 0 &&
                                        pageItems.map((item, key) => (
                                            <li key={key+1200} className={item.p === active ? "page-item page-indicator active" : "page-item page-indicator"}>
                                                <Link className="page-link" to="#" onClick={() => {changePage(item.p)}}>
                                                    {item.p}
                                                </Link>
                                            </li>
                                        )
                                    )}
                                    
                                    <li className="page-item page-indicator">
                                        <Link className="page-link" to="#" onClick={() => {changePageNext()}}>
                                        <i className="la la-angle-right" />
                                        </Link>
                                    </li>
                                </Pagination>


                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    
}

export default IPQS;