import React,{Fragment,useState, useEffect} from 'react';
// import {Link} from 'react-router-dom';
import { Button, Table, Badge, Modal } from "react-bootstrap";
// import { ThemeContext } from "../../../context/ThemeContext";
// import Select from "react-select";
import axios from 'axios';
import {baseURL} from '../../../baseURL';
import PermissionGate from '../PermissionGate';
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";
// import { Bar } from "react-chartjs-2";
// import 'chartjs-plugin-datalabels';

const ReportUpdateInterval = () => {
	// const { mobileTrColor } = useContext(ThemeContext);
	useEffect(() => {
		getList();
		// eslint-disable-next-line
	}, []);

	const [basicModal, setBasicModal] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [loadingBadge, setLoadingBadge] = useState(false);

	const [sortBy] = useState('ASC');
	const [orderBy] = useState('hour_original');

	const [newHour, setNewHour] = useState('');
	const [newMinute, setNewMinute] = useState('');
	const [timezone, setTimezone] = useState('-8');

	const [editHour, setEditHour] = useState('');
	const [editMinute, setEditMinute] = useState('');
	const [editTimezone, setEditTimezone] = useState('');
	const [editTimeID, setEditTimeID] = useState('');
	
	
	function handleSelectTimezone(data) {
		setTimezone(data);
	}

	function handleSelectEditTimezone(data) {
		setEditTimezone(data);
	}

	function changeHour(event) {
		var value = parseInt(event.target.value);
		if(value > 23) {
			value = 23;
		}
		if(value < 0) {
			value = 0;
		}
		setNewHour(value);
	}
	
	function changeMinute(event) {
		var value = parseInt(event.target.value);
		if(value > 59) {
			value = 59;
		}
		if(value < 0) {
			value = 0;
		}
		setNewMinute(value);
	}

	function changeEditHour(event) {
		var value = parseInt(event.target.value);
		if(value > 23) {
			value = 23;
		}
		if(value < 0) {
			value = 0;
		}
		setEditHour(value);
	}

	function changeEditMinute(event) {
		var value = parseInt(event.target.value);
		if(value > 59) {
			value = 59;
		}
		if(value < 0) {
			value = 0;
		}
		setEditMinute(value);
	}

	async function addNewSchedule() {
		const data = {
			hour: newHour,
			minute: newMinute,
			timezone: timezone,
		}
		const response = await axios.post(baseURL+'/api/schedule/add', data)
		if(response.data.status === 'ok') {
			getList();
		}
	}

	async function editTime() {
		const data = {
			id: editTimeID,
			hour: editHour,
			minute: editMinute,
			timezone: editTimezone,
		}
		const response = await axios.post(baseURL+'/api/schedule/edit', data)
		if(response.data.status === 'ok') {
			setBasicModal(false);
			getList();
		}
	}

	async function deleteTime() {
		const data = {
			id: editTimeID
		}
		const response = await axios.post(baseURL+'/api/schedule/delete', data)
		if(response.data.status === 'ok') {
			setBasicModal(false);
			getList();
		}
	}

	async function getList(sort_by, order_by) {
		var sort_by_value = sortBy;
		if(sort_by !== undefined) {
			sort_by_value = sort_by;
		}

		var order_by_value = orderBy;
		if(order_by !== undefined) {
			order_by_value = order_by;
		}

		const data = {
			sort_by: sort_by_value,
			order_by: order_by_value,
		}
		const response = await axios.post(baseURL+'/api/schedule/get_list', data)
		setTableData(response.data.data);
		setLoadingBadge(false);
	}
	
	return(
		<Fragment>
			<PermissionGate/>
			<div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
				<h2 className="font-w600 mb-2 me-auto ">Report Update Interval</h2>

				{/* <Select
					options={optionList}
					placeholder="Select color"
					value={selectedOptions}
					onChange={handleSelect}
					isSearchable={true}
					isMulti={true}
					className="react-select-container"
					classNamePrefix="react-select"
				/> */}

				{/* <div className="weather-btn mb-2">
					<span className="me-3 fs-16 font-w600 text-black"><i className="fa fa-cloud me-2"></i>21</span>
					<Dropdown>
						<Dropdown.Toggle variant="" as="div" className="form-control style-1 default-select me-3 p-0">{country1}</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => setCountry1("Medan, IDN")}>Medan, IDN</Dropdown.Item>
							<Dropdown.Item onClick={() => setCountry1("Jakarta, IDN")}>Jakarta, IDN</Dropdown.Item>
							<Dropdown.Item onClick={() => setCountry1("Surabaya, IDN")}>Surabaya, IDN</Dropdown.Item>
							</Dropdown.Menu>
					</Dropdown>
				</div> */}
				{/* <Link to={"/feed-new"} className="btn btn-secondary text-white mb-2"><i className="la la-list scale5 me-3"></i>New Feed</Link> */}
			</div>

			<div className="row">
				<div className="col-12">
					<div className="card">
						{/* <div className="card-header border-0 flex-wrap pb-0 select-btn">
							<div className="mb-3">
								<h4 className="fs-20 text-black">Market Overview</h4>
								<p className="mb-0 fs-12 text-black">Lorem ipsum dolor sit amet, consectetur</p>
							</div>
						</div> */}
						<div className="card-body">
							<div className="row">
								<div className="form-group mb-3 col-md-8 col-lg-8">
									<label>Select Feeds</label>

									<div className="row">
										<div className="col-4">
											<input type="number" className="form-control" id="val-username" value={newHour} onChange={(event) => changeHour(event)} placeholder="Hour"/>
										</div>
										<div className="col-4">
											<input type="number" className="form-control" id="val-username" value={newMinute} onChange={(event) => changeMinute(event)} placeholder="Minutes"/>
										</div>
										<div className="col-4">
											<select value={timezone} onChange={(event) => handleSelectTimezone(event.target.value)} className="form-control" id="val-skill" name="val-skill">
                                                <option value="-12">UTC-12:00</option>
                                                <option value="-11">UTC-11:00</option>
                                                <option value="-10">UTC-10:00</option>
                                                <option value="-9">UTC-09:00</option>
                                                <option value="-8">UTC-08:00 (PST)</option>
                                                <option value="-7">UTC-07:00</option>
                                                <option value="-6">UTC-06:00</option>
                                                <option value="-5">UTC-05:00</option>
                                                <option value="-4">UTC-04:00</option>
                                                <option value="-3">UTC-03:00</option>
                                                <option value="-2">UTC-02:00</option>
                                                <option value="-1">UTC-01:00</option>
                                                <option value="0">UTC</option>
                                                <option value="1">UTC +01:00</option>
                                                <option value="2">UTC +02:00</option>
                                                <option value="3">UTC +03:00</option>
                                                <option value="4">UTC +04:00</option>
                                                <option value="5">UTC +05:00</option>
                                                <option value="6">UTC +06:00</option>
                                                <option value="7">UTC +07:00</option>
                                                <option value="8">UTC +08:00</option>
                                                <option value="9">UTC +09:00</option>
                                                <option value="10">UTC +10:00</option>
                                                <option value="11">UTC +11:00</option>
                                                <option value="12">UTC +12:00</option>
                                                <option value="13">UTC +13:00</option>
                                            </select>
										</div>

									</div>
								</div>
							</div>

							<Button className="me-2" variant="info" onClick={() => {addNewSchedule()} }>
								Add
							</Button>

						</div>
					</div>
				</div>
			</div>

			{tableData.length > 0 && (
				<div className="row">
					<div className="col-12">
						<div className="card">
							{/* <div className="card-header border-0 flex-wrap pb-0 select-btn">
								<div className="mb-3">
									<h4 className="fs-20 text-black">Market Overview</h4>
									<p className="mb-0 fs-12 text-black">Lorem ipsum dolor sit amet, consectetur</p>
								</div>
							</div> */}
							<div className="card-body">
								<Table responsive className="header-border respon-table-data" hover>
									<thead>
										<tr>
											<th><strong>Time</strong></th>
											<th><strong>Timezone</strong></th>
											<th><strong>Manage</strong></th>
										</tr>
									</thead>
									<tbody>
										{tableData.length > 0 &&
											tableData.map((item, key) => (
												
												<tr key={key+1100}>
													<td>{item.hour_original}:{item.minute_original}</td>
													<td>UTC {item.timezone_original}:00</td>
													<td>
														<Button className="me-2" variant="info" size="sm" onClick={() => {setEditTimeID(item.id); setEditHour(item.hour_original); setEditMinute(item.minute_original); setEditTimezone(item.timezone_original); setBasicModal(true)}} >
															Edit
														</Button>
													</td>


												</tr>
											)
										)}
									</tbody>
								</Table>


								{loadingBadge === true && (
									<Badge bg="info">Loading</Badge>
								)}

								<Modal className="fade" show={basicModal} onHide={setBasicModal}>
									<Modal.Header>
										<h4 className="text-black mb-2">&nbsp;</h4>
										<Button variant="" className="close" onClick={() => setBasicModal(false)}><span>&times;</span></Button>
									</Modal.Header>
									<Modal.Body>
										{/* <h4 className="text-black mb-3"></h4> */}

										<div className="row">
											<div className="form-group mb-3 col-md-12">
												<label className="text-black">Hour</label>
												<input type="number" className="form-control" id="val-username" value={editHour} onChange={(event) => changeEditHour(event)} placeholder="Hour"/>
											</div>
										</div>

										<div className="row">
											<div className="form-group mb-3 col-md-12">
												<label className="text-black">Minutes</label>
												<input type="number" className="form-control" id="val-username" value={editMinute} onChange={(event) => changeEditMinute(event)} placeholder="Minutes"/>
											</div>
										</div>

										<div className="row">
											<div className="form-group mb-3 col-md-12">
												<label className="text-black">Timezone</label>
												<select value={editTimezone} onChange={(event) => handleSelectEditTimezone(event.target.value)} className="form-control" id="val-skill" name="val-skill">
													<option value="-12">UTC-12:00</option>
													<option value="-11">UTC-11:00</option>
													<option value="-10">UTC-10:00</option>
													<option value="-9">UTC-09:00</option>
													<option value="-8">UTC-08:00 (PST)</option>
													<option value="-7">UTC-07:00</option>
													<option value="-6">UTC-06:00</option>
													<option value="-5">UTC-05:00</option>
													<option value="-4">UTC-04:00</option>
													<option value="-3">UTC-03:00</option>
													<option value="-2">UTC-02:00</option>
													<option value="-1">UTC-01:00</option>
													<option value="0">UTC</option>
													<option value="1">UTC +01:00</option>
													<option value="2">UTC +02:00</option>
													<option value="3">UTC +03:00</option>
													<option value="4">UTC +04:00</option>
													<option value="5">UTC +05:00</option>
													<option value="6">UTC +06:00</option>
													<option value="7">UTC +07:00</option>
													<option value="8">UTC +08:00</option>
													<option value="9">UTC +09:00</option>
													<option value="10">UTC +10:00</option>
													<option value="11">UTC +11:00</option>
													<option value="12">UTC +12:00</option>
													<option value="13">UTC +13:00</option>
												</select>
											</div>
										</div>

										<Button className="me-2" variant="info" onClick={() => editTime()}>
											Save
										</Button>

										<Button variant="danger" className="" onClick={() => deleteTime()}>
											Delete
										</Button>

									</Modal.Body>
								</Modal>


							</div>
						</div>
					</div>
				</div>
			)}

		</Fragment>
	)
	
}

export default ReportUpdateInterval;