export const MenuList = [
    //Dashboard
    {   
        title:'Advertiser Report',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'advertiser-report',
        permission: 'advertiser_report'
    },
    {   
        title:'Daily Stat Report',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'daily-stat-report',
        permission: 'daily_stat_report',
    },
    {   
        title:'Feed Details Report',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'feed-details-report',
        permission: 'feed_details_report',
    },
    {   
        title:'Channel Details Report',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'channel-details-report',
        permission: 'channel_details_report',
    },
    {   
        title:'Publisher Report',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'publisher-report',
        permission: 'publisher_report',
    },
    {   
        title:'Income Statement',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'income-statement',
        permission: 'income_statement',
    },
    // {   
    //     title:'Studio',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-144-layout" />,
    //     to: 'studio',
    // },
    // {   
    //     title:'Individual',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-144-layout" />,
    //     to: 'individual',
    // },
    // {
    //     title: 'Dashboard',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="flaticon-144-layout"></i>,
    //     content: [
            
    //         {
    //             title: 'Enterprise',
    //             to: 'enterprise',					
    //         },
    //         {
    //             title: 'Studio',
    //             to: 'dashboard',					
    //         },
    //         {
    //             title: 'Individual',
    //             to: 'dashboard',					
    //         },
    //         {
    //             title: 'Dashboard',
    //             to: 'dashboard',					
    //         },
    //         {
    //             title: 'Dashboard Light',
    //             to: 'dashboard-light',
    //         },
    //         {
    //             title: 'Wallet',
    //             to: 'my-wallets',
    //         },
    //         {
    //             title: 'Transactions',
    //             to: 'transactions',
    //         },
    //         {
    //             title: 'Coin Details',
    //             to: 'coin-details',                
    //         },
	// 		{
    //             title: 'Portofolio',
    //             to: 'portofolio',                
    //         },
    //         {
    //             title: 'Market Capital',
    //             to: 'market-capital',                
    //         },
            
            
    //     ],
    // },
    {
        title: 'Advertisers & Feeds',
        classsChange: 'nav-label',
        permissions: [
            'advertiser_management',
            'feed_management',
            'channel_management'
        ]
    },
    {   
        title:'Advertiser Management',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'advertiser-management',
        permission: 'advertiser_management',
    },
    {   
        title:'Feed Management',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'feed-management',
        permission: 'feed_management',
    },
    {   
        title:'Channel Management',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'channel-management',
        permission: 'channel_management',
    },

    {
        title: 'Publishers & Campaigns',
        classsChange: 'nav-label',
        permissions: [
            'publisher_management',
            'campaign_management'
        ]
    },
    {   
        title:'Publisher Management',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'publisher-management',
        permission: 'publisher_management',
    },
    {   
        title:'Campaign Management',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'campaign-management',
        permission: 'campaign_management',
    },

    {
        title: 'IPQS Management',
        classsChange: 'nav-label',
        permissions: [
            'ipqs_log',
            'ipqs_campaigns',
            'ipqs_query'
        ]
    },
    {   
        title:'IPQS Log',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'ipqs-log',
        permission: 'ipqs_log',
    },
    {   
        title:'IPQS Campaigns',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'ipqs-management',
        permission: 'ipqs_campaigns',
    },
    {   
        title:'IPQS Query',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'ipqs-report',
        permission: 'ipqs_query',
    },

    {
        title: 'Schedule',
        classsChange: 'nav-label',
        permissions: [
            'report_update_interval',
            'report_update_log'
        ]
    },
    {   
        title:'Report Update Interval',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'report-update-interval',
        permission: 'report_update_interval',
    },
    {   
        title:'Report Update Log',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'report-update-log',
        permission: 'report_update_log',
    },

    {
        title: 'User Management',
        classsChange: 'nav-label',
        permissions: [
            'user_management'
        ]
    },
    {   
        title:'Users',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout" />,
        to: 'users',
        permission: 'user_management',
    },

    
    

    // {
    //     title: 'Advertiser Management',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="flaticon-144-layout"></i>,
    //     content: [
    //         {
    //             title: 'CSV Upload',
    //             to: 'csv-upload',					
    //         },
    //     ],
    // },
    // {
    //     title: 'SETTINGS',
    //     classsChange: 'nav-label'
    // },
    // {
    //     title: 'Settings',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="flaticon-144-layout"></i>,
    //     content: [
    //         {
    //             title: 'User Management',
    //             to: 'user-management',					
    //         },
    //         {
    //             title: 'Assign Projects to Studios',
    //             to: 'projects-to-studios',					
    //         },
    //     ],
    // },

    // {
    //     title: 'APPS',
    //     classsChange: 'nav-label'
    // },



    //Apps
    // {
    //     title: 'Apps',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-077-menu-1"></i>,
    //     content: [
    //         {
    //             title: 'Profile',
    //             to: 'app-profile'
    //         },
    //         // {
    //         //     title: 'Edit Profile',
    //         //     to: 'edit-profile'
    //         // },
    //         {
    //             title: 'Post Details',
    //             to: 'post-details'
    //         },
    //         {
    //             title: 'Contact List',
    //             to: 'contact-list'
    //         },
    //         {
    //             title: 'Contact Card',
    //             to: 'contact-card'
    //         },
    //         {
    //             title: 'Project Card',
    //             to: 'project-card'
    //         },
    //         {
    //             title: 'Email',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Compose',
    //                     to: 'email-compose',
    //                 },
    //                 {
    //                     title: 'Index',
    //                     to: 'email-inbox',
    //                 },
    //                 {
    //                     title: 'Read',
    //                     to: 'email-read',
    //                 }
    //             ],
    //         },
    //         {
    //             title:'Calendar',
    //             to: 'app-calender'
    //         },
    //         {
    //             title: 'Shop',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Product Grid',
    //                     to: 'ecom-product-grid',
    //                 },
    //                 {
    //                     title: 'Product List',
    //                     to: 'ecom-product-list',
    //                 },
    //                 {
    //                     title: 'Product Details',
    //                     to: 'ecom-product-detail',
    //                 },
    //                 {
    //                     title: 'Order',
    //                     to: 'ecom-product-order',
    //                 },
    //                 {
    //                     title: 'Checkout',
    //                     to: 'ecom-checkout',
    //                 },
    //                 {
    //                     title: 'Invoice',
    //                     to: 'ecom-invoice',
    //                 },
    //                 {
    //                     title: 'Customers',
    //                     to: 'ecom-customers',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // //Charts
    // {
    //     title: 'Charts',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-061-puzzle" />,
    //     content: [
            
    //         {
    //             title: 'RechartJs',
    //             to: 'chart-rechart',					
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: 'chart-chartjs',					
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: 'chart-sparkline',					
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: 'chart-apexchart',					
    //         },
    //     ]
    // },
    // {
    //     title: ' COMPONENTS',
    //     classsChange: 'nav-label'
    // },
    // //Boosttrap
    // {
    //     title: 'Bootstrap',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-003-diamond" />,	
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: 'ui-accordion',					
    //         },
    //         {
    //             title: 'Alert',
    //             to: 'ui-alert',					
    //         },
    //         {
    //             title: 'Badge',
    //             to: 'ui-badge',					
    //         },
    //         {
    //             title: 'Button',
    //             to: 'ui-button',					
    //         },
    //         {
    //             title: 'Modal',
    //             to: 'ui-modal',					
    //         },
    //         {
    //             title: 'Button Group',
    //             to: 'ui-button-group',					
    //         },
    //         {
    //             title: 'List Group',
    //             to: 'ui-list-group',					
    //         },
    //         {
    //             title: 'Cards',
    //             to: 'ui-card',					
    //         },
    //         {
    //             title: 'Carousel',
    //             to: 'ui-carousel',					
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: 'ui-dropdown',					
    //         },
    //         {
    //             title: 'Popover',
    //             to: 'ui-popover',					
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: 'ui-progressbar',					
    //         },
    //         {
    //             title: 'Tab',
    //             to: 'ui-tab',					
    //         },
    //         {
    //             title: 'Typography',
    //             to: 'ui-typography',					
    //         },
    //         {
    //             title: 'Pagination',
    //             to: 'ui-pagination',					
    //         },
    //         {
    //             title: 'Grid',
    //             to: 'ui-grid',					
    //         },
    //     ]
    // },
    // //plugins
    // {
    //     title:'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle : <i className="flaticon-053-heart" />,
    //     content : [
    //         {
    //             title:'Select 2',
    //             to: 'uc-select2',
    //         },
    //         // {
    //         //     title:'Noui Slider',
    //         //     to: 'uc-noui-slider',
    //         // },
    //         {
    //             title:'Sweet Alert',
    //             to: 'uc-sweetalert',
    //         },
    //         {
    //             title:'Toastr',
    //             to: 'uc-toastr',
    //         },
    //         {
    //             title:'Jqv Map',
    //             to: 'map-jqvmap',
    //         },
    //         {
    //             title:'Light Gallery',
    //             to: 'uc-lightgallery',
    //         },
    //     ]
    // },
    // {
    //     title: 'Redux',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-book"></i>,
    //     content: [
    //         {
    //             title: 'Todo',
    //             to: 'todo'
    //         },
    //     ]
    // },
    // //Widget
    // {   
    //     title:'Widget',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-settings-2" />,
    //     to: 'widget-basic',
    // },
    // //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-044-file" />,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: 'form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: 'form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: 'form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: 'form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: 'form-validation',
    //         },

    //     ]
    // },
    // //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-network" />,
    //     content : [
    //         {
    //             title:'Table Filtering',
    //             to: 'table-filtering',
    //         },
    //         {
    //             title:'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    // //Pages
    // {
    //     title:'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-049-copy" />,
    //     content : [
    //         {
    //             title:'Error',
    //             hasMenu : true,
    //             content : [
    //                 {
    //                     title: 'Error 400',
    //                     to : 'page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to : 'page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to : 'page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to : 'page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to : 'page-error-503',
    //                 },
    //             ],
    //         },
    //         {
    //             title:'Lock Screen',
    //             to: 'page-lock-screen',
    //         },

    //     ]
    // },
    
]